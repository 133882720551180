import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {QuestionBase} from "../models/question-base";
import {adsRoute} from "../../../app.models";

@Injectable()
export class AdvFormService {
  private subject = new Subject<any>();
  private qObserver = new Subject<any>();
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  QuestionMap = new Map();
  public adsRoutes: adsRoute[];
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService) {}

  addQuestion(index: string,answer: any, actionAfter=null){
    this.QuestionMap.set(index,answer)
    this.sendClickEvent()
    this.sendQOClickEvent({index:index, action:actionAfter})
  }

  deleteQuestion(index: string, actionAfter=null){
    this.QuestionMap.delete(index);
    this.sendClickEvent(index)
    this.sendQOClickEvent({index:index, action: actionAfter})
  }

  getQuestion(index: string) {
    return this.QuestionMap.get(index);
  }

  loadAdsRouts(): void{
    this.http.get(this.apiKey.getClientAdsRouteUrl(), this.options).subscribe(res=>{
      this.adsRoutes = res['results'];
    })
  }

  getValues(data: any):any{
    return data
  }
  publishData(): Observable<any>{
    var arrJson:any = {}
    this.QuestionMap.forEach((value,key) => arrJson[key]=this.getValues(value))
    this.loadingMaskServiceService.setOnLoadingMask(true, true);

    return this.http.post<any>(this.apiKey.getFormsQuestionUrl(), JSON.stringify(arrJson), this.options)
  }

  sendQOClickEvent(obj: any = null){
    this.qObserver.next(obj);
  }

  getQOClickEvent():Observable<any>{
    return this.qObserver.asObservable();
  }

  sendClickEvent(obj: any = null){
    this.subject.next(obj);
  }

  getClickEvent():Observable<any>{
    return this.subject.asObservable();
  }

  clearRelatedFields(questionBase: QuestionBase<any>,source: string|null =null ): void{
    const arrList = questionBase.relatedField.split(",");
    if(arrList.length == 0 ) return;
    arrList.forEach((field:any)=>{
      if(this.getQuestion(field) != null || this.getQuestion(field) != undefined){
        this.deleteQuestion(field);
      }
    })
    if(source == "clearSearch"){
      this.deleteQuestion(questionBase.key)
    }

  }

}
