<section *ngIf="categorytList.length>0" class="top-category section-padding">
  <div class="container">
    <div class="owl-carousel owl-carousel-category">
      <div *ngFor="let category of categorytList; let i = index" [attr.data-index]="i" class="item owl-carousel-category-item">
        <div class="category-item">
          <a href="shop.html">
            <img class="img-fluid" src="{{category.companyImage}}" alt="">
            <h6>{{categorytList[i].companyName}}</h6>
            <p>{{categorytList[i].productQuantity}}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
