import {
  AfterContentInit, AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {Event} from "@angular/router";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {coerceStringArray} from "@angular/cdk/coercion";
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {MatDialog} from "@angular/material/dialog";
import {PhoneValidationDialogComponent} from "./phone-validation-dialog/phone-validation-dialog.component";

export interface PersonContact{
  userId: number,
  contactPerson: string,
  phoneNumber: string,
  email: string,
  acceptTerms: boolean,
  acceptAdv: boolean,
  validPhone:boolean
}

@Component({
  selector: 'app-person-contact',
  templateUrl: './person-contact.component.html',
  styleUrls: ['./person-contact.component.css'],
})
export class PersonContactComponent implements OnInit {
  personContact: PersonContact;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  constructor(private http: HttpClient, private apiKey:ApiUrl, private formService: AdvFormService, public dialog: MatDialog) {
    // this.loadData()
    this.dialog.afterAllClosed.subscribe(res=>{
      this.loadData()
    })
    this.personContact = {
      acceptAdv: false,
      acceptTerms: false,
      contactPerson: "",
      email: "",
      validPhone:false,
      phoneNumber: "",
      userId: -1
    };
  }

  isUserValid: boolean = false;
  set isUserValidSetter(isuservalid: boolean){
    this.isUserValid = isuservalid
  }
  get isUserValidGetter(){
    return this.isUserValid
  }

  loadData(): void{
    this.getUserValidation().subscribe((res)=>{
      this.personContact = {
        acceptAdv: res.acceptAdv,
        acceptTerms: res.acceptTerms,
        contactPerson: res.user.first_name,
        email: res.user.email,
        phoneNumber: res.user.mainPhone,
        userId: res.id,
        validPhone:res.validPhone
      };
      this.MissingData()
      this.isUserValidSetter = res.validPhone
    },error => this.getUserInfo().subscribe(res=>{
      this.personContact = {
        acceptAdv: res.acceptAdv,
        acceptTerms: res.acceptTerms,
        contactPerson: res.first_name,
        email: res.email,
        phoneNumber: res.mainPhone,
        userId: res.id,
        validPhone:res.validPhone
      };
      this.MissingData()
    }));
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getUserValidation(): Observable<any>{
    return this.http.get(this.apiKey.getPhoneValidationUrl()+"validate/", this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  getUserInfo(): Observable<any>{
    return this.http.get(this.apiKey.getUserInfo(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  MissingData(): void{
    this.formService.addQuestion('userContactDetails',this.personContact);
  }

  onChange($event: any): void{
    this.MissingData()
  }
  ngOnInit(): void {
  }

  validatePhone() {
    this.http.post(this.apiKey.getPhoneValidationUrl(),this.personContact, this.options).subscribe(res=>{
      this.dialog.open(PhoneValidationDialogComponent,{ data : this.personContact })
    })
  }



}
