import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MainCategory} from "../../models/header.model";
import {Observable, Subject, throwError} from "rxjs";
import {catchError, debounceTime, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {LoginService} from "../session/login/login.service";

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.css']
})
export class HeaderSearchComponent implements OnInit {
  @ViewChild('input') text!: ElementRef;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  @Input()
  categories!:MainCategory[];
  searchTerms = new Subject<string>();
  searchProductList: any={};
  menuOpen: boolean= false;
  search($event: any): void {
    this.searchTerms.next($event.target.value);
  }
  get isEmptySearchResult(): boolean{
    let isEmpty = true;
    ['car','nadlan','sale2'].forEach(index =>{
      if(this.searchProductList[index].length>0){
        isEmpty = false
      }
    })
    return isEmpty;
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loginService: LoginService) {
    this.initSearchBrowser()
  }

  initSearchBrowser(): void{
    this.searchProductList['car']=[];
    this.searchProductList['nadlan']=[];
    this.searchProductList['sale2']=[];
    this.searchProductList['required']=[];
    this.searchProductList['business']=[];
    this.searchProductList['worker']=[];
    this.searchProductList['nadlanbusiness']=[];
    this.searchProductList['cellular']=[];
    this.searchProductList['furniture']=[];
    this.searchProductList['tourism']=[];
    this.searchProductList['fashion']=[];
    this.searchProductList['pets']=[];
    this.searchProductList['boybaby']=[];
    this.searchProductList['teachers']=[];
  }

  ngOnInit(): void {
    this.initSearchInput();
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  browserSearch(query: string): Observable<any>{
    return this.http.get(this.apiKey.getBrowserSearch(query), this.options)
      .pipe(map((res) => res ? res: null),catchError(this.handleObserverError))
  }

  onBrowserSearch($event:any): void{
    this.browserSearch($event.target.value).subscribe(res=>{
      ['car','nadlan','sale2'].forEach(index=>{
        this.searchProductList[index]=[]
        this.searchProductList[index].push(res[index])
      })
    })
  }

  private initSearchInput(): void {
    this.searchProductList ={}
    this.searchTerms.pipe(debounceTime(250))
      .subscribe(term => {
        this.browserSearch(term).subscribe(res=>{
          ['car','nadlan','sale2'].forEach(index=>{
            this.searchProductList[index]=[]
            this.searchProductList[index].push(res[index])
          })
        })
      });
  }


  openMenu() {
    this.menuOpen = true;
  }
  private wasInside = false;
  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }
  @HostListener('document:click')
  clickOutSide($event: any): void{
    if (!this.wasInside) {
      this.menuOpen = false;
    }
    this.wasInside = false;
  }
  onBlur($event:any) {
    console.log($event)
    // this.menuOpen = false;
  }
}
