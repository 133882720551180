<div class="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main">
  <div class="top-categories-search">
    <div class="input-group">
                        <span class="input-group-btn categories-dropdown">
                           <select class="form-control-select">
                              <option selected="selected">{{'sale4you.label.all' | translate}}</option>
                              <option *ngFor="let cate of categories" [value]="cate.nameEng">{{cate.name}}</option>
                           </select>
                        </span>
      <input (focus)="openMenu()" (blur)="onBlur($event)" class="form-control" #input (keyup)="search($event)" list="browsers" name="browser" id="browser" placeholder="{{'sale4you.label.searchInput'| translate}}" aria-label="Search products in Your City" type="text"/>
      <ul class="browsersDataList" *ngIf="menuOpen" id="browsers">
        <li *ngFor="let category of categories">
          <div *ngIf="searchProductList[category.nameEng][0] && searchProductList[category.nameEng][0].length>0" class="headerCategory">{{category.name}}</div>
          <div *ngIf="searchProductList[category.nameEng][0] && searchProductList[category.nameEng][0].length>0" class="row headerDescription">
            <div class="col">{{'sale4you.label.name' | translate}}</div>
            <div class="col">{{'sale4you.label.description' | translate}}</div>
          </div>
          <ul *ngIf="searchProductList[category.nameEng][0] && searchProductList[category.nameEng][0].length>0" class="categoryBrowser">
            <li  *ngFor="let res of searchProductList[category.nameEng][0]">
              <a [href]="['single/'+res.id]">
              <div class="row">
                <div class="col">{{res.name}}</div>
                <div class="col">{{res.description}}</div>
              </div>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <span class="input-group-btn">
                        <button class="btn btn-secondary" type="button"><i class="mdi mdi-file-find"></i> {{'sale4you.label.search'| translate}}</button>
                        </span>
    </div>
  </div>
</div>
