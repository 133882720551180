<!--<section class="carousel-slider-main text-center border-top border-bottom bg-white">-->
<!--  <div *ngIf="carouselSlider!=undefined" class="owl-carousel owl-carousel-slider">-->
<!--    <div  *ngFor="let item of carouselSlider.images;"  class="item">-->
<!--      <a href="shop.html"><img style="width: 100%;" class="img-fluid"   src="{{serverUrl}}{{item.image}}" alt="First slide"></a>-->
<!--    </div>-->
<!--    <div class="item">-->
<!--      <a href="shop.html"><img class="img-fluid" src="assets/img/slider/slider2.jpg" alt="First slide"></a>-->
<!--    </div>-->
<!--    <div class="item">-->
<!--      <a href="shop.html"><img class="img-fluid" src="assets/img/slider/slider1.jpg" alt="First slide"></a>-->
<!--    </div>-->
<!--    <div class="item">-->
<!--      <a href="shop.html"><img class="img-fluid" src="assets/img/slider/slider2.jpg" alt="First slide"></a>-->
<!--    </div>-->
<!--  </div>-->
<!--</section>-->

<div class="main-slider" *ngIf="carouselSlider">
  <div class="swiper-container h-100" [swiper]="config">
    <div class="swiper-wrapper h-100">
      <div *ngFor="let slide of carouselSlider" class="swiper-slide">
        <div [attr.data-background]="serverUrl+slide.image" class="slide-item swiper-lazy">
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
          <div class="mask"></div>
          <div fxLayout="column" fxLayoutAlign="center center" class="content">
            <h1>{{slide.title}}</h1>
            <h3>{{slide.subtitle}}</h3>
            <button mat-raised-button color="primary">{{'sale4you.slider.moreDetails' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination white"></div>
    <button mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow sale4all-swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab color="primary" class="swiper-button-next swipe-arrow sale4all-swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
  </div>
</div>