import {AfterViewInit, Component, OnInit} from '@angular/core';
declare function LoadSliderJs(): any;
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {

  }
  ngAfterViewInit(): void{
    // LoadSliderJs()
  }

}
