
<div #dropdown class="check-box-container">
    <hr/>
    <label style="font-weight: bold; white-space: nowrap; padding: 1em 0em;"> {{ filterBase.label }}</label>
    <div class="row">
        <div class="col" *ngFor="let item of items" style="min-width: 70px; max-width: 160px">
            <table>
                <tr>
                    <td style="padding: 1em;"><input type="checkbox" (click)="selectCheckBox(item)" ></td>
                    <td>{{item.label}}</td>
                </tr>
            </table>
        </div>
    </div>
    <hr/>
</div>
