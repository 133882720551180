import {Component, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {AdsRoute} from "../../../../models/user-ads";
import {PaymentProcessStatus} from "../../../../app.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {MatDialogRef} from "@angular/material/dialog";
import {CreditCardComponent} from "../credit-card/credit-card.component";
import {LoadingMaskServiceService} from "../../../../components/loading-frame/loading-frame.component";
import {Router} from "@angular/router";
import {timer} from "rxjs";

export enum CreditCardType {
  AM="AM",
  DINERS="DINERS",
  MASTER_CARD="MASTER_CARD",
  VISA="VISA"
}
@Component({
  selector: 'app-credit-card-payment',
  templateUrl: './credit-card-payment.component.html',
  styleUrls: ['./credit-card-payment.component.scss']
})
export class CreditCardPaymentComponent implements OnInit {

  @Input() adsId: number;
  @Input() adsRoute: AdsRoute;
  @Input() dialog!: MatDialogRef<CreditCardComponent>;

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiUrl.getJWTToken()
    })
  }

  title: string = 'Credit Card Form'; // Change this accordingly
  cardholderName: string = '';
  email: string = '';
  phoneNumber: string = '';
  cardNumber: string = '';
  expirationDate: string = '';
  cvv: string = '';
  numOfPays: number = 1; // Change this accordingly
  creditCardForm: NgForm;

  constructor(private http: HttpClient, private apiUrl: ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService, private router: Router) {
  }

  // Function to format the card number
  formatCardNumber() {
    const numericValue = this.cardNumber.replace(/\D/g, '');
    if (numericValue.length > 0) {
      this.cardNumber = numericValue.replace(/(\d{4})/g, '$1 ').trim();
    } else {
      this.cardNumber = '';
    }
  }

  // Function to format the expiration date
  formatExpirationDate() {
    const numericValue = this.expirationDate.replace(/\D/g, '');
    if (numericValue.length > 0) {
      this.expirationDate = numericValue.replace(/(\d{2})(\d{0,2})/, '$1/$2').trim();
    } else {
      this.expirationDate = '';
    }
  }


  // Function to check if the expiration date is valid
  isExpirationDateValid() {
    const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    return regex.test(this.expirationDate);
  }

  // Function to check if the card number is valid
  isCardNumberValid() {
    const regex = /^\d{16}$/;
    return regex.test(this.cardNumber.replace(/\D/g, ''));
  }

  // Function to check if the CVV is valid
  isCvvValid() {
    const regex = /^\d{3}$/;
    return regex.test(this.cvv);
  }

  // Function to handle input changes for card number
  onCardNumberChange() {
    this.formatCardNumber();
  }

  // Function to handle input changes for expiration date
  onExpirationDateChange() {
    this.formatExpirationDate();
  }

  // Function to handle input changes for CVV
  onCvvChange() {
    // Update any logic related to CVV changes
  }
  ngOnInit() {
  }

  getCardType(cardNumber: string): string | null {
    const cleanedCardNumber = cardNumber.replace(/\D/g, '');
    const patterns = {
      "AM": ["34", "37"],
      "DINERS": ["300", "301", "302", "303", "304", "305", "36", "38"],
      "MASTER_CARD": ["51", "52", "53", "54", "55"],
      "VISA": ["4"]
    };
    for (const [cardType, prefixes] of Object.entries(patterns)) {
      for (const prefix of prefixes) {
        if (cleanedCardNumber.startsWith(prefix)) {
          return cardType;
        }
      }
    }
    return null;
  }
  // Function to submit the form
  onSubmit() {
    if (this.isCardNumberValid() && this.isExpirationDateValid() && this.isCvvValid()) {
      const payPayload ={
        cardholderName: this.cardholderName,
        email: this.email,
        phoneNumber:this.phoneNumber,
        cardNumber:this.cardNumber,
        expirationDate:this.expirationDate,
        cvv:this.cvv,
        numOfPays:this.numOfPays,
        adsId: this.adsId
      }
      this.payProcess = PaymentProcessStatus.IN_PROGRESS;
      this.http.post<any>(this.apiUrl.checkoutAdv(), JSON.stringify(payPayload), this.options).subscribe(res=>{
        this.loadingMaskServiceService.setOnLoadingMask(false,false);
        this.payProcess = PaymentProcessStatus.DONE
        timer(2000).subscribe(() => {
          this.dialog.close()
          this.router.navigate(['/']); // Replace '/main' with the route you want to navigate to
        })
      },error => {
        this.payProcess = PaymentProcessStatus.TODO
      })


    } else {
      console.log('Invalid input. Please check your card number, expiration date, and CVV.');
    }
  }

  creditCardNumber: string = '';
  expireDate: string = '';


  creditCardType: string | null = CreditCardType.VISA;

  submitForm() {
    // Handle form submission logic here
    console.log('Form submitted!');
  }

  getCardIcon(type: string): string {
    // Replace with your logic to determine the card type and return the corresponding icon URL
    return `assets/icons/${type.toLowerCase()}.png`;
  }

  protected readonly CreditCardType = CreditCardType;
  payProcess: PaymentProcessStatus = PaymentProcessStatus.TODO;

  CheckIfValid(creditCardForm: NgForm) {
    return creditCardForm.status == 'VALID'
  }

  protected readonly PaymentProcessStatus = PaymentProcessStatus;

  isCardType(cardType: CreditCardType) {

    if( this.cardNumber.trim() == '' ){
      return true;
    }
    const vCardType = this.getCardType(this.cardNumber)
    if(cardType == vCardType){
      return true;
    }
    return false;
  }
}
