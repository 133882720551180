<h2>
    {{"sale4you.label.Ad.Images" | translate}}
</h2>
<div class="row">
    <div class="col-2" style="width: 70px; display: flex; flex-shrink: 0; align-items: flex-end; justify-content: center; left:-10px;">
        <button (click)="scrollRight()" mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
    <div class="col-8">
        <div class="row imageList" #imageList>
            <div class="imageContainer">
                <div *ngFor="let image of value" class="image-item">
                    <img [src]="image.image" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="col-2" style="width: 70px; display: flex; flex-shrink: 0; align-items: center; justify-content: center;">
        <button (click)="scrollLeft()" mat-mini-fab color="primary" class="swiper-button-prev swipe-arrow">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
    </div>
</div>