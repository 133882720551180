import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from "./auth.service";
import {SystemApp} from "../../constans/SystemConfigurations";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }
  canActivate() {
    if(this.auth.IsLoggedIn()){
      return true;
    }

    // this.router.navigate(['sign-in'],{ queryParams: { } }).then(()=>{
    //   window.location.reload();
    // });;
    this.router.navigate([],{ queryParams: { loginOpenForm: 'true' } });
    return false
  }

}



@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
  }
  canActivate(): Promise<boolean> {
    return this.auth.IsAdminLogin();
  }

}
