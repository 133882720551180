import {Component, Injectable, OnInit} from '@angular/core';
import {Observable, Subject} from "rxjs";

@Injectable()
export class PriceViewService {
  private subject = new Subject<any>();
  private viewPriceFlg = false;

  set viewPrice(flg:boolean){
    this.viewPriceFlg = flg;
  }

  get viewPrice(): boolean{
    return this.viewPriceFlg;
  }

  sendClickEvent(obj: any = null){
    this.subject.next(obj);
  }

  getClickEvent():Observable<any>{
    return this.subject.asObservable();
  }
}
