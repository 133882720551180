import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [
    new SidenavMenu (9991, 'sale4you.label.HomePage.HomePage', '/', null, null, false, 0),
    new SidenavMenu (9992, 'sale4you.label.HomePage.AllProducts', '/products', null, null, false, 0),
    new SidenavMenu (9999, 'sale4you.label.HomePage.Categories', '/categories', null, null, true, 0),
    new SidenavMenu (9993, 'sale4you.label.HomePage.MyCart', '/cart', null, null, false, 0),
    new SidenavMenu (9994, 'sale4you.label.HomePage.Contact', '/contact', null, null, false, 0),


]
