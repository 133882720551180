<app-credit-card-payment [dialog]="dialogRef" [adsRoute]="data.adsRoute" [adsId]="adsId"></app-credit-card-payment>
<!--  <div class="modal-body" style="background: #fbfbfb; min-width: 350px; width: 80vw;" *ngIf="sessionUrl != null">-->

<!--        <app-credit-card-payment></app-credit-card-payment>-->
<!--    <iframe  [src]="getSafeSessionUrl()" width="100%" height="800px" frameborder="0"></iframe>-->

    <!--    <form>-->
<!--      <div class="form-group">-->
<!--        <section class="checkout-page section-padding">-->
<!--          <div class="container">-->
<!--            <div class="row">-->
<!--              <div class="col">-->
<!--                <div class="checkout-step">-->
<!--                  <div class="accordion" id="accordionExample">-->
<!--                    <div class="card">-->
<!--                      <div class="card-header" id="headingThree" >-->
<!--                        <h5 class="mb-0">-->
<!--                          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">-->
<!--                            <span class="number">1</span><b>תשלום בכרטיס אשראי</b>-->
<!--                          </button>-->
<!--                        </h5>-->
<!--                      </div>-->
<!--                      <div id="collapseThree" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">-->
<!--                        <div class="card-body">-->
<!--                          <form class="">-->
<!--                            <div class="form-group">-->
<!--                              <label class="control-label">מספר כרטיס</label>-->
<!--                              <input class="form-control border-form-control" value="" placeholder="0000 0000 0000 0000" type="text">-->
<!--                            </div>-->
<!--                            <div class="form-group">-->
<!--                              <label class="control-label">ת.ז</label>-->
<!--                              <input class="form-control border-form-control" value="" placeholder="XXXXXXXXX" type="text">-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                              <div class="col-sm-3">-->
<!--                                <div class="form-group">-->
<!--                                  <label class="control-label">חודש</label>-->
<!--                                  <input class="form-control border-form-control" value="" placeholder="01" type="text">-->
<!--                                </div>-->
<!--                              </div>-->
<!--                              <div class="col-sm-3">-->
<!--                                <div class="form-group">-->
<!--                                  <label class="control-label">שנה</label>-->
<!--                                  <input class="form-control border-form-control" value="" placeholder="15" type="text">-->
<!--                                </div>-->
<!--                              </div>-->
<!--                              <div class="col-sm-3">-->
<!--                              </div>-->
<!--                              <div class="col-sm-3">-->
<!--                                <div class="form-group">-->
<!--                                  <label class="control-label">CVV</label>-->
<!--                                  <input class="form-control border-form-control" value="" placeholder="135" type="text">-->
<!--                                </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--                            <hr>-->
<!--                            &lt;!&ndash;                            <div class="custom-control custom-radio">&ndash;&gt;-->
<!--                            &lt;!&ndash;                              <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input">&ndash;&gt;-->
<!--                            &lt;!&ndash;                              <label class="custom-control-label" for="customRadio1">Would you like to pay by Cash on Delivery?</label>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            </div>&ndash;&gt;-->
<!--                            &lt;!&ndash;                            <p>Vestibulum semper accumsan nisi, at blandit tortor maxi'mus in phasellus malesuada sodales odio, at dapibus libero malesuada quis.</p>&ndash;&gt;-->
<!--                            <button type="button" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour" class="btn btn-secondary mb-2 btn-lg">תשלום</button>-->
<!--                          </form>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="card">-->
<!--                      <div class="card-header" id="headingThree">-->
<!--                        <h5 class="mb-0">-->
<!--                          <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">-->
<!--                            <span class="number">2</span> <b>סטטוס הזמנה</b>-->
<!--                          </button>-->
<!--                        </h5>-->
<!--                      </div>-->
<!--                      <div id="collapsefour" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">-->
<!--                        <div class="card-body">-->
<!--                          <div class="text-center">-->
<!--                            <div class="col-lg-10 col-md-10 mx-auto order-done">-->
<!--                              <i class="mdi mdi-check-circle-outline text-secondary"></i>-->
<!--                              <h4 class="text-success">הזמנה בוצעה בהצלחה</h4>-->
<!--                            </div>-->
<!--                            <div class="text-center">-->
<!--                              <a href="#"><button type="submit" class="btn btn-secondary mb-2 btn-lg">חזור לדף הבית</button></a>-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->
<!--      </div>-->
<!--    </form>-->
<!--  </div>-->

