<form style="direction: rtl;" [formGroup]="dynamicForm" (ngSubmit)="onSubmit()" class="row">
    <div class="col" style="min-width: 200px; max-width: 250px;" *ngFor="let question of questions" >

        <div [ngSwitch]="question.type">
            <div style="text-align: right" *ngSwitchCase="'id'">
                <label>{{ question.label }}</label>
                <input class="form-control" disabled type="text" [formControlName]="question.key" />
            </div>
            <div style="text-align: right"  *ngSwitchCase="'text'">
                <label>{{ question.label }}</label>
                <input class="form-control" type="text" (change)="onChange($event,question.key)"  [formControlName]="question.key" />
            </div>
            <div style="text-align: right"  *ngSwitchCase="'number'">
                <label>{{ question.label }}</label>
                <input class="form-control" type="number" (change)="onChange($event,question.key)"  [formControlName]="question.key" />
            </div>
            <div style="text-align: right"  *ngSwitchCase="'boolean'">
                <label>{{ question.label }}</label>
                <input class="form-control" type="checkbox" (change)="onChange($event,question.key)"  [formControlName]="question.key" />
            </div>
            <div style="text-align: right"  *ngSwitchCase="'date'">
                <label>{{ question.label }}</label>
                <input class="form-control" *ngSwitchCase="'date'" (change)="onChange($event,question.key)"  type="datetime-local" [formControlName]="question.key" />
            </div>
            <div style="text-align: right"  *ngSwitchCase="'select'">
                <label>{{ question.label }}</label>
                <select class="form-control" *ngSwitchCase="'select'" (change)="onChange($event,question.key)"  [formControlName]="question.key">
                    <!-- You can add options here dynamically based on your requirements -->
                </select>
            </div>
            <div style="text-align: right"  *ngSwitchCase="'remoteSelect'">
                <label>{{ question.label }}</label>
                <app-drop-down-select [question]="question" ></app-drop-down-select>
<!--                <app-dropdown-field [question]="" value=""></app-dropdown-field>-->
            </div>
            <div *ngIf="dynamicForm.get(question.key)?.invalid && dynamicForm.get(question.key)?.touched">
                Please enter a valid {{ question.key }}.
            </div>
        </div>
    </div>
    <div class="col-12" *ngFor="let question of questions" >
        <div [ngSwitch]="question.type">
            <div style="text-align: right"  *ngSwitchCase="'textarea'">
                <label>{{ question.label }}</label>
                <textarea class="form-control" *ngSwitchCase="'textarea'" (change)="onChange($event,question.key)"  [formControlName]="question.key"></textarea>
            </div>
            <div  *ngSwitchCase="'image'">
                <p class="mt-1"><span class="uppercase fw-500">{{'sale4you.slider.image' | translate}}</span><span class="text-muted px-1">{{'sale4you.slider.maxImages' | translate}}</span></p>
                <input-file formControlName="image" fileLimit="1"  (change)="onChange($event, 'image')" fileAccept="image/*"></input-file>
            </div>
        </div>
    </div>

    <button class="form-control" (click)="publishData()" type="submit">Submit</button>
</form>