import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FilterBase} from "../../../../../models/filter-base";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../../components/loading-frame/loading-frame.component";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";
import {SelectOption} from "../select-field/select-field.component";

@Component({
  selector: 'app-select-check-boxs',
  templateUrl: './select-check-boxs.component.html',
  styleUrls: ['./select-check-boxs.component.scss']
})
export class SelectCheckBoxsComponent implements OnInit, AfterViewInit {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  items: SelectOption[]=[];
  @Input() widthSize: number = 160;
  @Input() filterBase!: FilterBase;
  loadingData!: boolean;
  selectedOpt: SelectOption[]=[];

  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService, private filterBaseService: FilterbaseService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    const pathFetch = this.apiKey.pathTranslate(this.filterBase.callBackFunction, this.filterBaseService);
    this.fetchFilterData(pathFetch.path).subscribe(res=>{
      this.loadingData = false;
      this.items = res;
    })
  }

  fetchFilterData(url: string): Observable<any>{
    return this.http.get(url, this.options)
        .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
        );
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  selectCheckBox(item: SelectOption) {
    if(this.selectedOpt.indexOf(item) != -1){
      this.selectedOpt.splice(this.selectedOpt.indexOf(item),1);
    }else{
      this.selectedOpt.push(item)
    }
    this.filterBaseService.addQuestion(this.filterBase.key,this.selectedOpt.map(itm=>itm.value.value).join(','));
  }


}
