import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TopProduct} from "../../../../models/top-product";
import {Product} from "../../../../models/product"
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../components/loading-frame/loading-frame.component";
import {SystemApp} from "../../../../constans/SystemConfigurations";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
declare function itemsSlider(category: string): any;
@Component({
  selector: 'app-product-details-item',
  templateUrl: './product-details-item.component.html',
  styleUrls: ['./product-details-item.component.css']
})
export class ProductDetailsItemComponent implements OnInit {
  @Input() header!: string;
  @Input() namespace!: string;
  productList: TopProduct[];
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  base_url: any;
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public translate:TranslateService) {
    this.base_url = SystemApp.base_url_api;
    // @ts-ignore
    this.productList = [1,2,3,4,5,6];
    this.loadData()
  }

  loadData(): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getToProduct().subscribe((res) => {
      if(this.namespace == 'Nadlan'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
          id: res.id,
          name: res.name,
          shortDescription: res.description,
          image: res.images[0].image,
          link: 'None',

        }})
      }
      if(this.namespace == 'car'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
          id: res.id,
          name: res.name,
          shortDescription: res.description,
          image: res.images[0].image,
          link: 'None',
          color: res.Color?{
            colorName: res.Color.colorName,
            colorCode:res.Color.colorCode
          }: null
        }})
      }
      if(this.namespace == 'Sale2'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
          id: res.id,
          name: res.name,
          shortDescription: res.description,
          image: res.images[0].image,
          link: 'None'
        }})
      }
      setTimeout(() => { itemsSlider("owl-carousel-featured-"+this.namespace) }, 300);
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })

  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }
  getToProduct(): Observable<any>{
    return this.http.get(this.apiKey.getTopProducts(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  ngOnInit(): void {
  }

}
