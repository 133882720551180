import {Component, ElementRef, HostListener, Input, OnInit, Pipe, PipeTransform, ViewChild} from '@angular/core';
import {QuestionBase} from "../../pages/create-adv/models/question-base";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {LoadingMaskServiceService} from "../loading-frame/loading-frame.component";
import {SystemApp} from "../../constans/SystemConfigurations";
import {Observable, throwError} from "rxjs";
import {catchError, debounce, debounceTime, distinctUntilChanged, map} from "rxjs/operators";
import {selectOptionData} from "../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";


@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string, fieldName: string): any[] {
    console.log(items, searchText, fieldName)
    // return empty array if array is falsy
    if (!items) { return []; }

    // return the original array if search text is empty
    if (!searchText) { return items; }

    // convert the searchText to lower case
    searchText = searchText.toLowerCase();

    // retrun the filtered array
    return items.filter(item => {
      if (item && item[fieldName]) {
        return item[fieldName].toLowerCase().includes(searchText);
      }
      return false;
    });
  }
}

@Component({
  selector: 'app-ui-select-dropdown',
  templateUrl: './ui-select-dropdown.component.html',
  styleUrls: ['./ui-select-dropdown.component.css']
})
export class UiSelectDropdownComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiUrl.getJWTToken()
    })
  }
  pointerIndex = -1;
  selectBtBtn(event: KeyboardEvent) {


  }

  getClass(item: any) {

    if(this.selectedItem  === item){
      return 'selectedRow';
    }
    return ''
  }

  @Input() questionBase!: QuestionBase<any>;
  @ViewChild('optionsList') optionsList!: ElementRef;
  expanded: boolean = false;
  dataList: any = [];
  fetchUrl!: string;
  inputValue!: string;
  translatedQue!: any;
  isLoading: boolean = false;
  selectedItem: any;
  private onBlurtimeoutId!: number;



  constructor(private apiUrl: ApiUrl, private http: HttpClient, private formService: AdvFormService, private loadingMaskServiceService: LoadingMaskServiceService) {
    this.formService.getClickEvent().subscribe((res: any)=>{
      if(res != this.questionBase.key) return;
      this.inputValue = "";
      this.dataList = [];
    })
  }

  ngOnInit(): void {
    this.fetchUrl = SystemApp.base_url_api + '/' + this.questionBase.callBackFunction.$piece("*",1);

  }

  onClick() {

    if(this.expanded == true){ return; }
    this.isLoading = true;
    this.translatedQue = this.apiUrl.pathTranslate(this.questionBase.callBackFunction, this.formService,this.questionBase.additionalParam);
    this.fetchUrl = this.translatedQue.path
    this.fetchData(this.fetchUrl, this.inputValue).subscribe(res=>{
      this.dataList = res;
      this.expanded = true;
      this.isLoading = false;
    })
  }

  onBlur($event: any) {
    // @ts-ignore
    this.onBlurtimeoutId = setTimeout( ()=> {this.expanded = false;}, 100);
  }

  fetchData(url: string, query: string = ''): Observable<any>{
    if(query != null && query != undefined && query != ''){
      if(url.includes("?")){
        url = url+"&q="+query
      }else{
        url = url+"?q="+query
      }
    }
    return this.http.get(url, this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  public selectedValue: Observable<any>;

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }


  onChange(value:any) {

    this.selectedItem = this.dataList.find(i=>i.label == value);
    this.selectValue(value, this.selectedItem)
    this.inputValue = value;
    // this.fetchData(this.fetchUrl, this.inputValue).pipe(debounceTime(1000)).subscribe(res=>{
    //   this.expanded = true;
    //   this.dataList = res
    // })
    // }, 100)
  }

  stopPropagation($event: any): void{
    $event.stopPropagation();
    $event.preventDefault();
    clearTimeout(this.onBlurtimeoutId);
  }

  selectValue($event1: any,$event: selectOptionData) {
    // $event1.stopPropagation();
    // $event1.preventDefault();
    this.inputValue = $event?.label
    const index = this.questionBase.key
    this.formService.addQuestion(index,$event)
    this.formService.clearRelatedFields(this.questionBase)
    // @ts-ignore
    this.onBlurtimeoutId = setTimeout( ()=> {this.expanded = false;}, 50);
  }

  onSearch($event: any) {

    // this.formService.clearRelatedFields(this.questionBase, "clearSearch")
    this.fetchData(this.fetchUrl, $event).pipe(debounceTime(1000), distinctUntilChanged()).subscribe(res=>{
      this.dataList = res
    })
  }
}
