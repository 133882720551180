<section class="account-page section-padding">
  <form>
    <div class="container">
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div class="row no-gutters">
            <div class="col-md-4">
              <div class="card account-left">
                <div class="user-profile-header">
                  <div style="min-width: 250px" class="col"><app-images-box [selectClass]="'personal-area'" [imageSelected]="base_url+userInfo.avatar" (onChange)="onChange($event, 0)" [title]="'תמונה ראשית'"></app-images-box></div>
                  <h5 class="mb-1 text-secondary"><strong>{{this.translate.instant('sale4you.label.MyProfile.Hi')}} </strong> {{userInfo.first_name}}</h5>
                  <p> {{userInfo.mainPhone}}</p>
                </div>
                <div class="list-group">

                  <a [routerLink]="['/personal/user-info']" class="list-group-item list-group-item-action active"><i aria-hidden="true" class="mdi mdi-account-outline"></i> {{this.translate.instant('sale4you.label.MyProfile')}}</a>
                  <a  [routerLink]="['/personal/wishlist']" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-heart-outline"></i> {{this.translate.instant('sale4you.label.MyProfile.WishList')}}</a>
                  <a href="#" class="list-group-item list-group-item-action" (click)="logout()"><i aria-hidden="true" class="mdi mdi-lock"></i>  {{this.translate.instant('sale4u.label.logout')}}</a>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-body account-right">
                <div class="widget">
                  <div class="section-header">
                    <h5 class="heading-design-h5">
                      {{this.translate.instant('sale4you.label.MyProfile')}}
                    </h5>
                  </div>
                  <form>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.FirstName')}} </label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.first_name" name="first_name"   placeholder="שם פרטי" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span>  {{this.translate.instant('sale4you.label.MyProfile.LastName')}} </label>
                          <input class="form-control border-form-control" value=""  [(ngModel)]="userInfo.last_name" name="last_name"   placeholder="שם משפחה" type="text">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.PhoneNumber')}} </label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.mainPhone" name="mainPhone" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.DateJoined')}}</label>
                          <input  class="form-control border-form-control"  style="text-align: right;" [ngModel]="userInfo.date_joined | date:'yyyy-MM-dd'"  (ngModelChange)="userInfo.date_joined = $event" type="date" name="date_joined"/>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Country')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.country" name="country" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.FamilyStatus')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.FamilyStatus" name="FamilyStatus" type="text">

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Street')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.street" name="street" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.StreetNumber')}}</label>
                          <input class="form-control border-form-control " value="" [(ngModel)]="userInfo.streetNumber" name="streetNumber"  type="text">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Department')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.department" name="department" type="number">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Birthday')}}</label>
                          <input  class="form-control border-form-control"  style="text-align: right;" [ngModel]="userInfo.birthday | date:'yyyy-MM-dd'"  (ngModelChange)="userInfo.birthday = $event" type="date" name="birthday"/>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.IdExDate')}}</label>
                          <input  class="form-control border-form-control" style="text-align: right;" [ngModel]="userInfo.idExDate | date:'yyyy-MM-dd'"  (ngModelChange)="userInfo.idExDate = $event" type="date" name="idExDate"/>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"><span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.IdNum')}}</label>
                          <input class="form-control border-form-control " value="" [(ngModel)]="userInfo.idNum" name="idNum"  type="number">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Neighborhood')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.neighborhood" name="neighborhood" type="text">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.ZipCode')}}</label>
                          <input class="form-control border-form-control " value="" [(ngModel)]="userInfo.zipCode" name="zipCode"  type="number">
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.PoBox')}}</label>
                          <input class="form-control border-form-control" value="" [(ngModel)]="userInfo.PoBox" name="PoBox" type="number">
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <label class="control-label"> <span class="required">*</span> {{this.translate.instant('sale4you.label.MyProfile.Area')}}</label>
                          <input class="form-control border-form-control "  value="" [(ngModel)]="userInfo.area" name="area"  type="text">
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-sm-12 text-right">
                        <button type="button" (click)="saveUserInfo()" class="btn btn-success btn-lg"> {{this.translate.instant('sale4you.label.MyProfile.SavingChanges')}}</button>
                        <button type="button" class="btn btn-danger btn-lg"> {{this.translate.instant('sale4you.label.MyProfile.Cancelation')}}  </button>

                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
<section class="section-padding bg-white border-top">
  <div class="container" style="text-align: left;">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-car-alt" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.freeDelivery" | translate}}</h6>
          <p>{{"sale4you.label.freeDelivery.value" | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-handshake" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.Guarantee" | translate}}</h6>
          <p>{{"sale4you.label.Guarantee.value" | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-pen" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.Discount" | translate}}</h6>
          <p>{{"sale4you.label.GreatDailyDealsDiscount" | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</section>
