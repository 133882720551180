<div [formGroup]="form" class="form-group questionBox">


  <div [ngSwitch]="question.controlType">
    <div *ngSwitchCase="'bigselectbox'" >
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <app-select-big-box [questionKey]="question.key" [questionBase]="question"></app-select-big-box>
    </div>
    <div *ngSwitchCase="'checkbox'" style="text-align: center; margin: auto;">
      <table class="table table-striped" style="background: whitesmoke;">
        <tr>
          <td  style="text-align: left;width: 48%; direction: ltr; vertical-align: middle;">
            <input style="width: 18px; margin-left: 1em;" class="form-control" *ngSwitchCase="'checkbox'" [formControlName]="question.key" [id]="question.key" [type]="question.type" (change)="valuechange($event)">
          </td>
          <td style="text-align: right; vertical-align: middle;">
            <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
          </td>
        </tr>
      </table>
    </div>
    <div *ngSwitchCase="'selectrelateddescription'">
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <app-select-related-description [questionKey]="question.key"></app-select-related-description>
    </div>
    <div *ngSwitchCase="'textbox'">
        <app-text-field [question]="question" [control]="form.get(question.key)" (onValueChange)="valuechange($event)"></app-text-field>
    </div>
    <div *ngSwitchCase="'textarea'">
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <textarea  class="form-control questionTag input-field-focus"  [formControlName]="question.key" [id]="question.key"  (change)="valuechange($event)"></textarea>
    </div>

    <div *ngSwitchCase="'dropdown'">
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
          <app-ui-select-dropdown [questionBase]="question"></app-ui-select-dropdown>
<!--      <app-dropdown [question]="question" [questionKey]="question.key"></app-dropdown>-->
<!--      <select class="form-control questionTag" (click)="selectOnClick()" (scroll)="dropDownScroll($event)" (change)="updateMySelection($event)"  [id]="question.key"  [formControlName]="question.key">-->
<!--        <option *ngFor="let opt of question.options" [value]="opt">{{opt.label}}</option>-->
<!--      </select>-->
    </div>

    <div *ngSwitchCase="'multiselectbox'" >
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <app-multi-select-box></app-multi-select-box>
    </div>
    <div *ngSwitchCase="'imagesbox'" >
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <app-image-list></app-image-list>
    </div>
    <div *ngSwitchCase="'personcontact'">
      <label class="questionLabel" [attr.for]="question.key">{{question.label}}</label>
      <app-person-contact></app-person-contact>
    </div>
  </div>
  <div class="errorMessage" *ngIf="!isValid">{{question.label}} is required</div>
</div>
