<nav class="navbar navbar-light navbar-expand-lg  bg-faded osahan-menu ">
  <div class="container-fluid container-fluid-top">
    <a class="navbar-brand"  [routerLink]="['/publish/']">
      <button class="publish-search-btn"   >{{'sale4you.label.button.publish' | translate}}<span class="iconify publish-search-icon" data-icon="ant-design:plus-outlined"></span></button>
    </a>
    <ul class="list-inline main-nav-right">
    <li class="list-inline-item cart-btn">
      <a  data-toggle="offcanvas" class="btn btn-link border-none"><i class="mdi mdi-cart"></i> {{'sale4u.label.favorite' | translate }} <small class="cart-value">5</small></a>
    </li>
    </ul>
    <button class="navbar-toggler navbar-toggler-white" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse" id="navbarNavDropdown" style="direction: rtl; ">
      <div class="my-2 my-lg-0">
        <ul class="homeLinks">
          <li class="list-inline-item">
            <a class="btn btn-link" [routerLink]="['']">{{'sale4you.label.link.homePage' | translate}}</a>
          </li>
          <li class="list-inline-item">
            <a href="#" *ngIf="isLogin()" data-target="#bd-example-modal" data-toggle="modal"  class="btn btn-link"><app-personal-area></app-personal-area></a>
          </li>
          <li class="list-inline-item">
            <a href="#" *ngIf="!isLogin()" data-target="#bd-example-modal" data-toggle="modal" (click)="openLogin()" class="btn btn-link"><i class="mdi mdi-account-circle" ></i> {{'sale4u.label.loginSignUp' | translate }}</a>
          </li>
          <li class="list-inline-item">
            <a href="#" *ngIf="isLogin()" data-target="#bd-example-modal" (click)="logout()" class="btn btn-link"><i class="mdi mdi-account-circle" ></i> {{'sale4u.label.logout' | translate }}</a>
          </li>

        </ul>
      </div>
      <app-header-search [categories]="mainCategories" ></app-header-search>

    </div>
  </div>
</nav>

<nav style="direction: rtl" class="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
  <div class="container-fluid container-fluid-bottom">
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto  mt-lg-0 margin-auto">
<!--        <li class="nav-item">-->
<!--          <a class="nav-link shop" href="index.html"><span class="mdi mdi-store"></span> <b>SALE4YOU !</b></a>-->
<!--        </li>-->
        <span *ngFor="let cate of mainCategories ">
        <li *ngIf="cate.subcategories.length == 0 && cate.important" class="nav-item">
          <a href="{{'/shop/'+cate.nameEng}}" class="nav-link">{{cate.name}}</a>
        </li>
        <li *ngIf="cate.subcategories.length > 0 && cate.important" class="nav-item dropdown">
          <a class="nav-link nave-tab-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" (click)="navigateTo('/shop/'+cate.nameEng)" aria-expanded="false">
            {{cate.name}}
          </a>
          <div style="direction: rtl;text-align: center" class="dropdown-menu">
            <a class="dropdown-item" *ngFor="let subCate of cate.subcategories" href="{{'/shop/'+cate.nameEng+'/category/'+subCate.id}}" style="font-size: 11pt; font-weight: bold;"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> {{subCate.name}}</a>
          </div>
        </li>
        </span>
        <span >
            <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{"sale4u.label.more" | translate}}
          </a>
          <div style="direction: rtl;text-align: center" class="dropdown-menu">
            <span *ngFor="let cate of mainCategories">
                <a class="dropdown-item" *ngIf="!cate.important" href="{{'/shop/'+cate.nameEng}}" style="font-size: 11pt; ; font-weight: bold;"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> {{cate.name}}</a>
            </span>
          </div>
        </li>
        </span>

<!--        <li  class="nav-item">-->
          <!--          <a href="index.html" class="nav-link">{{cate.name}}</a>-->
          <!--        </li>-->

<!--        <li *ngFor="let cate of mainCategories" class="nav-item">-->
<!--          <a href="index.html" class="nav-link">{{cate.name}}</a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a href="about.html" class="nav-link">About Us</a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="shop.html">Fruits & Vegetables</a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="shop.html">Grocery & Staples</a>-->
<!--        </li>-->
<!--        <li class="nav-item dropdown">-->
<!--          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            Pages-->
<!--          </a>-->
<!--          <div class="dropdown-menu">-->
<!--            <a class="dropdown-item" href="shop.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Shop Grid</a>-->
<!--            <a class="dropdown-item" href="single.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Single Product</a>-->
<!--            <a class="dropdown-item" href="cart.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Shopping Cart</a>-->
<!--            <a class="dropdown-item" href="checkout.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Checkout</a>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="nav-item dropdown">-->
<!--          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            My Account-->
<!--          </a>-->
<!--          <div class="dropdown-menu">-->
<!--            <a class="dropdown-item" href="my-profile.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  My Profile</a>-->
<!--            <a class="dropdown-item" href="my-address.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  My Address</a>-->
<!--            <a class="dropdown-item" href="wishlist.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Wish List </a>-->
<!--            <a class="dropdown-item" href="orderlist.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Order List</a>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="nav-item dropdown">-->
<!--          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            Blog Page-->
<!--          </a>-->
<!--          <div class="dropdown-menu">-->
<!--            <a class="dropdown-item" href="blog.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Blog</a>-->
<!--            <a class="dropdown-item" href="blog-detail.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i> Blog Detail</a>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="nav-item dropdown">-->
<!--          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
<!--            More Pages-->
<!--          </a>-->
<!--          <div class="dropdown-menu">-->
<!--            <a class="dropdown-item" href="about.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  About Us</a>-->
<!--            <a class="dropdown-item" href="contact.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  Contact Us</a>-->
<!--            <a class="dropdown-item" href="faq.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  FAQ </a>-->
<!--            <a class="dropdown-item" href="not-found.html"><i class="mdi mdi-chevron-right" aria-hidden="true"></i>  404 Error</a>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" href="contact.html">Contact</a>-->
<!--        </li>-->
      </ul>
    </div>
  </div>
</nav>
