import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MainCategory} from "../../../../models/header.model";
import {SubCategories} from "../../../../models/header.model"
import {SystemApp} from "../../../../constans/SystemConfigurations";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {LoginAction, LoginService} from "../../../../components/session/login/login.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.css']
})
export class CategoryItemComponent implements OnInit {

  mainCategories!:MainCategory[];
  subcategories!: SubCategories[];
  selectedCategory!: string;
  baseurl: string = SystemApp.base_url;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  constructor(public translate:TranslateService, private http: HttpClient, private apiKey:ApiUrl, private loginService: LoginService) {
    this.loadData()
  }
  isLogin(){ return !!localStorage.getItem('token') }
  ngOnInit(): void {
    this.selectedCategory = this.apiKey.getNameSpacePos("/",3);
  }

  private loadData() {
    this.mainCategories = []
    this.getPages().subscribe((res) => {
      this.mainCategories = res.filter((cate:any) => cate.category.toLowerCase() == this.selectedCategory).map((cate:any) => {
        return{
          id: cate.id,
          name: cate.name,
          icon: cate.icon,
          nameEng: cate.nameEng,
          subcategories: cate.subcategories
        }})
      this.subcategories = this.mainCategories[0].subcategories;
    })


  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getPages(): Observable<any>{
    return this.http.get(this.apiKey.getPages(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  navigateTo(s: string) {
    window.location.href = s;
  }

  logout() {
    localStorage.removeItem('token')
    this.loginService.sendClickEvent(LoginAction.Close);
  }

  openLogin() {
    this.loginService.sendClickEvent(LoginAction.Open);
  }
}
