<h4><b>{{question.label}}</b></h4>
<div class="row person-contact-box">
    <div class="col-md-6 col-sm-12 " style="margin-top:1em;">
        <span><b>{{"sale4you.label.Ad.NameContact" | translate}}: </b>{{value.contactPerson }}</span>
    </div>
    <div class="col-md-6 col-sm-12" style="margin-top:1em;">
        <span><b>{{"sale4you.label.Ad.NumberContact" | translate}}: </b>{{value.phoneNumber }}</span>
    </div>
    <div class="col-md-6 col-sm-12" style="margin-top:1em;">
        <span><b>{{"sale4you.label.Ad.EmailContact" | translate}}: </b>{{value.email }}</span>
    </div>
    <div class="col-md-6 col-sm-12" style="margin-top:1em;">
        <div class="row">
            <input [ngModel]="value.acceptTerms" disabled type="checkbox" [ngClass]="value.acceptTerms ? 'custom-checkbox custom-checkbox-v' : 'custom-checkbox custom-checkbox-x'"/>
            <b style="margin-right: 3em;">{{"sale4you.label.ads.acceptTerms" | translate}} </b>
        </div>
    </div>
    <div class="col-md-6 col-sm-12" style="margin-top:1em;">
        <div class="row">
            <input [ngModel]="value.acceptAdv" disabled type="checkbox" [ngClass]="value.acceptAdv ? 'custom-checkbox custom-checkbox-v' : 'custom-checkbox custom-checkbox-x'"/>
            <b style="margin-right: 3em;">{{"sale4you.label.ads.acceptAdv" | translate}}</b>
        </div>
    </div>
</div>
