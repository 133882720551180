<section class="offer-product" *ngIf="advertisement">
  <div class="container">
    <div class="row no-gutters">
      <div class="col-md-6 offer-product">
        <a href="#"><img class="img-fluid" [src]="advertisement[0]?.image" alt=""></a>
      </div>
      <div class="col-md-6 offer-product mobileHide">
        <a href="#"><img class="img-fluid" [src]="advertisement[1]?.image" alt=""></a>
      </div>
    </div>
  </div>
</section>
