import {Component, Input, OnInit} from '@angular/core';
import {AppService} from "../../../app.service";
import {Product} from "../../../models/product";
import {TranslateService} from "@ngx-translate/core";
import {SystemApp} from "../../../constans/SystemConfigurations";

@Component({
  selector: 'app-cart-product-item',
  templateUrl: './cart-product-item.component.html',
  styleUrls: ['./cart-product-item.component.css']
})
export class CartProductItemComponent implements OnInit {
  @Input() product!: Product;
  base_url: String;
  constructor(public translate:TranslateService) {
    this.base_url = SystemApp.base_url_api;
  }

  ngOnInit(): void {
  }

}
