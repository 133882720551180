<div class="container">
   <section class="account-page section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 mx-auto">
          <div class="row no-gutters">
            <div class="col-md-4">
              <div class="card account-left">
                <div class="user-profile-header">
                  <div style="min-width: 250px" class="col"><app-images-box [selectClass]="'personal-area'" [imageSelected]="base_url+userInfo.avatar" ></app-images-box></div>
                  <h5 class="mb-1 text-secondary"><strong>{{this.translate.instant('sale4you.label.MyProfile.Hi')}} </strong> {{userInfo.first_name}}</h5>
                  <p> {{userInfo.mainPhone}}</p>
                </div>
                <div class="list-group">
                  <a [routerLink]="['/personal/user-info']" class="list-group-item list-group-item-action active"><i aria-hidden="true" class="mdi mdi-account-outline"></i> {{this.translate.instant('sale4you.label.MyProfile')}}</a>
                  <a [routerLink]="['/personal/wishlist']" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-heart-outline"></i> {{this.translate.instant('sale4you.label.MyProfile.WishList')}}</a>
                  <a href="#" class="list-group-item list-group-item-action"><i aria-hidden="true" class="mdi mdi-lock" (click)="logout()"></i>  {{this.translate.instant('sale4u.label.logout')}}</a>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="card card-body account-right">
                <div class="widget">
                  <div class="section-header">
                    <h5 class="heading-design-h5" [routerLink]="['homePage']">
                      {{this.translate.instant('sale4you.label.MyWishlist')}}
                    </h5>
                  </div>
                  <app-item-in-wishlist></app-item-in-wishlist>
<!--                  <div class="row no-gutters">-->
<!--                    <div class="col-md-6" *ngFor="let item of allWishlistProduct">-->
<!--                        <div class="product">-->
<!--                          <a href="#">-->
<!--                            <div class="product-header" >-->
<!--                              <span class="badge badge-success" *ngIf="item.discount!=null">{{"sale4you.label.discount" | translate}} {{item.discount}}{{"sale4you.label.Percent" | translate}}</span>-->
<!--                              <img alt="" src="{{base_url}}{{item.images[0].image}}" class="img-fluid">-->
<!--                              <span class="veg text-success mdi mdi-circle"></span>-->
<!--                            </div>-->
<!--                            <div class="product-body">-->
<!--                              <h5>{{item.name}}</h5>-->
<!--                              <h6><strong><span class="mdi mdi-approval" *ngIf="item.shortDescription!=null"></span>{{item.shortDescription}}</strong></h6>-->
<!--                            </div>-->
<!--                            <div class="product-footer">-->
<!--                              <p class="offer-price mb-0" *ngIf="item.price!=null">{{"sale4you.label.WishList.shekel" | translate}} {{item.price}} <i class="mdi mdi-tag-outline"></i></p>-->
<!--                            </div>-->
<!--                          </a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                   &lt;!&ndash; <div class="col-md-6">-->
<!--                      <app-product-details-item [header]="'sale4you.label.top6products.nadlan'" [namespace]="'Nadlan'"></app-product-details-item>-->
<!--                    </div>-->
<!--                    <div class="col-md-6">-->
<!--                      <app-product-details-item [header]="'sale4you.label.top6products.sale2'" [namespace]="'Sale2'"></app-product-details-item>-->
<!--                    </div>-->
<!--                    <div class="col-md-6">-->
<!--                      <app-product-details-item [header]="'sale4you.label.top6products.car'" [namespace]="'car'"></app-product-details-item>-->
<!--                    </div>&ndash;&gt;-->
<!--                  </div>-->

                  <nav>
                    <ul class="pagination justify-content-center mt-4">
                      <li class="page-item disabled">
                        <span class="page-link">{{this.translate.instant('sale4you.label.Wishlist.Previous')}}</span>
                      </li>
                      <li class="page-item"><a href="#" class="page-link">1</a></li>
                      <li class="page-item active">
									   <span class="page-link">
									   2
									   <span class="sr-only">(current)</span>
									   </span>
                      </li>
                      <li class="page-item"><a href="#" class="page-link">3</a></li>
                      <li class="page-item">
                        <a href="#" class="page-link">{{this.translate.instant('sale4you.label.Wishlist.Next')}}</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="cart-sidebar">
    <div class="cart-sidebar-header">
      <h5>
        My Cart <span class="text-success">(5 item)</span> <a data-toggle="offcanvas" class="float-right" href="#"><i class="mdi mdi-close"></i>
      </a>
      </h5>
    </div>
    <div class="cart-sidebar-body">
      <div class="cart-list-product">
        <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
        <img class="img-fluid" src="../img/item/11.jpg" alt="">
        <span class="badge badge-success">50% OFF</span>
        <h5><a href="#">Product Title Here</a></h5>
        <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
        <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
      </div>
      <div class="cart-list-product">
        <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
        <img class="img-fluid" src="../img/item/7.jpg" alt="">
        <span class="badge badge-success">50% OFF</span>
        <h5><a href="#">Product Title Here</a></h5>
        <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
        <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
      </div>
      <div class="cart-list-product">
        <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
        <img class="img-fluid" src="../img/item/9.jpg" alt="">
        <span class="badge badge-success">50% OFF</span>
        <h5><a href="#">Product Title Here</a></h5>
        <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
        <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
      </div>
      <div class="cart-list-product">
        <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
        <img class="img-fluid" src="../img/item/1.jpg" alt="">
        <span class="badge badge-success">50% OFF</span>
        <h5><a href="#">Product Title Here</a></h5>
        <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
        <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
      </div>
      <div class="cart-list-product">
        <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
        <img class="img-fluid" src="../img/item/2.jpg" alt="">
        <span class="badge badge-success">50% OFF</span>
        <h5><a href="#">Product Title Here</a></h5>
        <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
        <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
      </div>
    </div>
    <div class="cart-sidebar-footer">
      <div class="cart-store-details">
        <p>Sub Total <strong class="float-right">$900.69</strong></p>
        <p>Delivery Charges <strong class="float-right text-danger">+ $29.69</strong></p>
        <h6>Your total savings <strong class="float-right text-danger">$55 (42.31%)</strong></h6>
      </div>
      <a href="checkout.html"><button class="btn btn-secondary btn-lg btn-block text-left" type="button"><span class="float-left"><i class="mdi mdi-cart-outline"></i> Proceed to Checkout </span><span class="float-right"><strong>$1200.69</strong> <span class="mdi mdi-chevron-right"></span></span></button></a>
    </div>
  </div>

  <script src="../vendor/jquery/jquery.min.js"></script>
  <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <!-- select2 Js -->
  <script src="../vendor/select2/js/select2.min.js"></script>
  <!-- Owl Carousel -->
  <script src="../vendor/owl-carousel/owl.carousel.js"></script>
  <!-- Custom -->
  <script src="../js/custom.js"></script>
</div>
