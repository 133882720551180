import { Component, OnInit } from '@angular/core';
import { Forms, QuestionPriority} from "../../../constans/formsQuestions";
import {Router} from "@angular/router";
import {FormMainCategory} from "../../../models/forms.model";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {AdvFormService} from "../services/adv-form.service";




@Component({
  selector: 'app-main-category-component',
  templateUrl: './main-category.component.html',
  styleUrls: ['./main-category.component.css']
})
export class MainCategoryComponent implements OnInit {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  firstCategory:FormMainCategory[]=[]
  secondCategory:FormMainCategory[]=[]
  thirdCategory:FormMainCategory[]=[]
  selectedCategory!: FormMainCategory |undefined;

  constructor(private router: Router, private http: HttpClient, private apiKey: ApiUrl, private formService: AdvFormService) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData (): void{
    this.formService.loadAdsRouts();
    this.getMainCategory().subscribe(res=>{
      res.forEach((cate:any)=>{
        if(cate.priority == QuestionPriority.FIRST)
          this.firstCategory.push(cate);
        else if(cate.priority == QuestionPriority.SECOND){
          this.secondCategory.push(cate);
        }else if(cate.priority == QuestionPriority.THIRD){
          this.thirdCategory.push(cate);
        }
      })
    })
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getMainCategory(): Observable<any>{
    return this.http.get(this.apiKey.getMainCategory(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  SelectMainCategory(selectedCategory:FormMainCategory) {
    this.selectedCategory = selectedCategory
    this.formService.addQuestion('mainCategory',this.selectedCategory)
    this.router.navigate(['/publish/'+selectedCategory.category.toLowerCase()]);
  }
}
