<div class="cart-sidebar">
  <div class="cart-sidebar-header">
    <h5>
      {{this.translate.instant('sale4you.label.CartSlider.MyFavorites')}}<span class="text-success"> (5 item)</span> <a data-toggle="offcanvas" class="float-right"><i class="mdi fa fa-close"></i>
    </a>
    </h5>
  </div>
  <div class="cart-sidebar-body">
    <app-cart-product-item *ngFor="let wProduct of allWishlistProduct" [product]="wProduct"></app-cart-product-item>
    <!--<div class="cart-list-product">
      <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
      <img class="img-fluid" src="assets/img/item/11.jpg" alt="">
      <span class="badge badge-success">50% OFF</span>
      <h5><a href="#">Product Title Here</a></h5>
      <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
      <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
    </div>
    <div class="cart-list-product">
      <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
      <img class="img-fluid" src="assets/img/item/7.jpg" alt="">
      <span class="badge badge-success">50% OFF</span>
      <h5><a href="#">Product Title Here</a></h5>
      <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
      <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
    </div>
    <div class="cart-list-product">
      <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
      <img class="img-fluid" src="assets/img/item/9.jpg" alt="">
      <span class="badge badge-success">50% OFF</span>
      <h5><a href="#">Product Title Here</a></h5>
      <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
      <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
    </div>
    <div class="cart-list-product">
      <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
      <img class="img-fluid" src="assets/img/item/1.jpg" alt="">
      <span class="badge badge-success">50% OFF</span>
      <h5><a href="#">Product Title Here</a></h5>
      <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
      <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
    </div>
    <div class="cart-list-product">
      <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
      <img class="img-fluid" src="assets/img/item/2.jpg" alt="">
      <span class="badge badge-success">50% OFF</span>
      <h5><a href="#">Product Title Here</a></h5>
      <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
      <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i> <span class="regular-price">$800.99</span></p>
    </div>-->
  </div>
  <div class="cart-sidebar-footer">
    <div class="cart-store-details">
      <p> {{this.translate.instant('sale4you.label.CartSlider.SubTotal')}}<strong class="float-right">$900.69</strong></p>
      <p>{{this.translate.instant('sale4you.label.CartSlider.DeliveryCharges')}} <strong class="float-right text-danger">+ $29.69</strong></p>
      <h6>{{this.translate.instant('sale4you.label.CartSlider.YourTotalSavings')}} <strong class="float-right text-danger">$55 (42.31%)</strong></h6>
    </div>
    <a [routerLink]="['homePage']"><button class="btn btn-secondary btn-lg btn-block text-left" type="button"><span class="float-left"><i class="mdi fa fa-shopping-cart"></i> {{this.translate.instant('sale4you.label.CartSlider.ProceedToCheckout')}}</span><span class="float-right"><strong>$1200.69</strong> <span class="mdi mdi-chevron-right"></span></span></button></a>
  </div>
</div>
