import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true               
};

import { PipesModule } from '../theme/pipes/pipes.module';
import { RatingComponent } from './rating/rating.component';
import { ControlsComponent } from './controls/controls.component';
import { MainCarouselComponent } from './main-carousel/main-carousel.component';
import { BrandsCarouselComponent } from './brands-carousel/brands-carousel.component';
import { ProductsCarouselComponent } from './products-carousel/products-carousel.component';
import { ProductDialogComponent } from './products-carousel/product-dialog/product-dialog.component';
import { BannersComponent } from './banners/banners.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { LangComponent } from './lang/lang.component';
import { PriceViewComponent } from './price-view/price-view.component';
import {CategoryCarouselComponent} from "./category-carousel/category-carousel.component";
import {CategoryDialogComponent} from "./category-carousel/category-dialog/category-dialog.component";
import {ClearWhiteSpace} from "../Pipes/clear-whitespace";
import {FilterByString} from "../admin/components/search-field/search-admin-field.component";
import {FilterByStringPipe} from "../Pipes/filter-by-string";
import {AdsSocialmediaComponent} from "../pages/personal/ads/components/ads-socialmedia/ads-socialmedia.component";
import {AdsControlsComponent} from "../pages/personal/ads/components/ads-controls/ads-controls.component";
import {PersonContactAdsComponent} from "../pages/personal/ads/components/person-contact/person-contact-ads.component";
import {
    MultiSelectBoxAdsComponent
} from "../pages/personal/ads/components/multi-select-box/multi-select-box-ads.component";
import {DropdownFieldComponent} from "../pages/personal/ads/components/dropdown/dropdown-field.component";
import {CheckBoxAdsComponent} from "../pages/personal/ads/components/check-box-ads/check-box-ads.component";
import {TextBoxInputComponent} from "../pages/personal/ads/components/text-box-input/text-box-input.component";
import {TextAreaAdsComponent} from "../pages/personal/ads/components/text-area-ads/text-area-ads.component";
import {
    ImageListDisplayComponent
} from "../pages/personal/ads/components/image-list-display/image-list-display.component";
import {
    SelectRelatedDescriptionComponent
} from "../components/select-related-description/select-related-description.component";
import {BigSelectBoxComponent} from "../pages/personal/ads/components/big-select-box/big-select-box.component";
import {DynamicFormDialogService} from "../admin/components/dynamic-form-dialog/dynamic-form-dialog.service";
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TypingMessageComponent } from './typing-message/typing-message.component';
import { FormatInputDirective } from './format-input-directive/format-input-directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SwiperModule,
    TranslateModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PerfectScrollbarModule,
    PipesModule,
  FormsModule,
  ReactiveFormsModule,
  ],
    exports: [
        RouterModule,
        SwiperModule,
        TranslateModule,
        FlexLayoutModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatStepperModule,
        PerfectScrollbarModule,
        PipesModule,
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        CategoryCarouselComponent,
        CategoryDialogComponent,
        ProductDialogComponent,
        BannersComponent,
        CategoryListComponent,
        ConfirmDialogComponent,
        LangComponent,
        PriceViewComponent,
        ClearWhiteSpace,
        FilterByString,
        FilterByStringPipe,
        AdsSocialmediaComponent,
        AdsControlsComponent,
        AdsSocialmediaComponent,
        PersonContactAdsComponent,
        MultiSelectBoxAdsComponent,
        DropdownFieldComponent,
        CheckBoxAdsComponent,
        TextBoxInputComponent,
        TextAreaAdsComponent,
        ImageListDisplayComponent,
        SelectRelatedDescriptionComponent,
        BigSelectBoxComponent,
        TypingMessageComponent,
    ],
    declarations: [
        RatingComponent,
        ControlsComponent,
        MainCarouselComponent,
        BrandsCarouselComponent,
        ProductsCarouselComponent,
        ProductDialogComponent,
        CategoryCarouselComponent,
        CategoryDialogComponent,
        BannersComponent,
        CategoryListComponent,
        ConfirmDialogComponent,
        LangComponent,
        PriceViewComponent,
        ClearWhiteSpace,
        FilterByString,
        FilterByStringPipe,
        AdsSocialmediaComponent,
        AdsControlsComponent,
        PersonContactAdsComponent,
        MultiSelectBoxAdsComponent,
        DropdownFieldComponent,
        CheckBoxAdsComponent,
        TextBoxInputComponent,
        TextAreaAdsComponent,
        ImageListDisplayComponent,
        SelectRelatedDescriptionComponent,
        BigSelectBoxComponent,
        ConfirmationDialogComponent,
        TypingMessageComponent,
        FormatInputDirective,

    ],
  providers:[
      DynamicFormDialogService,
      { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
    schemas:[CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule { }
