<div class="PersonContact" >
  <div class="row keep-top-far">
    <div class="col" style="min-width: 250px;">
      <label for="presonContact">איש קשר</label>
      <input id="presonContact" class="form-control" (ngModelChange)="onChange($event)" [disabled]="isUserValidGetter"  [(ngModel)]="personContact.contactPerson"  type="text" />
    </div>
  </div>
  <div class="row keep-top-far">
    <div class="col" style="min-width: 250px;">
      <label for="phoneContact">מספר טלפון</label>
      <table style="width: 100%">
        <tr>
          <td><input type="text" (ngModelChange)="onChange($event)" [(ngModel)]="personContact.phoneNumber" [disabled]="isUserValidGetter" id="phoneContact" class="form-control"   /></td>
          <td *ngIf="!isUserValidGetter"><button  (click)="validatePhone()" class="form-control phoneValidate">אימות מספר</button></td>
          <td *ngIf="isUserValidGetter"><button [disabled]="true" mat-raised-button class="form-control phoneValidate" style="background: mediumseagreen; color:white; font-weight: bold;"><mat-icon>check</mat-icon> מספר מאומת</button></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row keep-top-far">
    <div class="col">
      <table class="checkBoxTable">
        <tr>
          <td><input (ngModelChange)="onChange($event)" [disabled]="isUserValidGetter" [(ngModel)]="personContact.acceptTerms" type="checkbox"/></td>
          <td><span>קראתי ואישרתי את תקנון</span></td>
        </tr>
      </table>
    </div>
  </div>
  <div class="row keep-top-far">
    <div class="col">
      <table class="checkBoxTable">
        <tr>
          <td><input (ngModelChange)="onChange($event)" [disabled]="isUserValidGetter" [(ngModel)]="personContact.acceptAdv"  type="checkbox"/></td>
          <td><span>אני רוצה לקבל עדכונים רלוונטיים למודעה שלי במייל</span></td>
        </tr>
      </table>
    </div>
  </div>
</div>
