import {Component, Inject, Input, OnInit} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {StatusAds, UserAds} from "../../../../models/user-ads";
import {SystemApp} from "../../../../constans/SystemConfigurations";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from '@ngx-translate/core';
import {AppService} from "../../../../app.service";
import {FormGroup} from "@angular/forms";
import {QuestionService} from "../../../create-adv/services/question.service";
import {Observable} from "rxjs";
import {QuestionBase} from "../../../create-adv/models/question-base";
import {QuestionControlService} from "../../../create-adv/services/question-control.service";
import {SectionUI} from "../../../create-adv/dynamic-form/dynamic-form.component";
import {ProductType} from "../../../../app.models";

@Component({
  selector: 'app-ads-page',
  templateUrl: './ads-page-component-dialog.component.html',
  styleUrls: ['./ads-page-component-dialog.component.scss']
})

export class AdsPageComponentDialog implements OnInit {
  @Input()
  adsItem!:UserAds;
  baseUrl: string;
  questions$!: Observable<QuestionBase<any>[]>;
  @Input() form!: FormGroup;
  public sections: SectionUI[];
  public namespace: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<AdsPageComponentDialog>,public translate:TranslateService, public appService: AppService,public service: QuestionService, private qcs: QuestionControlService) {
    this.adsItem = data.selectAds;
    console.log(this.adsItem)
    this.namespace = data.namespace.toLowerCase();
    this.baseUrl = SystemApp.base_url_api
  }

  set setDataInForm(data: any){

  }
  myForm: FormGroup;

  translateData(object:any, value:string): string{
    const delimiterNum = value.$len("*")
    if(delimiterNum == 0 ){
      const strIndex = value.$piece("*",1)
      return object !=null ? object[strIndex]: null;
    }else{
      for(var i=1; i<=delimiterNum+1 ;i++){
        if(object == null)
          break
        object = object[value.$piece("*", i)]
      }
      return object !=null && object !='' ? object : null;
    }
  }

  setSectionQuestions(questions: QuestionBase<string>[] | null): SectionUI[] {
    let section: SectionUI[] = [] || null;
    let SectionMap = new Map();
    questions?.forEach((res: QuestionBase<string>) => {
      // @ts-ignore
      var qS: SectionUI = res.section
      if (SectionMap.get(qS.number) == undefined) {
        SectionMap.set(qS.number, {
          description: qS.description,
          number: qS.number,
          namespace: qS.namespace,
          expanded: qS.expanded,
          questions: [],
          requiredQuestion: [],
          callBackFunction: qS.callBackFunction
        })
      }
      SectionMap.get(qS.number).questions.push(res);
      if (res.required === true) {
        SectionMap.get(qS.number).requiredQuestion.push(res);
      }
    })
    SectionMap.forEach((value, index) => {
      section.push(value);
    })
    return section;
  }
  ngOnInit(): void {
    const namespace = this.namespace;
    this.service.getServerQuestions(namespace).subscribe((res)=>{
      res.subscribe(res=>{
        this.sections = this.setSectionQuestions(res).sort(function (a, b) {
          return a.number === b.number ? 0 : a.number < b.number ? -1 : 1;
        });
        this.setDataToQuestions(this.adsItem);
      })
      console.log(this.sections)
    })
  }


  getStatusDesc(deviceStatus: string) {
    if(deviceStatus == StatusAds.Active) return 'sale4you.label.status.active';
    if(deviceStatus == StatusAds.Done) return 'sale4you.label.status.Done';
    if(deviceStatus == StatusAds.Delete) return 'sale4you.label.status.Delete';
    if(deviceStatus == StatusAds.inReview) return 'sale4you.label.status.inReview';
    if(deviceStatus == StatusAds.Pause) return 'sale4you.label.status.Pause';
  }
  getFieldMappSale2(key:any){
    let map= {}
    map["sconderyCategory"]="sconderyCategory*name",
    map["subCategory"]="subCategory*subCategoryName",
    map["Sale2ThirdCategory_degem"]="model",
    map["ProductStatus"]="ProductStatus*statusName",
    map["sale2ThirdCategory_thirdCategory"]="producer",
    map["name"]="name",
    map["DeliveryOffer"]="DeliveryOffer",
    map["price"]="price",
    map["city"]="City*nameHe",
    map["street"]="Street*nameHe",
    map["FreeText"]="FreeText",
    map["images"]="images",
    map["userContactDetails"]="userContactDetails"
    return map[key];
  }
  getFieldMappNadlan(key:any){
    let map= {}
    map["sconderyCategory"]="sconderyCategory*name"
    map["TypeOfNadlan"]="TypeOfNadlan*nameHe"
    map["StatusOfNadlan"]="StatusOfNadlan*nameHe"
    map["city"]="City*nameHe"
    map["street"]="Street*nameHe"
    map["HomeNum"]="HomeNum"
    map["Floor"]="Floor"
    map["TotalFloor"]="TotalFloor"
    map["OnColumns"]="OnColumns"
    map["Neighborhood"]="Neighborhood"
    map["Area"]="Area"
    map["Mailers"]="Mailers"
    map["RoomsNum"]="RoomsNum*numberOfRooms"
    map["ParkingNumber"]="ParkingNumber*numberParking"
    map["armatureList"]="armatureList"
    map["FreeText"]="FreeText"
    map["numberOfPays"]="numberOfPays"
    map["condo"]="condo"
    map["arnona"]="arnona"
    map["buildingMeter"]="buildingMeter"
    map["buildingMeterTotal"]="buildingMeterTotal"
    map["price"]="price"
    map["Entrance"]="Entrance"
    map["availabilityEntrance"]="availabilityEntrance"
    map["flexible"]="flexible"
    map["longTime"]="longTime"
    map["images"]="images"
    map["userContactDetails"]="userContactDetails"
    return map[key];
  }
  getFieldMappCar(key:any){
    let map= {}
    map['sconderyCategory'] = 'sconderyCategory*name'
    map['NumberOfOwner'] = 'NumberOfOwner*numberOnwer'
    map['mainCategory'] = 'mainCategory*name'
    map['color'] = 'Color*colorName'
    map['description'] = 'FreeText'
    map['name'] = 'name'
    map['FreeText'] = 'FreeText'
    map['producerDescription'] = 'CodeProducer'
    map['price'] = 'price'
    map['discount'] = 'discount'
    map['CodeProducer'] = 'CodeProducer'
    map['mainModelDescription'] = 'MainModel'
    map['year'] = 'year'
    map['DrivingTest'] = 'DrivingTest'
    map['YearTest'] = 'YearTest'
    map['MonthTest'] = 'MonthTest'
    map['mileage'] = 'mileage'
    map['engineCapacity'] = 'engineCapacity'
    map['gearBox'] = 'gearBox'
    map['city'] = 'city*nameHe'
    map['licenseNumber'] = 'licenseNumber'
    map['DisabledSuitable'] = 'DisabledSuitable'
    map['exchange'] = 'exchange'
    map['monthOnRoad'] = 'monthOnRoad'
    map['CurrentOwner'] = 'CurrentOwner'
    map['PastOwner'] = 'PastOwner'
    map['numberOfOwner'] = 'NumberOfOwner*numberOnwer'
    map['PastOwner'] = 'PastOwner'
    map['images']='images'
    map['armatureList']='armatureList'
    map["userContactDetails"]="userContactDetails"
    return map[key];
  }
  getFieldMapping(key:any, resourceType:string){
    if(resourceType.toLowerCase() == ProductType.CAR.toLowerCase()){
     return this.getFieldMappCar(key);
    }
    if(resourceType.toLowerCase() == ProductType.NADLAN.toLowerCase()){
      return this.getFieldMappNadlan(key);
    }
    if(resourceType.toLowerCase() == ProductType.SALE2.toLowerCase()) {
      return this.getFieldMappSale2(key);
    }
    return {}
  }
  setDataToQuestions(data: UserAds): void{
    this.sections.forEach(section=>{
      section.questions.forEach(question=>{
        if(this.getFieldMapping(question.key, this.namespace) != undefined){
          question.value = this.translateData(data,this.getFieldMapping(question.key, this.namespace))
        }
      })
    })
  }
  getClasses(question: QuestionBase<any>) {
    if(question.addClass == null || question.addClass ==''){ return '' };
    return question.addClass;
  }

  getStatusIcon(statusAds: StatusAds) {
    if(statusAds == StatusAds.Active) return 'active-icon';
    if(statusAds == StatusAds.Done) return 'active-icon';
    if(statusAds == StatusAds.Delete) return 'inactive-icon';
    if(statusAds == StatusAds.inReview) return 'inactive-icon';
    if(statusAds == StatusAds.PayPending) return 'inactive-icon';
    if(statusAds == StatusAds.Pause) return 'inactive-icon';
    return undefined;
  }
}
