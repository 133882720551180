import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-icon-cell-renderer',
  templateUrl: './icon-cell-renderer.component.html',
  styleUrls: ['./icon-cell-renderer.component.scss']
})
export class IconCellRendererComponent implements  ICellRendererAngularComp {
  icon: string;

  constructor() { }

  ngOnInit(): void {
  }

  agInit(params: ICellRendererParams): void {
    this.icon = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

}
