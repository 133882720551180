import { Component, OnInit } from '@angular/core';

import {CarouselSlider} from "../../models/carousel-slider";
import {SystemApp} from "../../constans/SystemConfigurations";
import {LoadingMaskServiceService} from "../loading-frame/loading-frame.component";
import {CarouselSliderService} from "../../services/carouselSlider.service";
import {SwiperConfigInterface, SwiperPaginationInterface} from "ngx-swiper-wrapper";

declare function MainSilder(): any;
@Component({
  selector: 'app-carousel-slider',
  templateUrl: './carousel-slider.component.html',
  styleUrls: ['./carousel-slider.component.scss']
})
export class CarouselSliderComponent implements OnInit {

  carouselSlider!: CarouselSlider[];
  serverUrl!:String;
  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };

  constructor(private carouselSliderService: CarouselSliderService, private loadingMaskServiceService: LoadingMaskServiceService) {
    this.initVariables();
    this.loadData()
  }

  initVariables(){
    this.serverUrl=SystemApp.base_url_api;
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: true,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      autoplay: {
        delay: 6000,
        disableOnInteraction: false
      },
      speed: 500,
      effect: "slide"
    }
  }

  loadData(){
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.carouselSliderService.getCarouselSlider().subscribe(res=>{
      this.carouselSlider = res.CarouselSlider
      MainSilder()
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })
  }

  ngOnInit(): void {
    // LoadSliderJs()
  }

}
