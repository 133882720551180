import {SectionUI} from "../dynamic-form.component";
import {TranslateService} from "@ngx-translate/core";



export const generalFunction = (value: any, translateService: TranslateService) =>{
  if(value == undefined) return "";
  const string = value?.map((i:any)=>i?.label).filter((str:any)=>str !=null && str.length != 0).join(", ");
  return string;
}


const cutString = (string: string) => {
  const innerWidth = window.innerWidth;
  let from = 0, to =95;
  if(0 < innerWidth && innerWidth < 560){ to = 35 }
  if(560 < innerWidth && innerWidth < 760){ to = 45 }
  if(760 < innerWidth && innerWidth < 1200){ to = 80 }
  if(string.length ==0){return ""};

  return string.substr(from, to) + " ... ";
}

export const advCarToDay = (value: any, translateService: TranslateService) =>{
  const carType = value[0].name
  return translateService.instant("sale4you.label.advForm.advCarToDay",{carType: carType});
}


export const sectionFunction:any= {
  'generalFunction': generalFunction,
  'advCarToDay': advCarToDay,
}
