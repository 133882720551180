import {selectOptionData} from "../dynamic-form-question/dynamic-form-question.component";

export interface Section{
  number: number;
  description: string;
  namespace: string;
  expanded: boolean;
  callBackFunction: string;
}

export class QuestionBase<T> {
  value: T|undefined;
  key: string;
  keyArr: string;
  label: string;
  required: boolean;
  regex: string;
  order: number;
  controlType: string;
  type: string;
  page: number;
  section: Section | {};
  options: selectOptionData[];
  callBackFunction: string;
  relatedField: string;
  additionalParam: string;
  bindSelectHide: boolean;
  addClass: string;
  parentMapValue: string
  dropDownLabels: string[]
  constructor(options: {
    value?: T;
    key?: string;
    keyArr?: string;
    label?: string;
    required?: boolean;
    regex?: string;
    order?: number;
    controlType?: string;
    type?: string;
    page?: number;
    section?:{
      number: number;
      description: string;
      namespace: string;
      expanded: boolean;
      callBackFunction: string;
    };
    options?: selectOptionData[];
    callBackFunction?: string;
    relatedField?: string;
    additionalParam?: string;
    bindSelectHide?: boolean;
    addClass?: any;
    parentMapValue?: string
    dropDownLabels?: string[]
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.keyArr = options.keyArr || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.regex = options.regex || '';
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.page = options.page || 1;
    this.section = options.section || {}
    this.options = options.options || [];
    this.callBackFunction = options.callBackFunction || '';
    this.relatedField = options.relatedField || '';
    this.additionalParam = options.additionalParam || '';
    this.bindSelectHide = options.bindSelectHide || false;
    this.addClass = options.addClass;
    this.parentMapValue = options.parentMapValue;
    this.dropDownLabels = options.dropDownLabels || []
  }
}

