import { Menu } from './menu.model'; 

export const menuItems = [

    new Menu (10, ' ADMIN_NAV.DASHBOARD', '/admin', null, 'dashboard', null, false, 0),
    new Menu (21, 'ADMIN_NAV.CATEGORIES', '/admin/products/categories', null, 'category', null, false, 20),
    new Menu (22, 'ADMIN_NAV.PRODUCT_LIST', '/admin/products/product-list', null, 'list', null, false, 20), 
    new Menu (23, 'ADMIN_NAV.PRODUCT_DETAIL', '/admin/products/product-detail', null, 'remove_red_eye', null, false, 20),
    new Menu (24, 'ADMIN_NAV.ADD_PRODUCT', '/admin/products/add-product', null, 'add_circle_outline', null, false, 20),

    new Menu (30, 'ADMIN_NAV.TABLES', '/admin/tables/', null, 'add_circle_outline', null, true, 0),
    new Menu (31, 'ADMIN_NAV.armatureList', '/admin/tables/armature-list', null, '', null, false, 30),
    new Menu (32, 'ADMIN_NAV.adsRoute', '/admin/tables/ads-route', null, '', null, false, 30),
    new Menu (33, 'ADMIN_NAV.cars', '/admin/tables/cars', null, '', null, true, 30),
    new Menu (331,'ADMIN_NAV.color', '/admin/tables/cars/colors', null, '', null, false, 33),
    new Menu (332,'ADMIN_NAV.month_list', '/admin/tables/cars/month_list', null, '', null, false, 33),
    new Menu (333,'ADMIN_NAV.year_list', '/admin/tables/cars/years_list', null, '', null, false, 33),
    new Menu (334,'ADMIN_NAV.owner_list', '/admin/tables/cars/owner_list', null, '', null, false, 33),
    new Menu (335,'ADMIN_NAV.car_hand_list', '/admin/tables/cars/car-hand-list', null, '', null, false, 33),
    new Menu (336,'ADMIN_NAV.month_on_road_list', '/admin/tables/cars/month_on_road_list', null, '', null, false, 33),
    new Menu (337,'ADMIN_NAV.archive.car-dgm-list', '/admin/tables/archive/car-dgm-list', null, '', null, false, 33),

    new Menu (34, 'ADMIN_NAV.sale2', '/admin/tables/sale2', null, '', null, true, 30),
    new Menu (341, 'ADMIN_NAV.sale2-subcategory-list', '/admin/tables/sale2/sale2-subcategory-list', null, '', null, false, 34),
    new Menu (342, 'ADMIN_NAV.sale2-third-category', '/admin/tables/sale2/sale2-third-category', null, '', null, false, 34),
    new Menu (343, 'ADMIN_NAV.product-status-list', '/admin/tables/sale2/product-status-list', null, '', null, false, 34),

    new Menu (35, 'ADMIN_NAV.nadlan', '/admin/tables/nadlan', null, '', null, true, 30),
    new Menu (351, 'ADMIN_NAV.nadlan-status-nadlan-list', '/admin/tables/nadlan/nadlan-status-nadlan-list', null, '', null, false, 35),
    new Menu (352, 'ADMIN_NAV.nadlan-type-nadlan-list', '/admin/tables/nadlan/nadlan-type-nadlan-list', null, '', null, false, 35),
    new Menu (353, 'ADMIN_NAV.nadlan-room-numbers-list', '/admin/tables/nadlan/nadlan-room-numbers-list', null, '', null, false, 35),
    new Menu (354, 'ADMIN_NAV.nadlan-parking-list', '/admin/tables/nadlan/nadlan-parking-list', null, '', null, false, 35),
    new Menu (355, 'ADMIN_NAV.nadlan-balcony-list', '/admin/tables/nadlan/nadlan-balcony-list', null, '', null, false, 35),
    // new Menu (30, 'ADMIN_NAV.SALES', null, null, 'monetization_on', null, true, 0),
    //new Menu (31, 'ADMIN_NAV.ORDERS', '/admin/sales/orders', null, 'list_alt', null, false, 30),
    // new Menu (32, 'ADMIN_NAV.TRANSACTIONS', '/admin/sales/transactions', null, 'local_atm', null, false, 30),
    new Menu (40, 'ADMIN_NAV.USERS', '/admin/users', null, 'group_add', null, false, 0),
    new Menu (41, 'ADMIN_NAV.HOMEPAGE', '/admin/homepage', null, 'homepage', null, true, 0),
    new Menu (42, 'ADMIN_NAV.BANNERS', '/admin/homepage/banners', null, 'banner', null, false, 41),
    new Menu (43, 'ADMIN_NAV.SLIDER', '/admin/tables/homepage/carousel-slider-admin', null, 'slider', null, false, 41),
    new Menu (44, 'ADMIN_NAV.VIDEOBACKGROUND', '/admin/homepage/background', null, 'video', null, false, 41),
    new Menu (45, 'ADMIN_NAV.ADVERTISEMENTS', null, null, 'grid_on', null, true, 0),
    new Menu (46, 'ADMIN_NAV.ADVERTISEMENTS', '/admin/advertisements', null, 'advertisements', null, false, 45),

    new Menu(60, 'ADMIN_NAV.ECOMMERCE', '/admin', null, 'list', null, true, 0),
    new Menu(61, 'ADMIN_NAV.ORDERS', '/admin/ecommerce/orders-list', null, null, null, false, 60),
    new Menu(62, 'ADMIN_NAV.i-eighteen-n', '/i18n/iEighteenN', null, 'label-translate', null, false, 0),

    new Menu(50, "sale4you.label.Processing", '/admin/process',null, 'table',null, false,0),
]
