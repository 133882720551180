import { Component, OnInit } from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {FormSubCategory} from "../../models/forms.model";

export interface accessories{
  id: number,
  secondaryCategory: number,
  mainCategory: number,
  ArmatureIcon: string,
  ArmatureDesc: string
}

@Component({
  selector: 'app-multi-select-box',
  templateUrl: './multi-select-box.component.html',
  styleUrls: ['./multi-select-box.component.css']
})
export class MultiSelectBoxComponent implements OnInit {
  mainCategory!: number;
  list!: any[]
  selectedList: any[]=[]
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl, private formService: AdvFormService) {
    this.loadData();
  }


  ngOnInit(): void {
    this.getAccessories().subscribe((res) => {
      this.list = res.map((res: any)=>{
        return {
          id: res.id,
          secondaryCategory: res.SocondryCategory,
          mainCategory: res.MainCategory,
          armatureIcon: res.ArmatureIcon,
          ArmatureDesc: res.ArmatureDesc
        }
      })
    })
  }

  highlightSelectedData(category: any): string{
    if(this.selectedList.indexOf(category) != -1){
      return 'selectMultiBox';
    }
    return '';
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getAccessories(): Observable<any>{
    const nameSpace = this.apiKey.getNameSpace()
    return this.http.get(this.apiKey.getAccessories(nameSpace), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  loadData(): void{

  }

  selectItem(accessItm: any) {
    const pos = this.selectedList.indexOf(accessItm)
    if(pos == -1){
      this.selectedList.push(accessItm);
    }else{
      this.selectedList.$remove(accessItm);
    }
    if(this.selectedList.length>0){
      this.formService.addQuestion('armatureList',this.selectedList);
    }else{
      this.formService.deleteQuestion('armatureList');
    }

  }
}
