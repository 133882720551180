
<div #dropdown class="sale4u-dropdow" >
    <table style="height: 100%; margin-top: 1em;">
        <tr>
            <td>
                <label style="font-weight: bold; white-space: nowrap;"> &nbsp;</label>
            </td>
        </tr>
        <tr style="vertical-align: middle;">
            <td style="vertical-align: middle;">
                <input style=" height: 15px; width: 15px;" type="checkbox" [(ngModel)]="selectDate" class="dateField">
                &nbsp;<label style="font-weight: bold; white-space: nowrap;"> {{ !hideTitle ? filterBase.label : '&nbsp;'}}</label>
            </td>
        </tr>
    </table>


</div>
