import { Component, OnInit, Input } from '@angular/core';
import { SidenavMenuService } from './sidenav-menu.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  providers: [ SidenavMenuService ]
})
export class SidenavMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  expanded = false;
  parentMenu:Array<any>;

  constructor(private sidenavMenuService:SidenavMenuService, public router:Router) { }


  get isExpanded(){
    return this.expanded;
  }
  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }

  onClick($event, menuId){
    this.expanded = this.expanded ? false : true;
    this.sidenavMenuService.toggleMenuItem(menuId);
    this.sidenavMenuService.closeOtherSubMenus(this.menuItems, menuId);    
  }

  goToCategory($event, menuId) {
    $event.stopPropagation()
    this.router.navigate(['/products', menuId]);
  }
}
