import { Injectable } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class MetaTagService {

  private metaTags: Record<string, string> = {};

  constructor(private title: Title) {}

  setMetaTag(name: string, content: string): void {
    if(name == 'title'){
      this.title.setTitle(content);
    }else{
      this.metaTags[name] = content;
      this.updateMetaTag(name);
    }

  }

  setFavicon(newFaviconUrl: string) {
    const linkTag = document.querySelector("link[rel*='icon']") || document.createElement('link');
    linkTag.setAttribute('type', 'image/x-icon');
    linkTag.setAttribute('rel', 'shortcut icon');
    linkTag.setAttribute('href', newFaviconUrl);

    if (!document.querySelector("link[rel*='icon']")) {
      document.head.appendChild(linkTag);
    }
  }
  private updateMetaTag(name: string): void {
    const existingTag = document.querySelector(`meta[name="${name}"]`);
    if (existingTag) {
      existingTag.setAttribute('content', this.metaTags[name]);
    } else {
      // If the meta tag doesn't exist, create it
      const newMetaTag = document.createElement('meta');
      newMetaTag.name = name;
      newMetaTag.content = this.metaTags[name];
      document.head.appendChild(newMetaTag);
    }
  }
}
