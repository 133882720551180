<div *ngIf="selectedCategory == null" id="CreateAdvContainer">
  <h1>אני רוצה לפרסם מודעה בלוח ...</h1>
  <div class="row">
    <div *ngFor="let category of firstCategory" class="col categoryBox first" (click)="SelectMainCategory(category)">
      <mat-icon aria-hidden="false"  aria-label="Example home icon">{{category.icon}}</mat-icon>
      <label>{{category.name}}</label>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let category of secondCategory" class="col categoryBox second" (click)="SelectMainCategory(category)">
      <mat-icon aria-hidden="false"  aria-label="Example home icon">{{category.icon}}</mat-icon>
      <label>{{category.name}}</label>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let category of thirdCategory" class="col categoryBox third" (click)="SelectMainCategory(category)">
      <label>{{category.name}}</label>
    </div>
  </div>
</div>
