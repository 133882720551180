<!--<div style="width: 100%; height: 30px;">-->
<!--        <div style="cursor:pointer; float: right; margin: 2px; background: whitesmoke; box-shadow: 0px 0px 2px -1px;"  *ngFor="let color of product.color" (click)="updateProductColor(product, color)" [style]="product.selectedColor != null && product.selectedColor[0].id == color.id ? 'border:2px solid #6495ED;' : ''">-->
<!--            &lt;!&ndash;<div  class="row">-->
<!--                <div  class="col" style="text-align: center;">{{color.count}}</div>-->
<!--            </div>&ndash;&gt;-->
<!--            <div *ngIf="color.code == null" class="row">-->
<!--                <div  [ngStyle]="{'background-image': 'url(' + color.image + ')',' background-size': '100% 100%'}" class="col" [style.width]="'25px'" [style.height]="'15px'" ></div>-->
<!--            </div>-->
<!--            <div *ngIf="color.code != null" class="row">-->
<!--                <div  [style.background]="color.code" class="col" [style.width]="'25px'" [style.height]="'15px'" ></div>-->
<!--            </div>-->
<!--        </div>-->
<!--</div>-->
<!--<div class="divider "></div>-->
<div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="product?.availibilityCount > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">{{'sale4you.label.Quantity' | translate }}:</span>
            <button mat-icon-button matTooltip="{{'sale4you.label.remove' | translate}}" (click)="decrement(product)"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="{{'sale4you.label.add' | translate}}" (click)="increment(product)"><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div><br></div>
    <div *ngIf="type!='wish'">
        <button mat-icon-button matTooltip="הוסף לרשימת המועדפים" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>
        <button mat-icon-button matTooltip="הוסף להזמנה" *ngIf="product?.availibilityCount > 0 && product?.price" (click)="addToCart(product)"><mat-icon>shopping_cart</mat-icon></button>
        <button mat-icon-button matTooltip="הוסף להשוואה" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>
        <button *ngIf="type!='all'" mat-icon-button matTooltip="{{'sale4you.label.QuickView' | translate}}" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button>
    </div>
</div> 
<div *ngIf="product?.availibilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
    {{'sale4you.label.SorryThisItemIsUnavailablePleaseChooseADifferentOne' | translate }}
</div>
