<div class="row adsContainer" *ngIf="sections">
    <div class="col-md-3 col-sm-12 detailsProduct">
        <div class="row">
            <div class="col-5" style="white-space: nowrap"><b>{{'sale4you.label.createDate'| translate }}:</b></div>
            <div class="col-7" style="white-space: nowrap">{{adsItem.createTime | date:"dd/MM/YYYY HH:mm"}}</div>
        </div>
        <div class="row">
            <div class="col-5" style="white-space: nowrap"><b>{{'sale4you.label.MyProfile.DateJoined'| translate }}:</b> </div>
            <div class="col-7" style="white-space: nowrap">{{adsItem.date_joined | date:"dd/MM/YYYY HH:mm"}}</div>
        </div>
        <div class="row">
            <div class="col-5" style="white-space: nowrap"><b>{{'sale4you.label.active'| translate }}:</b> </div>
            <div class="col-7" style="white-space: nowrap">
                <div class="row">
                    <div class="col">{{getStatusDesc(adsItem.statusAds) | translate}}</div>
                    <div class="col"><mat-icon  style=" font-size: 15px; width: 15px; height: 15px;" [ngClass]="getStatusIcon(adsItem.statusAds)" >fiber_smart_record</mat-icon></div>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <div class="row">
            <div class="col-4">
                <div class="align-items-center"  class="imageAdsProfileBox"><img class="imageAdsProfile" [src]="adsItem.adsRoute.image" alt=""/></div>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-5" style="white-space: nowrap"><b>{{'sale4you.label.adsTitle'| translate }}:</b></div>
                    <div class="col-7" style="white-space: nowrap">{{adsItem.adsRoute.title}}</div>
                </div>
                <div class="row">
                    <div class="col-5" style="white-space: nowrap"><b>{{'sale4you.label.price'| translate }}:</b></div>
                    <div class="col-7" style="white-space: nowrap">₪{{adsItem.adsRoute.price}}</div>
                </div>
            </div>
        </div>
        <div class="divider"></div>
        <app-ads-controls [(adsItem)]="adsItem"></app-ads-controls>
        <div class="divider"></div>
        <app-ads-socialmedia [adsItem]="adsItem"></app-ads-socialmedia>
    </div>
    <div class="col-md-9 col-sm-12">
        <div class="adsContainer mobileSectionBox" *ngIf="sections">
            <div class="row" *ngFor="let section of sections" style="text-align: right;direction: rtl">
                <div class="divider"></div>
                <div *ngFor="let question of section.questions" [ngClass]="getClasses(question)!= '' ? getClasses(question) :'col-md-4 col-sm-12'" [ngSwitch]="question.controlType">
                    <div *ngSwitchCase="'dropdown'">
                        <app-dropdown-field [question]="question" [value]="question.value"></app-dropdown-field>
                    </div>
                    <div *ngSwitchCase="'checkbox'">
                        <app-check-box-ads [question]="question" [value]="question.value"></app-check-box-ads>
                    </div>
                    <div *ngSwitchCase="'textbox'">
                        <app-text-box-input [question]="question" [value]="question.value"></app-text-box-input>
                    </div>
                    <div *ngSwitchCase="'textarea'">
                        <app-text-area-ads [question]="question" [value]="question.value"></app-text-area-ads>
                    </div>
                </div>
                <div  *ngFor="let question of section.questions" [ngClass]="getClasses(question)!= '' ? getClasses(question) :'col-sm-12'" [ngSwitch]="question.controlType">
                    <div *ngSwitchCase="'imagesbox'">
                        <app-image-list-display [question]="question" [value]="question.value"></app-image-list-display>
                    </div>
                    <div *ngSwitchCase="'selectrelateddescription'">
                        <app-select-related-description [question]="question" [value]="question.value"></app-select-related-description>
                    </div>
                    <div *ngSwitchCase="'bigselectbox'">
                        <app-big-select-box [question]="question" [value]="question.value"></app-big-select-box>
                    </div>
                    <div *ngSwitchCase="'multiselectbox'">
                        <app-multi-select-box-ads [question]="question" [value]="question.value"></app-multi-select-box-ads>
                    </div>
                    <div *ngSwitchCase="'personcontact'">
                        <app-person-contact-ads [question]="question" [value]="question.value"></app-person-contact-ads>
                    </div>

                </div>
                <div class="adsFooter" ></div>
            </div>
        </div>
    </div>
</div>
<!--<div class="col-9 mobileSectionBox">-->
<!--  <div class="row">-->
<!--    <h1 class="col titleAndIconSection" >{{'sale4you.label.Ad.DescriptionOfTheAd' | translate}}</h1>-->
<!--  </div>-->
<!--  <div class="row paadingTop1">-->
<!--    <div class="col detailsProduct leftLine"  >-->
<!--      <div><b>{{'sale4you.label.producer'| translate }}:</b>   {{adsItem.CodeProducer}}</div>-->
<!--      <div><b>{{'sale4you.label.model'| translate }}:</b>   {{adsItem.MainModel}}</div>-->
<!--      <div><b>{{'sale4you.label.year'| translate }}:</b>   {{adsItem.year}}</div>-->
<!--      <div><b>{{'sale4you.label.gearBox'| translate }}:</b>   {{adsItem.gearBox}}</div>-->
<!--      <div><b>{{'sale4you.label.engineCapacity'| translate }}:</b>   {{adsItem.engineCapacity}}</div>-->
<!--      <div><b>{{'sale4you.label.Color'| translate }}:</b>   {{adsItem.Color?adsItem.Color.colorName:null}}</div>-->
<!--      <div><b>{{'sale4you.label.numberOwner'| translate }}:</b>   {{adsItem.NumberOfOwner ? adsItem.NumberOfOwner.numberOnwer: null}} </div>-->
<!--      <div><b>{{'sale4you.label.kilometer'| translate }}:</b>   {{adsItem.mileage}}</div>-->
<!--      <div><b>{{'sale4you.label.CurrentOwner'| translate }}:</b>   {{adsItem.CurrentOwner}}</div>-->
<!--      <div><b>{{'sale4you.label.PastOwner'| translate }}:</b>   {{adsItem.PastOwner}}</div>-->
<!--    </div>-->

<!--    <div class="detailsProduct leftLine" [ngStyle]="appService.isMobile() ? { width: '100%' } : {  }" >-->
<!--      <div><b>{{'sale4you.label.monthOnRoad'| translate }}:</b>  {{'sale4you.label.Month'| translate }} {{adsItem.monthOnRoad}} </div>-->
<!--      <div><b>{{'sale4you.label.YearTest'| translate }}:</b>   {{adsItem.YearTest}}</div>-->
<!--      <div><b>{{'sale4you.label.MonthTest'| translate }}:</b> {{adsItem.MonthTest}}  </div>-->
<!--      <div><b>{{'sale4you.label.licenseNumber'| translate }}: </b> {{adsItem.licenseNumber}}</div>-->

<!--    </div>-->

<!--    <div class="detailsProduct" style="margin:0" >-->
<!--      <div><b>{{'sale4you.label.details'| translate }}:</b>   {{adsItem.description}}</div>-->
<!--      <div><b>{{'sale4you.label.additionalDetails'| translate }}:</b>   {{adsItem.FreeText}}</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<br/>-->
<!--<div class="col-3 mobileSectionBox">-->
<!--  <div class="row">-->
<!--    <h1 class="col titleAndIconSection" >{{'sale4you.label.Ad.Manage' | translate}}</h1>-->
<!--  </div>-->
<!--  <div class="row paadingTop1">-->
<!--    <div class="col detailsProduct">-->
<!--      <div><span>{{'sale4you.label.WeHaveNotApprovedTheAd'| translate }}</span></div>-->
<!--      <div class="paadingTopHalf"><button type="button" class="EditingDtailsButton" >{{'sale4you.label.EditingDtails'| translate }} </button></div>-->
<!--      <div><b>{{'sale4you.label.active'| translate }}:</b>  {{getStatusDesc(adsItem.active) | translate}}</div>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="row paadingTopOneAndHalf">-->
<!--    <h1 class="col titleAndIconSection" >{{'sale4you.label.DescriptionOfTheAd' | translate}}</h1>-->
<!--  </div>-->
<!--  <div class="row paadingTop1">-->
<!--    <div class="col detailsProduct">-->
<!--      <div><b>{{'sale4you.label.createDate'| translate }}:</b>  {{adsItem.createTime | date:'short'}}</div>-->
<!--      <div><b>{{'sale4you.label.MyProfile.DateJoined'| translate }}:</b>  {{adsItem.date_joined | date:'short'}}</div>-->
<!--      <div><b>{{'sale4you.label.active'| translate }}:</b>  {{getStatusDesc(adsItem.active) | translate}}</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--</div>-->
<!--<br/>-->
<!--<div class="row">-->
<!--  <div class="col-12">-->
<!--    <h1 class="col titleAndIconSection" >{{'sale4you.label.Ad.ContactInformation'| translate }}</h1>-->
<!--    <div class="detailsProduct">-->
<!--      <div><b>{{'sale4you.label.Ad.NameContact'| translate }}:</b>   {{adsItem.userContactDetails.contactPerson}} </div>-->
<!--      <div><b>{{'sale4you.label.Ad.NumberContact'| translate }}:</b>   {{adsItem.userContactDetails.phoneNumber}}</div>-->
<!--      <div><b>{{'sale4you.label.Ad.EmailContact'| translate }}:</b>   {{adsItem.userContactDetails.email}}</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<br/>-->
<!--<div class="row">-->
<!--  <div class="col-12">-->
<!--    <h1 class="col titleAndIconSection" >{{'sale4you.label.Ad.Images'| translate }}</h1>-->
<!--    <div *ngFor="let image of adsItem.images"  style="margin: 1em; direction:rtl;" >-->
<!--      <img src="{{baseUrl}}{{image.image}}"  width="100" height="100">-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->