import {Component, OnInit, Input, Pipe, PipeTransform} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {Category} from "../../../app.models";



@Pipe({
  name: 'myfilter',
  pure: true
})
export class MyFilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    if(filter.cate == null){
      return items.filter(cate => cate.parentId == null);
    }
    return items.filter(cate => cate.parentId?.id == filter.cate?.id);
  }
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public categories:Category[] =[];
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()

    })
  }

  public mainCate = [];

  constructor(private http: HttpClient,private apiKey:ApiUrl,) { }

  ngOnInit() {
    this.getCategories().subscribe(res => {
      this.categories = res.object_list;
    })
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  public getCategories():Observable<any>{
    return this.http.get(this.apiKey.getCategories(), this.options)
        .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
        );
  }

  openMegaMenu(){
    let pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function (el) {
        if(el.children.length > 0){
          if(el.children[0].classList.contains('mega-menu')){
            el.classList.add('mega-menu-pane');
          }
        }        
    });
  }

}
