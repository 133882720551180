<footer class="bg-primary footer">
    <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="border-bottom-mute subscribe-block theme-container">
        <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
            <h1 class="fw-500">{{'sale4you.footer.header.h1' | translate}}</h1>
            <h3 class="fw-300 secondary-color"> {{'sale4you.footer.header.subTitle' | translate}}</h3>
        </div>
        <form method="get" class="subscribe-search-form" fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
            <input type="text" placeholder="{{'sale4you.footer.header.yourEmail' | translate}}" fxFlex>
            <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="mat-elevation-z0 text-muted">{{'sale4you.footer.header.Subscribe' | translate}}</button>
        </form>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="space-between" class="py-3 border-bottom-mute theme-container">        
        <div fxFlex="100" fxFlex.gt-sm="20" fxFlex.sm="40"> 
            <h3 class="col-title">{{'sale4you.footer.header.UsefulLinks' | translate}}</h3>
            <p class="mt-2"><a href="javascript:void(0);" class="link secondary-color">{{'sale4you.footer.header.MyAccount' | translate}}</a></p>
            <p class="mt-1"><a routerLink="/contact" class="link secondary-color">{{'sale4you.footer.label.contactUs' | translate}}</a></p>
            <p class="mt-1"><a routerLink="/compare" class="link secondary-color"> {{'sale4you.footer.header.Compare' | translate}}</a></p>
            <p class="mt-1"><a routerLink="/wishlist" class="link secondary-color"> {{'sale4you.footer.label.Wishlist' | translate}}</a></p>
            <p class="mt-1"><a routerLink="/checkout" class="link secondary-color">{{'sale4you.footer.label.HomePage.CheckOut' | translate}}</a></p>
            <p class="mt-1"><a href="javascript:void(0);" class="link secondary-color">{{'sale4you.footer.header.fqa' | translate}}</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="60" ngClass.xs="mt-2" class="contact-information"> 
            <h3 class="col-title">{{'sale4you.footer.header.UsefulLinks' | translate}}</h3>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-2 secondary-color">
                <mat-icon>location_on</mat-icon>
                <span> {{'sale4you.footer.header.location' | translate}}</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>call</mat-icon>
                <span>{{'sale4you.footer.header.PhoneNumber' | translate}}</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>mail_outline</mat-icon>
                <span>{{'sale4you.footer.header.email' | translate}}</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>schedule</mat-icon>
                <span>{{'sale4you.footer.header.OpeningDays' | translate}}</span>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions</mat-icon>
                <a href="javascript:void(0);" class="link secondary-color">{{'sale4you.footer.header.getDirections' | translate}}</a>
            </p>
            <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                <mat-icon>directions_bus</mat-icon>
                <span>{{'sale4you.footer.header.routesToUs' | translate}}</span>
            </p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" ngClass.sm="mt-2" ngClass.xs="mt-2"> 
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
            </agm-map>
        </div>
    </div>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1"> {{'sale4you.footer.header.Copyright' | translate}}</p>
        <p> {{'sale4you.footer.header.writenByAbed' | translate}} <a mat-button href="http://themeseason.com/" target="_blank"> {{'sale4you.footer.header.DesignedBy' | translate}}</a></p>
    </div>
</footer>
