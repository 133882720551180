<div *ngFor="let menu of parentMenu" class="menu-item">
    <a *ngIf="menu.routerLink && !menu.hasSubMenu" mat-button
        fxLayout="row" fxLayoutAlign="start center"
        [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"        
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{ menu.title | translate }}</span>
    </a>
    <a *ngIf="menu.href && !menu.subMenu" mat-button 
        fxLayout="row" fxLayoutAlign="start center"
        [attr.href]="menu.href || ''" [attr.target]="menu.target || ''"       
        (click)="onClick(menu.id)" [id]="'menu-item-'+menu.id">
        <span class="menu-title">{{ menu.title | translate }}</span>
    </a>
    <a *ngIf="menu.hasSubMenu"
        fxLayout="row" fxLayoutAlign="start center" #menuTitle
         [id]="'menu-item-'+menu.id">
        <table>
            <tr>
                <td *ngIf="menu.routerLink == null"><span style="display: block; z-index: 999; padding: 0em 1em;"  class="menu-title">{{ menu.title | translate }}</span></td>
                <td><a *ngIf="menu.routerLink != null" [routerLink]="[menu.routerLink]"><span style="display: block; z-index: 999; padding: 0em 1em;"  class="menu-title">{{ menu.title | translate }}</span></a></td>
                <td><mat-icon (click)="onClick($event,menu.id)" *ngIf="isExpanded">keyboard_arrow_down</mat-icon></td>
                <td><mat-icon (click)="onClick($event,menu.id)" *ngIf="!isExpanded">keyboard_arrow_left</mat-icon></td>
            </tr>
        </table>
    </a>

    <div *ngIf="menu.hasSubMenu" class="sub-menu" [id]="'sub-menu-'+menu.id">
        <app-sidenav-menu [menuItems]="menuItems" [menuParentId]="menu.id"></app-sidenav-menu>
    </div>
</div>
