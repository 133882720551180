<section  class="product-items-slider section-padding">
  <div class="container">
    <div class="section-header">
      <div class="row" style="direction: rtl;">
        <div class="col-8">
          <h5 class="heading-design-h5">{{header | translate}}&nbsp;&nbsp;<span class="badge badge-primary"> {{'sale4you.label.homepage.top' | translate}} </span>
          </h5>
        </div>
        <div class="col-4 show-all-btn">
          <a class="text-secondary show-all-btn" [href]="['shop/'+namespace.toLowerCase()]">{{'sale4you.label.homepage.showall.btn' | translate}}</a>
        </div>
      </div>


    </div>
            <div class="owl-carousel owl-carousel-featured-{{namespace}}">
      <div *ngFor="let product of productList; let i = index" [attr.data-index]="i" class="item slider-item">
        <div class="product" [style]="product.isNewAds?'background:#fbfbef':''">
          <a [routerLink]="['/single/'+product.id]">
            <div class="product-header">

              <span class="badge badge-success" *ngIf="product.discount!=null">{{this.translate.instant('sale4you.label.discount')}}: {{product.discount}}{{this.translate.instant('sale4you.label.Percent')}}</span>
              <img class="img-fluid" src="{{product.image}}" alt="">
              <span *ngIf="product.isNewAds" class="veg text-success mdi mdi-circle is-new-label">{{this.translate.instant('sale4you.label.NewAd')}}</span>
            </div>
          </a>
          <a [routerLink]="['/single/'+product.id]">
            <div class="product-body TextAlignRight">
              <h5>{{productList[i].name}}</h5>
              <h6 style="direction: rtl;"><strong><span class="mdi mdi-approval"></span> </strong> {{product.shortDescription}}</h6>
            </div>
          </a>

            <div class="product-footer TextAlignCenter">

             <button type="button" class="btn btn-secondary btn-sm float-right" (click)="addToWishList($event,product)"> {{this.translate.instant('sale4you.label.CartSlider.ProceedToCheckout')}}</button>
              <p class="offer-price mb-0" style="height: 0px;" *ngIf="product.price!=null"> {{this.translate.instant('sale4you.label.WishList.shekel')}} {{product.price}}<i class="mdi mdi-tag-outline"></i><br></p>
            </div>




        </div>
      </div>
    </div>
  </div>
</section>

<!--  <div fxLayout="row" fxLayout.xs="column" [fxLayoutAlign]="align" class="text-muted">
    <div *ngIf="product?.availibilityCount > 0 && (type=='all' || type=='wish')">
        <bdi>
            <span *ngIf="type!='wish'" class="fw-500">{{'sale4you.label.Quantity' | translate }}:</span>
            <button mat-icon-button matTooltip="{{'sale4you.label.remove' | translate}}" (click)="decrement(product)"><mat-icon>remove</mat-icon></button>
            <span> {{count}} </span>
            <button mat-icon-button matTooltip="{{'sale4you.label.add' | translate}}" (click)="increment(product)"><mat-icon>add</mat-icon></button>
        </bdi>
    </div>
    <div><br></div>
    <div *ngIf="type!='wish'">
        <button mat-icon-button matTooltip="הוסף לרשימת המועדפים" (click)="addToWishList(product)"><mat-icon>favorite</mat-icon></button>
        <button mat-icon-button matTooltip="הוסף להזמנה" *ngIf="product?.availibilityCount > 0 && product?.supplierPrice" (click)="addToCart(product)"><mat-icon>shopping_cart</mat-icon></button>
        <button mat-icon-button matTooltip="הוסף להשוואה" (click)="addToCompare(product)"><mat-icon>compare</mat-icon></button>
        <button *ngIf="type!='all'" mat-icon-button matTooltip="{{'sale4you.label.QuickView' | translate}}" (click)="openProductDialog(product)"><mat-icon>remove_red_eye</mat-icon></button>
    </div>
</div>
<div *ngIf="product?.availibilityCount == 0 && type=='all'" class="bg-warn p-1 mt-2">
    {{'sale4you.label.SorryThisItemIsUnavailablePleaseChooseADifferentOne' | translate }}
</div> -->
