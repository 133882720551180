
<div #dropdown class="sale4u-dropdow" >
    <table>
        <tr>
            <td>
                <label style="font-weight: bold; white-space: nowrap;"> {{ !hideTitle ? filterBase.label : '&nbsp;'}}</label>
            </td>
        </tr>
        <tr>
            <td>
                <input type="date" [(ngModel)]="selectDate" class="dateField">
            </td>
        </tr>
    </table>


</div>
