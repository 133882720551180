
<app-login></app-login>
<div class="container-fluid-top">
    <app-top-navbar></app-top-navbar>
    <app-header></app-header>
</div>
<router-outlet></router-outlet>
<app-section-padding></app-section-padding>
<app-footer></app-footer>
<app-loading-frame></app-loading-frame>
<app-cart-sidebar></app-cart-sidebar>
