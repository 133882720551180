import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-image-list-display',
  templateUrl: './image-list-display.component.html',
  styleUrls: ['./image-list-display.component.scss']
})
export class ImageListDisplayComponent implements OnInit, OnDestroy {

  @Input() question: QuestionBase<string>;
  @Input() value: {image: string, SortNum: number};
  @ViewChild('imageList', { static: false }) imageList: ElementRef;
  scrollOffset = 150; // Adjust the scroll offset as needed
  animationFrameId: number;

  scrollRight() {
    this.animateScroll(-this.scrollOffset);
  }

  scrollLeft() {
    this.animateScroll(this.scrollOffset);
  }

  animateScroll(offset: number) {
    const targetScrollLeft = this.imageList.nativeElement.scrollLeft + offset;

    const animate = () => {
      const currentScrollLeft = this.imageList.nativeElement.scrollLeft;
      const distance = targetScrollLeft - currentScrollLeft;

      if (Math.abs(distance) > 1) {
        this.imageList.nativeElement.scrollLeft += distance / 10; // Adjust the divisor for the desired speed
        this.animationFrameId = requestAnimationFrame(animate);
      } else {
        this.imageList.nativeElement.scrollLeft = targetScrollLeft;
      }
    };

    this.animationFrameId = requestAnimationFrame(animate);
  }

  ngOnDestroy() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  ngOnInit(): void {
  }
}
