<div class="dynamic-form formBox" id="formBoxId">
  <form (ngSubmit)="onSubmit()" [formGroup]="form" >
    <div *ngFor="let section of sections">
      <mat-expansion-panel #panel *ngIf="(section.namespace =='CAR' && section.number == 22)" [expanded]="section.expanded" class="sectionBox" [style.overflow]="!expandFlag?'unset':'hidden'"  (click)="onClickPanel(panel)">
        <mat-expansion-panel-header (click)="expandWindow(section)">
          <mat-panel-title class="panelTitleBox">
            <div class="panelTitle">
              <h1 for="">{{section.description}}</h1>
            </div>
            <div class="col sectionDescription">
              <app-text-wrapper [toolTip]="sectionDescription[section.number]">
                {{sectionDescription[section.number] }}
              </app-text-wrapper>
            </div>
          </mat-panel-title>
          <mat-panel-description style="width: 70px !important; padding: 0;">
            <div *ngIf="sectionMissingFlg[section.number] == true" style="text-align: left;" class="col"><img class="iconFlag missing"   src="./assets/icons/missing.png" alt=""></div>
            <div *ngIf="sectionMissingFlg[section.number] == false" style="text-align: left;" class="col"><img class="iconFlag checkingV"   src="./assets/icons/checkV.png" alt=""></div>
          </mat-panel-description>
        </mat-expansion-panel-header>
          <div class="body-content">
            <app-car-type [section]="section" [questions]="getQuestions(section.number)" [form]="form"></app-car-type>
          </div>
      </mat-expansion-panel>
      <mat-expansion-panel #panel1 *ngIf="!(section.namespace =='CAR' && section.number == 22)" [expanded]="section.expanded" class="sectionBox" [style.overflow]="!expandFlag?'unset':'hidden'" (click)="onClickPanel(panel1)" >
        <mat-expansion-panel-header (click)="expandWindow(section)">
          <mat-panel-title class="panelTitleBox">
            <div class="panelTitle">
                <h1 for="">{{section.description}}</h1>
            </div>
            <div class="col sectionDescription">
              <app-text-wrapper [toolTip]="sectionDescription[section.number]">
                {{sectionDescription[section.number] }}
              </app-text-wrapper>
            </div>
          </mat-panel-title>

          <mat-panel-description style="width: 70px !important;">
            <div *ngIf="sectionMissingFlg[section.number] == true" style="text-align: left;" class="col"><img class="iconFlag missing" src="./assets/icons/missing.png" alt=""></div>
            <div *ngIf="sectionMissingFlg[section.number] == false" style="text-align: left;" class="col"><img class="iconFlag checkingV"  src="./assets/icons/checkV.png" alt=""></div>
          </mat-panel-description>

        </mat-expansion-panel-header>
          <div class="body-content">
            <div *ngFor="let question of getQuestions(section.number)" style="text-align: center;">
              <app-question [question]="question" *ngIf="!(question.type == 'bindSelect' && !question.bindSelectHide)" [form]="form"></app-question>
            </div>
          </div>
      </mat-expansion-panel>
    </div>
    <mat-expansion-panel  [expanded]="greenPass" [disabled]="!greenPass" class="sectionBox" >
      <mat-expansion-panel-header >
        <mat-panel-title class="panelTitleBox" >
          <div style="width: 100%" class="row panelTitle">
            <div class="col"><h1  for="">{{"sale4all.label.adv.choose.route" | translate}}</h1></div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-panel-description>
        <app-select-route [greenPass]="greenPass"></app-select-route>
      </mat-panel-description>
    </mat-expansion-panel>
  </form>
</div>
