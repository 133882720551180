<!--<app-top-category></app-top-category>-->
<app-carousel-slider></app-carousel-slider>
<app-product-items [header]="'sale4you.label.top6products.car'" [namespace]="'car'"></app-product-items>
<app-advertisement [advertisementType]="'BannerAdv'" ></app-advertisement>
<app-product-items [header]="'sale4you.label.top6products.nadlan'" [namespace]="'Nadlan'"></app-product-items>
<app-advertisement [advertisementType]="'BannerAdv'" ></app-advertisement>
<app-product-items [header]="'sale4you.label.top6products.sale2'" [namespace]="'Sale2'"></app-product-items>
<app-advertisement [advertisementType]="'BannerAdv'" ></app-advertisement>
<!--<app-cart-sidebar></app-cart-sidebar>-->
<router-outlet></router-outlet>
