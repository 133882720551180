import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormGroup} from "@angular/forms";
import {Observable, of} from "rxjs";
import {CellClickedEvent, ColDef, FirstDataRenderedEvent, GridReadyEvent} from "ag-grid-community";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";



export interface ConfirmValidation{
  acceptAdv: boolean,
  acceptTerms: boolean,
  contactPerson: string,
  email: string,
  phoneNumber: string,
  userId: number,
  codeValidation: number | null,

}
@Component({
  selector: 'app-session-dialog',
  templateUrl: './phone-validation-dialog.component.html',
  styleUrls: ['./phone-validation-dialog.component.scss'],
  animations: [
    trigger('shakeit', [
      state('shakestart', style({
        transform: 'scale(1)',
      })),
      state('shakeend', style({
        transform: 'scale(1)',
      })),
      transition('shakestart => shakeend', animate('1000ms ease-in',     keyframes([
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
      ]))),
    ])]
})
export class PhoneValidationDialogComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiUrl.getJWTToken()
    })
  }

  public form!:FormGroup;
  // selectedDays: any =[];
  selectedDays: any;
  states:any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmValidation,
    public apiUrl: ApiUrl,
    public http: HttpClient,
    public dialogRef: MatDialogRef<PhoneValidationDialogComponent>) {
    this.data.codeValidation = null;

  }

  shakeMe(stateVar: string) {
    this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
  }

  shakeEnd(stateVar: string, event:any) {
    this.states[stateVar] = 'shakeend';
  }

  private ConfirmValidation(){

  }
  private validationNumber!: number;

  set validationNumberSetter(input: number){
    this.data.codeValidation = input;
  }

  get validationNumberGetter(){
    return this.data.codeValidation;
  }

  ngOnInit(): void {}

  close() {
      this.dialogRef.close();
  }

  defaultColDef: any;
  public rowData$!: Observable<{ name: any }>;
  inputStatus:boolean =true;

  set inputStatusSetter(status: boolean){
    this.inputStatus = status;
  }
  get inputStatusGetter(): string{
    if(this.inputStatus){
      return ''
    }
    return 'errorValidation'
  };

  set acceptTermsSetter(acceptTerms: boolean){
    this.data.acceptTerms = acceptTerms;
  }

  set acceptAdvSetter(acceptAdv: boolean){
    this.data.acceptAdv = acceptAdv;
  }

  validationButton(validationNumberGetter: number | null) {
    if((validationNumberGetter==null || 999999 < validationNumberGetter || validationNumberGetter < 100000) || (!this.data.acceptTerms || !this.data.acceptAdv)){
      return true;
    }
    return false;
  }

  confirmValidation() {
    this.http.post(this.apiUrl.getPhoneValidationUrl(),this.data, this.options).subscribe((res:any)=>{
      if(res.status == "success"){
        this.close();
      }else{

      }
    },error => {
      console.log(error)
      this.shakeMe('state1')
      this.inputStatusSetter = false;
    })
  }
  confirm() {
  }
}
