import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {selectOptionData} from "../../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";
// declare function InitSelect(id:string, fetchUrl:string): any;
@Component({
  selector: 'app-select-remote',
  templateUrl: './select-remote.component.html',
  styleUrls: ['./select-remote.component.css']
})
export class SelectRemoteComponent implements OnInit,AfterViewInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  @Input() fetchUrl!: string;
  generatedClass:string;
  selectedValue: any;
  dataList!: selectOptionData[]

  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();

  @ViewChild('select') select!: ElementRef;
  @Input()  model!: number | string;
  @Output() modelChange = new EventEmitter<number>();


  expanded: boolean = false;

  inputValue!: string;
  isLoading: boolean = false;

  constructor(protected http: HttpClient, protected apiKey:ApiUrl) {
    this.generatedClass = 'remote-select-' + Math.random().toString(36).substr(2, 9);;
  }

  checkRequiredFields(input:any) {
    if(input === null) {
      throw new Error("Attribute 'a' is required");
    }
  }

  ngAfterViewInit(): void{

  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  fetchData(url: string, query: string = ''): Observable<any>{
    if(query != null && query != undefined && query != ''){
      if(url.includes("?")){
        url = url+"q="+query
      }else{
        url = url+"?q="+query
      }
    }
    return this.http.get(url, this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  ngOnInit(): void {
    this.checkRequiredFields(this.fetchUrl);
  }

  selectValue($event: selectOptionData) {
    this.inputValue = $event.label
    this.parentFun.emit($event)
  }
  onClick(){
    if(this.expanded == true){
      return;
    }

    this.isLoading = true;
    this.fetchData(this.fetchUrl).subscribe(res=>{
      this.dataList = res;
      this.expanded = true;
      this.isLoading = false;
    })
  }
  onExit($event:any) {
    setTimeout( ()=> {this.expanded = false;}, 100);
  }

  onChange($event: any) {
    if($event.code == "ArrowDown" || $event.code == "ArrowRight" || $event.code == "ArrowLeft" || $event.code == "ArrowUp")
      return;
    this.fetchData(this.fetchUrl, this.inputValue).subscribe(res=>{
      this.dataList = res
    })
  }
}
