import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-free-adv',
  templateUrl: './free-adv.component.html',
  styleUrls: ['./free-adv.component.css']
})
export class FreeAdvComponent implements OnInit {

  @Input() dialogRef:any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }


  closeDialog() {
    this.modalService.dismissAll();
  }
}
