import {MainCategory, Product} from "./product";
import {RouteType} from "../pages/create-adv/select-route/select-route.component";

export interface AdsRoute{
  "id": number,
  "title": string,
  "description": string,
  "price": number,
  "lifeSpan": number,
  "image": string,
  "level": RouteType
}
export interface ProductAds{
  name: string,
  description: string,
  price:number,
  typeProduct: string,
  mainCategory: MainCategory,
  sconderyCategory:{id:number, mainCategoryKey:MainCategory[]} ,
  area:string,
  deviceStatus:string,
  createTime:string,
  updateTime:string,
  User:UserAds,
  resourcetype:string,
  userContactDetails:any,
  armatureList: { ArmatureDesc:string,ArmatureIcon:string,MainCategory:number,SocondryCategory:number }[],
  images: {image: string, SortNum:number}[];
  active:string,

}


export interface CarAds extends ProductAds{
  CodeProducer:string,
  MainModel: { model:string,codeMainModel:number },
  year:string,
  Color:{colorName: string | null, colorCode: string | null},
  monthOnRoad:string,
  DrivingTest:boolean,
  YearTest:number,
  MonthTest:string,
  NumberOfOwner: { numberOnwer:string },
  CurrentOwner:string,
  PastOwner:string,
  mileage:number,
  engineCapacity:number,
  gearBox:number,
  licenseNumber:number,
  DisabledSuitable:boolean,
  FreeText:string,
  date_joined:string,
  exchange:boolean,
}

export interface SaleAds extends ProductAds {
  name:string,
  Street:string,
  DeliveryOffer:string,
  City:{nameHe:string},
  FreeText: string,
  createTime:string,

}
export enum StatusAds{
  Active="Active", Delete="Delete", inReview="inReview", Pause="Pause", Done="Done", Blocked="Blocked", PayPending="PayPending"
}
export interface NadlanAds extends ProductAds{
  TypeOfNadlan:any,//should to chek
  City:any,// should to chek
  Street:any,// should to chek
  HomeNum:number,
  Floor:number,
  TotalFloor:number,
  OnColumns:boolean,
  Neighborhood:string,
  Area: string,
  AdvsConfirmation:boolean,
  RoomsNum:any,// should to chek
  ParkingNumber:any,//should to chek
  Entrance:string,
  Balcony:any,// should to chek
  Mailers:boolean,
  StatusOfNadlan:any,// should to chek
  properties:any[],//should to check it
  FreeText:string,
  TermsConfirmation:boolean,

}

export interface UserAds {
  id: number,
  images: {image: string, SortNum:number}[];
  name: string,
  FreeText:string,
  description:string,
  year:number,
  price: number,
  DrivingTest:boolean,
  YearTest:number,
  MonthTest:string,
  mileage:number,
  engineCapacity:number,
  gearBox:number,
  licenseNumber:number,
  area: string,
  polymorphic_ctype:number,
  userContactDetails:any,
  adsRoute: AdsRoute,
  armatureList: { ArmatureDesc:string,ArmatureIcon:string,MainCategory:number,SocondryCategory:number }[],
  sconderyCategory:{id:number, mainCategoryKey:MainCategory[],name:string} ,
  CodeProducer: { producer:string,codeProducer:string },
  MainModel: { model:string,codeMainModel:number },
  NumberOfOwner: { numberOnwer:string },
  mainCategory: MainCategory,
  Color:{colorName: string | null, colorCode: string | null},
  createTime: string,
  date_joined:string,
  deviceStatus: string,
  active:string,
  PastOwner:string,
  CurrentOwner:string,
  monthOnRoad:string,
  User:string,
  Area:string,
  updateTime:string,
  TypeOfNadlan: { nameHe:string },
  City:{nameHe:string},
  Street:{nameHe:string},
  HomeNum:number,
  Floor:number,
  TotalFloor:number,
  Neighborhood:string,
  RoomsNum:{numberOfRooms:number},
  ParkingNumber:{numberParking:number},
  Mailers:number,
  TermsConfirmation:string,
  statusAds: StatusAds
  resourcetype:string,


}
