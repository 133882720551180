<form class="example-form">
    <mat-form-field fxFlex="100"  class="example-full-width"  appearance="fill">
        <mat-label>{{dataStructure.title}}</mat-label>
        <input type="text"
               placeholder="Pick one"
               aria-label="Number"
               matInput
               (blur)="onChange()"
               [formControl]="myControl"
               [matAutocomplete]="auto">
        <mat-autocomplete autoActiveFirstOption (selectionchange)="onChange()" #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" (select)="onChange()" [value]="option[dataStructure.name]">
                {{option[dataStructure.name]}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
