import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {AdsRoute} from "../../../../models/user-ads";
import {Router} from "@angular/router";
import {timer} from "rxjs";

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.css']
})
export class CreditCardComponent implements OnInit {

  sessionUrl: string = null;
  adsId: number;
  constructor(public dialogRef: MatDialogRef<CreditCardComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer, private router: Router) {
    // this.sessionUrl = this.data['zCreditSessionUrl']['sessionUrl'];
    this.adsId = data.adsId

    this.dialogRef.afterClosed().subscribe(student => {

    });

  }

  closeDialog(){

  }
  getSafeSessionUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.sessionUrl);
  }
  ngOnInit(): void {
  }

}
