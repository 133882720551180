<div class="brands-carousel" *ngIf="brands">
    <div class="swiper-container h-100" [swiper]="config">
        <a mat-button routerLink="/brands">{{'sale4you.brands.ShowAllBrands' | translate }}</a>
        <div class="swiper-wrapper h-100">      
            <div *ngFor="let brand of brands" class="swiper-slide">
                <div class="p-0">
                    <a [routerLink]="['/brands', brand.name]" class="brand-item">
                        <img [attr.data-src]="brand.image" class="swiper-lazy">
                    </a>
                    <div class="swiper-lazy-preloader"></div>
                </div>
            </div>
        </div> 
        <button mat-icon-button class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button> 
    </div>
</div>
