import {Component, Input, OnInit} from '@angular/core';
import {AdvService} from "../../core/advertisement/service/advertisement.service";
import {Advertisement} from "../../core/advertisement/advertisement.component";

@Component({
  selector: 'app-offer-product',
  templateUrl: './offer-product.component.html',
  styleUrls: ['./offer-product.component.css']
})
export class OfferProductComponent implements OnInit {

  imageSrc1: any;
  imageSrc2: any;
  @Input() advertisement!: Advertisement[];
  constructor(private advService:AdvService) {

  }

  ngOnInit(): void {
  }

}
