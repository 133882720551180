import {AfterViewInit, ViewChild, Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ImageCroppedEvent, LoadedImage} from "ngx-image-cropper";
import {FileUploader} from 'ng2-file-upload';


declare var ImageCompressor: any;

const compressor = new ImageCompressor();

@Component({
  selector: 'app-images-box',
  templateUrl: './images-box.component.html',
  styleUrls: ['./images-box.component.scss']
})
export class ImagesBoxComponent implements OnInit, AfterViewInit  {
  uploader!: FileUploader;
  @Input() imageSelected:any;
  @Output() imageSelectedChange = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();

  @Input()
  keyIndex!:number;

  @Input() selectClass: string = 'main-class';
  @ViewChild('imageBoxInput') imageButton:any;

  @Input()
  title!: string;
  constructor() {
  }
  ngAfterViewInit(){


  }
  ngOnInit(): void {
    this.uploader = new FileUploader({url: 'api/files', autoUpload: true});

  }
  imageChangedEvent: any = '';
  croppedImage: any = undefined;
  cropperOpen: boolean = false;

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.cropperOpen = true;

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {

  }
  cropperReady() {
    // cropper ready

  }
  loadImageFailed() {
    // show message
  }
  openImageLoader(){
    this.imageButton.nativeElement.click();
  }

  convertBlobToBase64 = (blob:any) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });


  convertDataUrlToBlob(dataURI:any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], {type: mimeString});
    return blob;

  }

  cropImage() {
    compressor.compress(this.convertDataUrlToBlob(this.croppedImage), {quality: 0.9,maxWidth:900,maxHeight:700, convertSize:700 }).then((res: any)=>{
      this.convertBlobToBase64(res).then(res=>{
        this.croppedImage = res;
        this.onChange.emit(this.croppedImage);
        this.cropperOpen = false;
      });
    });
  }


  closeWindow() {
    this.cropperOpen = false;
    this.croppedImage = undefined;
  }
}
