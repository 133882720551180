<div class="personalErea">
<!--  <div class="row" id="nav">-->
<!--    <div class="col-sm-1 col-md-2  no-gutters list-group personalButton" style="padding-top: 5px">-->
<!--      <button type="button" class="btn btn-info " style="text-align: center;">-->
<!--        <a  [routerLink]="['/personal']"><span>{{this.translate.instant('sale4you.label.PersonalArea.UpdatingAndEditingAds')}}</span></a>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="col-sm-1 col-md-4 no-gutters list-group personalButton" style="padding-top: 5px">-->
<!--      <button type="button" class="btn btn-info " style="text-align: center;">-->
<!--        <a [routerLink]="['/publish/car']"><span>{{this.translate.instant('sale4you.label.PersonalArea.PostingAnAd')}} </span></a>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="col-sm-0 no-gutters list-group personalButton" style="padding-top: 5px">-->
<!--      <button type="button" class="btn btn-info" style="text-align: center;">-->
<!--        <a [routerLink]="['/personal/user-info']"><span>{{this.translate.instant('sale4you.label.PersonalArea.UpdateDetails')}}</span></a>-->
<!--      </button>-->
<!--    </div>-->
<!--    <div class="col-sm-6  mx-auto no-gutters list-group personalButton" style="padding-top: 5px;max-width: 100%;">-->
<!--      <input id="searchField" type="text" style="border-style: dotted;border-color: #008CBA">-->
<!--    </div>-->
<!--    <div class="col-sm-0 mx-auto no-gutters list-group personalButton" style="padding-top: 5px">-->
<!--      <button type="button" class="btn btn-info" style="text-align: center;">-->
<!--        <a><span>{{this.translate.instant('sale4you.label.PersonalArea.Search')}}</span></a>-->
<!--      </button>-->
<!--    </div>-->

<!--  </div>-->
  <router-outlet></router-outlet>
</div>
