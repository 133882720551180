import {Component, Injectable, OnInit} from '@angular/core';
import {ThemePalette} from "@angular/material/core";
import {ProgressSpinnerMode} from "@angular/material/progress-spinner";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

declare function loadingFrame(): any;
@Component({
  selector: 'app-loading-frame',
  templateUrl: './loading-frame.component.html',
  styleUrls: ['./loading-frame.component.scss']
})
export class LoadingFrameComponent implements OnInit {

  constructor(private loadingMaskService: LoadingMaskServiceService,) {
    this.loadingInProcess = true;
    this.initVariables();
  }

  color: ThemePalette = 'warn';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  showMask!: boolean;
  customMaskText!: string;
  private unsubscribe$!: Subject<void>;
  defaultMaskText!: string;
  loadingInProcess: boolean;

  ngOnInit(): void {
    loadingFrame()
  }

  private initVariables(): void {
    this.unsubscribe$ = new Subject<void>();

    this.loadingMaskService.getOnLoadingMask().pipe(takeUntil(this.unsubscribe$)).subscribe(data => {
      this.showMask = data.showMask;
      if(!this.showMask) {
        this.setDefaultMaskText();
      }
    });

    this.loadingMaskService.getOnCustomMaskText().pipe(takeUntil(this.unsubscribe$)).subscribe(customText => {
      this.setCustomizedMaskText(customText);
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setCustomizedMaskText(customText: string) {
    this.customMaskText = customText;
  }

  setDefaultMaskText() {
    this.customMaskText = this.defaultMaskText;
  }

}

interface LoadingData {
  showMask: boolean;
  showLoading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LoadingMaskServiceService {


  constructor() { }
  private loadingList:boolean[] = [];
  private onLoadingMask$ = new Subject<any>();
  private onCustomMaskText$ = new Subject<string>();

  setOnLoadingMask(showLoading: boolean, showMask: boolean) {
    if(showLoading == true && showMask ==true){
      this.loadingList.push(true);
    }
    if(showLoading == false && showMask == false){
      this.loadingList.pop();
    }
    const data: LoadingData = {
      showMask: showMask,
      showLoading: showLoading,
    }
    if(this.loadingList.length == 0){
      setTimeout(()=>this.onLoadingMask$.next({
        showMask: false,
        showLoading: false,
      }),200)

    }else{
      this.onLoadingMask$.next({
        showMask: true,
        showLoading: true,
      })
    }
    // if(showLoading == false && showMask == false){
    //   this.loadingList.pop();
    //   if(this.loadingList.length == 0){
    //     alert("hello world")
    //     this.onLoadingMask$.next({
    //       showMask: false,
    //       showLoading: false,
    //     });
    //   } else{
    //     this.onLoadingMask$.next({
    //       showMask: true,
    //       showLoading: true,
    //     });
    //   }
    // }

    // this.onLoadingMask$.next(data);
  }

  setOnCustomMaskText(customText: string) {
    this.onCustomMaskText$.next(customText);
  }

  getOnCustomMaskText(): Observable<string> {
    return this.onCustomMaskText$.asObservable();
  }

  getOnLoadingMask(): Observable<LoadingData> {
    return this.onLoadingMask$.asObservable();
  }
}
