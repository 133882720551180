import {Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../components/loading-frame/loading-frame.component";
import {Observable, Subject, throwError} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from "rxjs/operators";
import {SystemApp} from "../../../../constans/SystemConfigurations";
import {Product} from "../../../../models/product";
import {AppService} from "../../../../app.service";
import {FilterbaseService} from "../../top-filter/filter-base/services/filterbase.service";
import {products} from "../../../../admin/dashboard/dashboard.data";

@Component({
  selector: 'app-products-container',
  templateUrl: './products-container.component.html',
  styleUrls: ['./products-container.component.css']
})
export class ProductsContainerComponent implements OnInit {
  @Input() productsList:Product[]=[]
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  private fetchTrigger$ = new Subject<void>();

  products: any[] = [];
  isLoading = false;
  totalPages: number;

  base_url: String;
  private mainCategory: string;
  private subCategory: string;

  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public appService:AppService,  private renderer: Renderer2, public filterbaseService:FilterbaseService) {
    this.base_url = SystemApp.base_url_api;
    this.mainCategory = this.apiKey.getNameSpacePos("/",3);
    this.subCategory = this.apiKey.getNameSpacePos("/",5);
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.fetchTrigger$
        .pipe(
            debounceTime(200), // Adjust the debounce time as needed
            switchMap(() => this.filterbaseService.loadFilterData(this.filterbaseService.limit, this.filterbaseService.offset))
        )
        .subscribe((data: any) => {
          this.loadingMaskServiceService.setOnLoadingMask(false, false);
          this.productsList = data.object_list;
          this.totalPages = data.page_size;
          this.isLoading = false;
        },error => {
          this.loadingMaskServiceService.setOnLoadingMask(false, false);
        });
  }


  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (!this.isLoading && window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.fetchTrigger$.next();
    }
  }


  ngOnInit(): void {
    this.fetchTrigger$.next();
  }

  previousPage(){
    if(this.filterbaseService.offset > 1){
      this.filterbaseService.offset --
      this.pageChange(this.filterbaseService.offset);
    }
  }
  nextPage(){
    if(this.filterbaseService.offset < this.totalPages){
      this.filterbaseService.offset ++
      this.pageChange(this.filterbaseService.offset);
    }
  }
  pageChange(pageNum){
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.filterbaseService.offset = pageNum;
    this.fetchTrigger$.next(null);
  }
  range(total: number): number[] {
    return Array.from({ length: total }, (_, index) => index + 1);
  }

  get pageNavigatorDetails(){
    const currentPage = this.filterbaseService.offset;
    const pageSize = this.totalPages;
    // const numberPage =
    return {currentPage: currentPage, pageSize: pageSize}
  }
  private handleObserverError(error: any): Observable<any>{
    this.loadingMaskServiceService.setOnLoadingMask(false, false);
    return throwError(error.error || error)
  }

  getProducts(mainCategory:string, subCategory:string|null = null): Observable<any>{
    return this.http.get(this.apiKey.getProducts(mainCategory, subCategory), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  public addToWishList($event:any,product: Product){
    $event.preventDefault();
    this.appService.addToWishList(product);
  }

  loading: boolean = false;

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;



  ngAfterViewInit() {
    this.addScrollListener();
  }

  addScrollListener() {
    this.renderer.listen(this.scrollContainer.nativeElement, 'scroll', (event) => {
      console.log(this.isScrolledToBottom())
      if (this.isScrolledToBottom()) {
        this.loadMoreProducts();
      }
    });
  }

  isScrolledToBottom() {
    const container = this.scrollContainer.nativeElement;
    console.log(container.scrollHeight, container.scrollTop)
    return container.scrollHeight - container.scrollTop === container.clientHeight;
  }

  loadMoreProducts(): void {
    if (!this.loading) {
      this.filterbaseService.offset++;
      this.loading = true;
      this.filterbaseService.loadFilterData(this.filterbaseService.limit, this.filterbaseService.offset).subscribe(data => {
        this.loadingMaskServiceService.setOnLoadingMask(false, false);
        this.products = data.object_list;
        this.loading = false
      })
    }
  }

}
