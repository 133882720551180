
<div style="height: 70vh;  box-sizing: border-box;">
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <div style="padding-top: 5px">
        <button type="button" class="btn btn-info" class="personalInfo" >
          <a [routerLink]="['/personal/user-info']"><span>{{this.translate.instant('sale4you.label.PersonalArea.UpdateDetails')}}</span></a>
          <i class="fa fa-user"></i>
        </button>
      </div>
    </div>
    <div class="col-lg-6 col-md-12">
      <ul class="adsHeader">
        <li [class]="getClass(ProductType.CAR)"
            (click)="loadAds(ProductType.CAR)">{{'sale4you.label.cars' | translate}}</li>
        <li [class]="getClass(ProductType.NADLAN)"
            (click)="loadAds(ProductType.NADLAN)">{{'sale4you.label.nadlans' | translate}}</li>
        <li [class]="getClass(ProductType.SALE2)"
            (click)="loadAds(ProductType.SALE2)">{{'sale4you.label.sale2' | translate}}</li>
      </ul>
    </div>
  </div>

          <ag-grid-angular
            #adgrid
            style="width: 100%; height: 100%;"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            [gridOptions]="gridOptions"
            [defaultColDef]="defaultColDef"
            (rowClicked)="openModel($event)"
            [defaultColGroupDef]="defaultColGroupDef"
            [columnTypes]="columnTypes"
            [rowData]="rowData"
            (gridReady)="onGridReady($event)"
          ></ag-grid-angular>
        <!--  <ng-template #adsModel >-->
<!--    <div class="modal-dialog " style="width:100vw;" *ngIf="selectedAdsItem" role="document">-->
<!--        <div class="content-box">-->
<!--          <div class="row">-->
<!--            <div class="col" style="background: red;">Part I</div>-->
<!--            <div class="col" style="background: yellow;">Part II</div>-->
<!--          </div>-->
<!--        </div>-->
<!--    </div>-->
<!--  </ng-template>-->
</div>
