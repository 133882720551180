import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { Settings, AppSettings } from '../../../app.settings';
import {AdminService} from "../../../admin/admin.service";
import {User, UserProfile} from "../../../admin/users/user.model";
import {UsersService} from "../../../admin/users/users.service";

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit {
  public currencies = ['NIS'];
  public currency:any; 
  public userDetails: UserProfile;
  public settings: Settings;
  constructor(public appSettings:AppSettings, public appService:AppService, public translateService: TranslateService, private adminService: AdminService, private userService: UsersService) {
    this.settings = this.appSettings.settings; 
  } 

  ngOnInit() {
    this.currency = this.currencies[0];
    this.getUserDetails()
  }

  public changeCurrency(currency){
    this.currency = currency;
  } 

  public changeLang(lang:string){ 
    this.translateService.use(lang);   
  } 

  public getUserDetails(){
    this.userDetails = this.userService.userObjDetails
  }

  public getLangText(lang){
    if(lang == 'de'){
      return 'German';
    }
    else if(lang == 'fr'){
      return 'French';
    }
    else if(lang == 'ru'){
      return 'Russian';
    }
    else if(lang == 'he'){
      return 'עברית';
    }
    else if(lang == 'tr'){
      return 'Turkish';
    }
    else{
      return 'English';
    }
  }

    signOut() {
      this.adminService.signOut();
    }
}
