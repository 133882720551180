<section class="section-padding bg-white border-top">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-car-alt" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.freeDelivery" | translate}}</h6>
          <p>{{"sale4you.label.freeDelivery.value" | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-handshake" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.Guarantee" | translate}}</h6>
          <p>{{"sale4you.label.Guarantee.value" | translate}}</p>
        </div>
      </div>
      <div class="col-lg-4 col-sm-6">
        <div class="feature-box">
          <i class="mdi fas fa-pen" style='color:white'></i>
          <h6 class="text-info">{{"sale4you.label.Discount" | translate}}</h6>
          <p>{{"sale4you.label.GreatDailyDealsDiscount" | translate}}</p>
        </div>
      </div>
    </div>
  </div>
</section>
