<div class="modal-body" style="background: #fbfbfb;">
  <form>
    <div class="form-group">
      <section class="checkout-page section-padding">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="checkout-step">
                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h5 class="mb-0">
                        <button class="btn btn-link " type="button" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                          <span class="number">1</span> <b>סטטוס הזמנה</b>
                        </button>
                      </h5>
                    </div>
                    <div id="collapsefour" class="collapse show" aria-labelledby="headingThree" data-parent="#accordionExample">
                      <div class="card-body">
                        <div class="text-center">
                          <div class="col-lg-10 col-md-10 mx-auto order-done">
                            <i class="mdi mdi-check-circle-outline text-secondary"></i>
                            <h4 class="text-success">הזמנה בוצעה בהצלחה</h4>
                          </div>
                          <div class="text-center">
                            <a routerLink="/"><button type="submit" (click)="closeDialog()" class="btn btn-secondary mb-2 btn-lg">חזור לדף הבית</button></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </form>
</div>
