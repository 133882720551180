<mat-toolbar id="menu-toolbar" class="top-navbar mat-elevation-z2" fxLayoutAlign="center center">
    <a mat-button routerLink="/" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.HomePage' | translate }}</a>
<!--   <a mat-button [matMenuTriggerFor]="menu" (click)="openMegaMenu()">קטגוריות<mat-icon class="caret">arrow_drop_down</mat-icon></a>-->
<!--    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="mega-menu app-dropdown">-->
<!--        <div fxLayout="row wrap">-->
<!--            <div fxFlex="33" fxLayout="column" class="p-3" *ngFor="let cate of categories | myfilter:{cate: null}">-->
<!--                <a style="padding-left: 2em; color:var(--text-color) !important; background: black;" mat-menu-item routerLink="/products/{{cate.name | clearWhiteSpace}}"><b>{{cate.name }}</b></a>-->
<!--                <mat-divider></mat-divider>-->
<!--                <div *ngFor="let secCate of categories | myfilter:{cate: cate}">-->
<!--                <a  mat-menu-item routerLink="/products/{{secCate.name | clearWhiteSpace}}">{{secCate.name }}</a>-->

<!--                    <p >-->
<!--                        <a style="padding-left: 2em; color:#bb992a !important;" class="categoryLink" *ngFor="let thirdCate of categories | myfilter:{cate: secCate}" routerLink="/products/{{thirdCate.name | clearWhiteSpace}}">{{thirdCate.name}}</a>-->
<!--                    </p>-->
<!--                    <mat-divider></mat-divider>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div fxFlex="20" class="p-1">-->
<!--                <a mat-menu-item routerLink="/"><b>MEN</b></a>-->
<!--                <mat-divider></mat-divider>-->
<!--                <button mat-menu-item>T-shirts & Polos</button>-->
<!--                <button mat-menu-item>Shoes & Boots</button>-->
<!--                <button mat-menu-item>Jeans</button>-->
<!--                <button mat-menu-item>Coats</button>-->
<!--            </div>-->
<!--            <div fxFlex="20" class="p-1">-->
<!--                <a mat-menu-item routerLink="/"><b>KIDS</b></a>-->
<!--                <mat-divider></mat-divider>-->
<!--                <button mat-menu-item>Top</button>-->
<!--                <button mat-menu-item>Pants & Shorts</button>-->
<!--                <button mat-menu-item>Dresses</button>-->
<!--                <button mat-menu-item>Skirts</button>-->
<!--                <button mat-menu-item>Set & Body</button>-->
<!--            </div>-->
<!--            <div fxFlex="20" class="p-1">-->
<!--                <a mat-menu-item routerLink="/"><b>ACCESSORIES</b></a>-->
<!--                <mat-divider></mat-divider>-->
<!--                <button mat-menu-item>Watches</button>-->
<!--                <button mat-menu-item>Bags & Wallet</button>-->
<!--                <button mat-menu-item>Sunglasses</button>-->
<!--                <button mat-menu-item>Belts & Hats</button>-->
<!--            </div>-->
<!--            <div fxFlex="20" class="p-1">-->
<!--                <mat-card class="mega-menu-widget p-0">-->
<!--                    <mat-chip-list>-->
<!--                        <mat-chip color="warn" selected="true">50% OFF</mat-chip>-->
<!--                    </mat-chip-list>-->
<!--                    <button mat-raised-button color="primary">Shop now</button>-->
<!--                    <img src="assets/images/others/mega_menu_img.png" alt="" class="w-100">-->
<!--                </mat-card>-->
<!--            </div>-->
<!--        </div>-->

<!--    </mat-menu>-->

    <a mat-menu-item routerLink="/categories" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'קטגוריות' | translate }}</a>
    <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.AllProducts' | translate }}</a>
<!--    <a mat-menu-item [routerLink]="['/products', '2', 'PC All-in-One']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.ProductDetail' | translate }}</a>-->
    <a mat-menu-item routerLink="/cart" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.MyCart' | translate }}</a>
<!--    <a mat-menu-item routerLink="/landing">{{ 'sale4you.label.HomePage.Landing' | translate }}</a>-->
<!--    <a mat-menu-item routerLink="/sign-in">{{ 'sale4you.label.HomePage.LogIn' | translate }}</a>-->
    <!--<a mat-menu-item [matMenuTriggerFor]="shop">{{ 'sale4you.label.HomePage.Shop' | translate }}</a>-->
   <!-- <a mat-menu-item [matMenuTriggerFor]="others">{{ 'sale4you.label.HomePage.Others' | translate }}</a>-->

    <!--
    <a mat-button [routerLink]="['/products', 'jewellery']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Jewellery</a>
    <a mat-button [routerLink]="['/products', 'electronics']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Electronics</a>
    <a mat-button [routerLink]="['/products', 'sports']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">Sports</a> 
     -->
    <!--
    <a mat-button [matMenuTriggerFor]="pages">{{ 'sale4you.label.HomePage.Pages' | translate }}<mat-icon class="caret">arrow_drop_down</mat-icon></a>
    <mat-menu #pages="matMenu" [overlapTrigger]="false" class="app-dropdown">
        <a mat-menu-item routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.Contact' | translate }}</a>
        <a mat-menu-item [matMenuTriggerFor]="shop">{{ 'sale4you.label.HomePage.Shop' | translate }}</a>
        <a mat-menu-item routerLink="/sign-in">{{ 'sale4you.label.HomePage.LogIn' | translate }}</a>
        <a mat-menu-item routerLink="/404">{{ 'sale4you.label.HomePage.404Page' | translate }}</a>
        <a mat-menu-item routerLink="/landing">{{ 'sale4you.label.HomePage.Landing' | translate }}</a>
        <a mat-menu-item [matMenuTriggerFor]="others">{{ 'sale4you.label.HomePage.Others' | translate }}</a>
    </mat-menu>
    -->
    <!--
   <mat-menu #shop="matMenu" class="app-dropdown">
       <a mat-menu-item routerLink="/products" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.AllProducts' | translate }}</a>
       <a mat-menu-item [routerLink]="['/products', '2', 'PC All-in-One']" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.ProductDetail' | translate }}</a>
       <a mat-menu-item routerLink="/cart" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.MyCart' | translate }}</a>
       <a mat-menu-item routerLink="/checkout" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.CheckOut' | translate }}</a>
   </mat-menu>

   <mat-menu #others="matMenu" class="app-dropdown">
       <a mat-menu-item href="http://themeseason.com" target="_blank">{{ 'sale4you.label.HomePage.ExternalLink' | translate}}</a>
       <a mat-menu-item>{{ 'sale4you.label.HomePage.MenuItem' | translate}}</a>
       <a mat-menu-item>{{ 'sale4you.label.HomePage.MenuItem' | translate}}</a>
       <a mat-menu-item>{{ 'sale4you.label.HomePage.MenuItem' | translate}}</a>
   </mat-menu>
    -->
<!--    <a mat-menu-item routerLink="/checkout" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.CheckOut' | translate }}</a>-->
   <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.Contact' | translate }}</a>
<!--   <a mat-button routerLink="/admin" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">{{ 'sale4you.label.HomePage.Admin' | translate }}</a>-->

</mat-toolbar>

