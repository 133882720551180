import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-person-contact-ads',
  templateUrl: './person-contact-ads.component.html',
  styleUrls: ['./person-contact-ads.component.scss']
})
export class PersonContactAdsComponent implements OnInit {

  @Input() question: QuestionBase<string>;
  @Input() value: any;

  constructor() { }

  ngOnInit(): void {
    console.log(this.value)
    console.log(this.question)
  }

}
