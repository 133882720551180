import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';


@Pipe({
  name: 'filterByString',
  pure: true
})
export class FilterByString implements PipeTransform {
  transform(strList: any[], filter: any): any {
    if (!strList || !filter) {
      return strList;
    }
    return strList.filter(cate => cate.toString().includes(filter));
  }
}

@Component({
  selector: 'app-search-admin-field',
  templateUrl: './search-admin-field.component.html',
  styleUrls: ['./search-admin-field.component.scss']
})
export class SearchAdminFieldComponent implements OnInit, AfterViewInit {

  @Input()  options: string[]=[]
  @Input()  dataStructure:any;

  @Output() onSearch = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onClick = new EventEmitter();

  @Input()  myModel!: any;
  @Output() myModelChange = new EventEmitter<number | string>();


  myControl = new FormControl('');
  filteredOptions: Observable<string[]>;

  constructor() { }

  @Input()
  set setOptions(options:any[]) {
    this.options = options.map(itm=>itm.label);
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );
  }

  get selectedLable(){
    return this.options.find(opt=>opt[this.dataStructure.value] == this.myModel[this.dataStructure.value])?.[this.dataStructure.name];
  }
  get selectedValue(){
    return this.options.find(opt=>opt[this.dataStructure.value] == this.myModel[this.dataStructure.value])?.[this.dataStructure.value];
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );
  }

  ngAfterViewInit() {
    // setTimeout(()=>{
      this.myControl.setValue(this.selectedLable)
    // },0)
  }


  private _filter(value: string): string[] {
    const filterValue = value.toString().toLowerCase();
    if(filterValue == null || filterValue.trim() ==''){
      return this.options;
    }
    return this.options.filter(option => option != null && option.toString().toLowerCase().includes(filterValue));
  }


  onClickFunc() {
    this.onClick.emit();
  }



  onValueChange() {

    setTimeout(()=>{
      if(!this.options.find(list=>list == this.myControl.value)){
        this.myControl.setValue('');
      }
      const selectedCategory = this.options.find(opt=>opt == this.myControl.value);
      this.onChange.emit(selectedCategory);
    },100)



  }

  onMySearch($event: KeyboardEvent) {
      this.onSearch.emit(this.myControl.value);
  }
}
