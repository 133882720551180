<section class="product-items-slider section-padding">
  <div class="container">
    <div class="section-header">
      <h5 class="heading-design-h5">Best Offers View <span class="badge badge-info">20% OFF</span>
        <a class="float-right text-secondary" href="shop.html">View All</a>
      </h5>
    </div>
    <div class="owl-carousel owl-carousel-featured">
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/7.jpg" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/8.jpg" alt="">
              <span class="non-veg text-danger mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/9.jpg" alt="">
              <span class="non-veg text-danger mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/10.jpg" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/11.jpg" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
      <div class="item">
        <div class="product">
          <a href="single.html">
            <div class="product-header">
              <span class="badge badge-success">50% OFF</span>
              <img class="img-fluid" src="assets/img/item/12.jpg" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
