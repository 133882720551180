import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit, AfterViewInit {


  @Input()  options: string[]=[]
  @Input()  dataStructure:any;

  @Input()  myModel!: any;
  @Output() myModelChange = new EventEmitter<number | string>();


  myControl = new FormControl('');
  filteredOptions!: Observable<string[]>;

  get selectedLable(){
    return this.options.find(opt=>opt[this.dataStructure.value] == this.myModel[this.dataStructure.value])?.[this.dataStructure.name];
  }
  get selectedValue(){
    return this.options.find(opt=>opt[this.dataStructure.value] == this.myModel[this.dataStructure.value])?.[this.dataStructure.value];
  }

  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value || '')),
    );
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      this.myControl.setValue(this.selectedLable)
    },100)
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if(filterValue == null || filterValue.trim() ==''){
      return this.options;
    }
    return this.options.filter(option => option[this.dataStructure.name].toLowerCase().includes(filterValue));
  }


  onChange() {
    setTimeout(()=>{
      if(!this.options.find(list=>list[this.dataStructure.name] == this.myControl.value)){
        this.myControl.setValue('');
      }
      const selectedCategory = this.options.find(opt=>opt[this.dataStructure['name']] ==this.myControl.value);
      this.myModelChange.emit(selectedCategory);
    },250)
  }
  constructor() { }

}
