<!-- scroll-pagination.component.html -->

<div class="dropDownContainer">
    <input type="search" (click)="requestData()"  class="searchDropDown form-control" (focusout)="onFocusOut($event)" [value]="selectedObject"  (focusin)="onFocusIn($event)" (search)="handleClearClick($event)">
    <div class="resultBox">
        <ui *ngIf="showList" class="result-list">
            <li *ngFor="let res of results">
                <div class="row" (click)="selectItem($event, res)">
                    <div class="col itemRow" *ngFor="let label of labels">{{getValueFromRes(res, label)}}</div>
                </div>
            </li>
        </ui>
    </div>
</div>
