import {Component, Input, OnInit} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../components/loading-frame/loading-frame.component";
import {AdvService} from "./service/advertisement.service";
import {QuestionBase} from "../../pages/create-adv/models/question-base";
export interface Advertisement{
  User:number,
  active: boolean,
  description: string,
  expireDate: string,
  heightSize: number,
  highlight: boolean,
  id: number,
  image: string,
  image2: string | null;
  mainCategory: string,
  resourcetype: string,
  startDate: string,
  video: string | null,
  withSize: string
}
@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.css']
})
export class AdvertisementComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl, private advService:AdvService) { }

  advertisementObj!:Advertisement[];

  @Input()
  advertisementType!: string;

  ngOnInit(): void {
    if(this.advertisementType == 'BannerAdv'){
      this.advService.getAdvertisement(2).subscribe((advertisement:Advertisement[]) =>{
        this.advertisementObj = advertisement;
      })
    }

  }

}
