<div *ngFor="let category of mainCategories">
    <button style="direction: rtl; text-align: right;" mat-menu-item >
        <span (click)="changeCategory($event)">{{category.name}}</span>
        <span *ngIf="category.hasSubCategory">
            <mat-icon *ngIf="opened[category.id]" (click)="closeMenu(category.id)" >keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="!opened[category.id]" (click)="openMenu(category.id)" >keyboard_arrow_left</mat-icon>
        </span>
    </button>
     <div *ngIf="category.hasSubCategory && opened[category.id]" class="sub-category">
        <app-category-list [categories]="categories" [categoryParentId]="category.id" (change)="changeCategory($event)"></app-category-list>
    </div>
</div>
