import {Component, OnChanges, OnInit} from '@angular/core';
import {Topcategory} from "../../models/top-category";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {SystemApp} from "../../constans/SystemConfigurations";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {LoadingMaskServiceService} from "../loading-frame/loading-frame.component";

declare function CategoryTop(): any;
@Component({
  selector: 'app-top-category',
  templateUrl: './top-category.component.html',
  styleUrls: ['./top-category.component.css']
})
export class TopCategoryComponent implements OnInit {
  categorytList: Topcategory[];
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  base_url: any;
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService)  {
    this.base_url = SystemApp.base_url_api;
    // @ts-ignore
    this.categorytList = [];
    this.loadData()
  }



  loadData(): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getToCategory().subscribe((res) => {
      this.categorytList = res.map((res:any) =>{return {
        companyName: res.companyName,
        productQuantity: res.productQuantity,
        companyImage: res.companyImage,
      }})
      CategoryTop()
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })

  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }
  getToCategory(): Observable<any>{
    return this.http.get(this.apiKey.getTopcategory(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  ngOnInit(): void {

  }

}
