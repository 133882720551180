import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-big-select-box',
  templateUrl: './big-select-box.component.html',
  styleUrls: ['./big-select-box.component.scss']
})
export class BigSelectBoxComponent implements OnInit {
  @Input() question: QuestionBase<string>;
  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
