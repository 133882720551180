import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {QuestionBase} from "../../../pages/create-adv/models/question-base";
import {FormGroup} from "@angular/forms";
import {SystemApp} from "../../../constans/SystemConfigurations";

@Injectable()
export class DynamicFormDialogService {
    form!: FormGroup;
    url: string;
    private subject = new Subject<any>();
    private deleteSubject = new Subject<any>();
    private options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.apiKey.getJWTToken()
        })
    }
    _questionList:QuestionBase<any>[];

    set questionList(questions: QuestionBase<any>[]){
        this._questionList = questions
    }

    get questionList(): QuestionBase<any>[]{
        return this._questionList;
    }
    QuestionMap = new Map();
    constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService) {
    }

    addQuestion(index: string,answer: any){
        this.QuestionMap.set(index,answer)
        this.sendClickEvent(index)
    }

    deleteQuestion(index: string){
        this.QuestionMap.delete(index);
        this.sendClickEvent(index)
        this.questionList.find(qs=>qs.key == index).relatedField?.split(',').forEach(qsIndex=>{
            this.deleteQuestion(qsIndex)
            this.sendDeleteEvent(qsIndex)
        })
    }

    getQuestion(index: string) {
        return this.QuestionMap.get(index);
    }

    isObject(variable: any): boolean {
        return typeof variable === 'object' && variable !== null;
    }

    updateData(rowId: number, url: string= ''):Observable<any>{
        var arrJson:any = {}
        this.QuestionMap.forEach((value,key) => {
            if(key == 'image'){
                if(this.isObject(value[0]) && value[0][0]!= null){
                    arrJson[key]=value[0][0]['link']
                }else{
                    arrJson[key]=value
                }
            }else if(this.isObject(value)){
                arrJson[key]=value.id
            }else{
                arrJson[key]=value
            }
        })
        const urlPath = url != null ? SystemApp.base_url_api + "/" + url: this.url;
        return this.http.patch(urlPath.replace(/^\/+|\/+$/g, '') + "/" + rowId + "/", JSON.stringify(arrJson), this.options)
    }

    publishData(): Observable<any>{
        var arrJson:any = {}
        this.QuestionMap.forEach((value,key) => {
            if(this.isObject(value)){
                arrJson[key]=value.id
            }else{
                arrJson[key]=value
            }
        })

        this.loadingMaskServiceService.setOnLoadingMask(true, true);
        return this.http.post<any>(this.url, JSON.stringify(arrJson), this.options)
    }

    sendDeleteEvent(obj: any = null){
        this.deleteSubject.next(obj);
    }

    getDeleteEvent():Observable<any>{
        return this.deleteSubject.asObservable();
    }

    sendClickEvent(obj: any = null){
        this.subject.next(obj);
    }

    getClickEvent():Observable<any>{
        return this.subject.asObservable();
    }

    clearRelatedFields(questionBase: QuestionBase<any>,source: string|null =null ): void{;
        const arrList = questionBase.relatedField.split(",");
        if(arrList.length == 0 ) return;
        arrList.forEach((field:any)=>{
            if(this.getQuestion(field) != null || this.getQuestion(field) != undefined){
                this.deleteQuestion(field);
            }
        })
        if(source == "clearSearch"){
            this.deleteQuestion(questionBase.key)
        }

    }

}