import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {StatusAds} from "../../../../models/user-ads";

@Component({
  selector: 'app-status-cell-renderer',
  templateUrl: './status-cell-renderer.component.html',
  styleUrls: ['./status-cell-renderer.component.scss']
})
export class StatusCellRendererComponent implements ICellRendererAngularComp {

  value: string;
  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.value = params.value
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  getStatusIcon() {
    if(this.value) return 'active-icon';
    return 'inactive-icon';
  }
}
