import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-date-formate-cellrenderers',
  templateUrl: './date-formate-cellrenderers.component.html',
  styleUrls: ['./date-formate-cellrenderers.component.scss']
})
export class DateFormateCellrenderersComponent implements ICellRendererAngularComp {

  value: string;
  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.value = params.value
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

}
