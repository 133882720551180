import {Component, Input, OnInit} from '@angular/core';
import {FilterBase} from "../../../../../models/filter-base";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent implements OnInit {

  @Input() hideTitle: boolean =false;
  @Input() filterBase!: FilterBase;
  constructor(private filterBaseService: FilterbaseService) { }

  ngOnInit(): void {
  }

  set selectDate(value: Date){
    this.filterBaseService.addQuestion(this.filterBase.key, value);
  }

}
