import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, environment.url +'/assets/i18n/', '.json');
}


import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import {MenuComponent, MyFilterPipe} from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import {ApiUrl} from "./shared/models/apiurl.models";
import {UsersService} from "./admin/users/users.service";
import {AdminService} from "./admin/admin.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AgGridModule} from "ag-grid-angular";
import {PriceViewService} from "./shared/price-view/price-view.service";
import {PagesComponentComponent} from "./pages/pages-component.component";
import {LoginComponent} from "./components/session/login/login.component";
import {TopNavbarComponent} from "./components/top-navbar/top-navbar.component";
import {HeaderComponent} from "./components/header/header.component";
import {LoginService} from "./components/session/login/login.service";
import {CartSidebarComponent} from "./components/cart-sidebar/cart-sidebar.component";
import {SectionPaddingComponent} from "./components/section-padding/section-padding.component";
import {LoadingFrameComponent} from "./components/loading-frame/loading-frame.component";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {TopCategoryComponent} from "./components/top-category/top-category.component";
import {CarouselSliderComponent} from "./components/carousel-slider/carousel-slider.component";
import {ProductItemsComponent} from "./components/product-items/product-items.component";
import {HeaderSearchComponent} from "./components/header-search/header-search.component";
import {PersonalAreaComponent} from "./components/personal-area/personal-area.component";
import {InfoComponent} from "./pages/personal/info/info.component";
import {WishlistComponent} from "./pages/personal/wishlist/wishlist.component";
import {AdsComponent} from "./pages/personal/ads/ads.component";
import {AdsPageComponentDialog} from "./pages/personal/ads/ads-page/ads-page-component-dialog.component";
import {PersonalComponent} from "./pages/personal/personal.component";
import {DatePipe} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CarAdvFormComponent} from "./pages/create-adv/car-adv-form/car-adv-form.component";
import {MainCategoryComponent} from "./pages/create-adv/main-category-component/main-category.component";
import {DynamicFormQuestionComponent} from "./pages/create-adv/dynamic-form-question/dynamic-form-question.component";
import {CreateAdvComponent} from "./pages/create-adv/create-adv.component";
import {AdvFormService} from "./pages/create-adv/services/adv-form.service";
import {FilterbaseService} from "./pages/shop/top-filter/filter-base/services/filterbase.service";
import {DynamicFormComponent} from "./pages/create-adv/dynamic-form/dynamic-form.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSliderModule} from "@angular/material/slider";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {MatIconModule} from "@angular/material/icon";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {TextWrapperComponent} from "./shared/text-wrapper/text-wrapper.component";
import {FilterPipe, UiSelectDropdownComponent} from "./components/ui-select-dropdown/ui-select-dropdown.component";
import {MultiSelectBoxComponent} from "./components/multi-select-box/multi-select-box.component";
import {ImageListComponent} from "./pages/create-adv/image-list/image-list.component";
import {PersonContactComponent} from "./components/person-contact/person-contact.component";
import {ImagesBoxComponent} from "./components/images-box/images-box.component";
import {PhoneValidationDialogComponent} from "./components/person-contact/phone-validation-dialog/phone-validation-dialog.component";
import {ImgCompressorDirective} from "./components/images-box/ img-compressor.directive";
import {OfferProductComponent} from "./components/offer-product/offer-product.component";
import {ProductItemsSliderComponent} from "./components/product-items-slider/product-items-slider.component";
import {SelectBigBoxComponent} from "./components/select-big-box/select-big-box.component";
import {SelectRouteComponent} from "./pages/create-adv/select-route/select-route.component";
import {CreditCardComponent} from "./pages/create-adv/select-route/credit-card/credit-card.component";
import {FreeAdvComponent} from "./pages/create-adv/select-route/free-adv/free-adv.component";
import {SelectRemoteComponent} from "./components/forms/select-remote/select-remote.component";
import {DropdownComponent} from "./pages/create-adv/dropdown/dropdown.component";
import {ShopComponent} from "./pages/shop/shop.component";
import {ControlMessages} from "./shared/controlMessages/control-messages";
import {MainContentComponent} from "./pages/shop/main-content/main-content.component";
import {NavHeaderComponent} from "./pages/shop/nav-header/nav-header.component";
import {ProductsContainerComponent} from "./pages/shop/main-content/products-container/products-container.component";
import {AutoTabDirective, CarTypeComponent} from "./pages/create-adv/car-type/car-type.component";
import {CartProductItemComponent} from "./components/cart-sidebar/cart-product-item/cart-product-item.component";
import {CategoryItemComponent} from "./pages/shop/main-content/category-item/category-item.component";
import {ProductDetailsItemComponent} from "./pages/personal/wishlist/product-details-item/product-details-item.component";
import {PhoneMaskDirective} from "./shared/phoneMaskDirective/phone-mask.directive";
import {FieldMessageComponent} from "./shared/controlMessages/field-message/field-message.component";
import {
    FilterBasesFilter,
    SelectFieldComponent
} from "./pages/shop/top-filter/components/select-field/select-field.component";
import {TextBoxComponent} from "./pages/shop/top-filter/components/text-box/text-box.component";
import {CheckboxComponent} from "./pages/shop/top-filter/components/checkbox/checkbox.component";
import {TopFilterComponent} from "./pages/shop/top-filter/top-filter.component";
import {BestOffersComponent} from "./components/best-offers/best-offers.component";
import {FilterBaseComponent} from "./pages/shop/top-filter/filter-base/filter-base.component";
import {RangeTextComponent} from "./pages/shop/top-filter/components/range-text/range-text.component";
import {RangeSelectComponent} from "./pages/shop/top-filter/components/range-select/range-select.component";
import {AdvService} from "./core/advertisement/service/advertisement.service";
import {SharedModule} from "./shared/shared.module";
import {SearchFieldComponent} from "./pages/create-adv/dynamic-form/shared/search-field/search-field.component";
import {ImageCropperModule} from "ngx-image-cropper";
import {ProductsComponent} from "./pages/products/products.component";
import {ProductComponent} from "./pages/product/product.component";
import {GetValueItemProduct, SingleComponent} from "./pages/single/single.component";
import {AdvertisementComponent} from "./core/advertisement/advertisement.component";
import {AdminModule} from "./admin/admin.module";
import {SortByPipe} from "./pages/shop/top-filter/sort-by.pipe";
import {DateFieldComponent} from "./pages/shop/top-filter/components/date-field/date-field.component";
import {
    SelectCheckBoxsComponent
} from "./pages/shop/top-filter/components/select-check-boxs/select-check-boxs.component";
import {QuestionService} from "./pages/create-adv/services/question.service";
import {QuestionControlService} from "./pages/create-adv/services/question-control.service";

import {AdsService} from "./pages/personal/ads/services/ads.service";
import {
    CreditCardPaymentComponent
} from "./pages/create-adv/select-route/credit-card-payment/credit-card-payment.component";
import {TextFieldComponent} from "./components/text-field/text-field.component";



@NgModule({
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridModule,
        NgxSpinnerModule,
        MatSliderModule,
        NgbModule,
        ImageCropperModule,
        MatIconModule,
        MatExpansionModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule,
        AdminModule,
        TextFieldComponent
    ],
    declarations: [
        AppComponent,
        TopMenuComponent,
        MenuComponent,
        MyFilterPipe,
        SidenavMenuComponent,
        PagesComponentComponent,
        BreadcrumbComponent,
        OptionsComponent,
        FooterComponent,
        LoginComponent,
        TopNavbarComponent,
        HeaderComponent,
        CartSidebarComponent,
        SectionPaddingComponent,
        LoadingFrameComponent,
        HomePageComponent,
        TopCategoryComponent,
        CarouselSliderComponent,
        ProductItemsComponent,
        HeaderSearchComponent,
        PersonalAreaComponent,
        InfoComponent,
        WishlistComponent,
        AdsComponent,
        AdsPageComponentDialog,
        PersonalComponent,
        MainCategoryComponent,
        CarAdvFormComponent,
        DynamicFormQuestionComponent,
        CreateAdvComponent,
        DynamicFormComponent,
        TextWrapperComponent,
        UiSelectDropdownComponent,
        MultiSelectBoxComponent,
        ImageListComponent,
        PersonContactComponent,
        ImagesBoxComponent,
        PhoneValidationDialogComponent,
        ImgCompressorDirective,
        AppComponent,
        HeaderComponent,
        FooterComponent,
        TopNavbarComponent,
        LoginComponent,
        TopCategoryComponent,
        CarouselSliderComponent,
        ProductItemsComponent,
        OfferProductComponent,
        ProductItemsSliderComponent,
        SectionPaddingComponent,
        CartSidebarComponent,
        HomePageComponent,
        CreateAdvComponent,
        CarAdvFormComponent,
        DynamicFormComponent,
        DynamicFormQuestionComponent,
        MainCategoryComponent,
        SelectBigBoxComponent,
        MultiSelectBoxComponent,
        PersonContactComponent,
        PhoneValidationDialogComponent,
        ImagesBoxComponent,
        PersonalComponent,
        AdsComponent,
        InfoComponent,
        AdsPageComponentDialog,
        SelectRouteComponent,
        ImageListComponent,
        CreditCardComponent,
        CreditCardPaymentComponent,
        FreeAdvComponent,
        SelectRemoteComponent,
        DropdownComponent,
        LoadingFrameComponent,
        ShopComponent,
        ControlMessages,
        AdsPageComponentDialog,
        MainContentComponent,
        NavHeaderComponent,
        ProductsContainerComponent,
        TopFilterComponent,
        BestOffersComponent,
        FilterBaseComponent,
        SelectFieldComponent,
        SelectCheckBoxsComponent,
        RangeTextComponent,
        RangeSelectComponent,
        CheckboxComponent,
        TextBoxComponent,
        FilterBasesFilter,
        PersonalAreaComponent,
        SearchFieldComponent,
        FieldMessageComponent,
        HeaderSearchComponent,
        UiSelectDropdownComponent,
        ImgCompressorDirective,
        AutoTabDirective,
        PhoneMaskDirective,
        WishlistComponent,
        ProductDetailsItemComponent,
        CategoryItemComponent,
        CartProductItemComponent,
        CarTypeComponent,
        FilterPipe,
        TextWrapperComponent,
        ProductsComponent,
        ProductComponent,
        SingleComponent,
        GetValueItemProduct,
        AdvertisementComponent,
        SortByPipe,
        DateFieldComponent,
    ],
    providers: [
        AppSettings,
        AppService,
        AdminService,
        ApiUrl,
        UsersService,
        AdsService,
        AdvFormService,
        FilterbaseService,
        LoginService,
        DatePipe,
        PriceViewService,
        SharedModule,
        MatSnackBar,
        QuestionService,
        QuestionControlService,
        {provide: OverlayContainer, useClass: CustomOverlayContainer},
        {provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay]},
        {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true}
    ],
    bootstrap: [AppComponent],
    exports: [
        FilterPipe,
        MyFilterPipe,
        SelectRemoteComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
