import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, throwError} from "rxjs";
import {catchError, delay, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../shared/models/apiurl.models";

import {LoginAction, LoginService} from "../session/login/login.service";
import {SystemApp} from "../../constans/SystemConfigurations";
import { debounceTime } from 'rxjs/operators';
import {MainCategory} from "../../models/header.model";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mainCategories!:MainCategory[];
  baseurl: string = SystemApp.base_url;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loginService: LoginService) {
    this.loadData()

  }


  isLogin(){ return !!localStorage.getItem('token') }

  ngOnInit(): void {}

  private loadData() {
    this.mainCategories = []
    this.getPages().subscribe((res) => {
     this.mainCategories = res.map((cate:any) => {
       return{
         id: cate.id,
         name: cate.name,
         nameEng: cate.category.toLowerCase(),
         important: cate.important,
         icon: cate.icon,
         subcategories: cate.subcategories

     }})
    })

  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getPages(): Observable<any>{
    return this.http.get(this.apiKey.getPages(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  navigateTo(s: string) {
    window.location.href = s;
  }

  logout() {
    localStorage.removeItem('token')
    localStorage.removeItem("token");
    localStorage.removeItem("is_superuser");
    localStorage.removeItem("token")
    localStorage.removeItem("is_superuser");
    localStorage.removeItem("first_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("email");
    localStorage.removeItem("avatar");
    localStorage.removeItem("userDetails");
    this.loginService.sendClickEvent(LoginAction.Close);
  }

  openLogin() {
    this.loginService.sendClickEvent(LoginAction.Open);
  }
}
