<!--<div class="loadingFrame">-->
<!--  <div class="spinnerBox">-->
<!--    <mat-progress-spinner-->
<!--      class="example-margin"-->
<!--      [color]="color"-->
<!--      [mode]="mode"-->
<!--      aria-label="Rating"-->
<!--      [value]="value">-->
<!--      <div style="position:relative; top: -60px; left: 30px;">Rating</div>-->
<!--    </mat-progress-spinner>-->
<!--    <span>Loading...</span>-->
<!--  </div>-->
<!--</div>-->
<div *ngIf="showMask" class="loadingFrame" style="z-index: 999;">
  <div class="loader">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</div>
