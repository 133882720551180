import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {HttpActions} from "../../../app.models";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {DynamicFormDialogService} from "../dynamic-form-dialog/dynamic-form-dialog.service";
import {QuestionBase} from "../../../pages/create-adv/models/question-base";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-drop-down-select',
  templateUrl: './drop-down-select.component.html',
  styleUrls: ['./drop-down-select.component.scss']
})
export class DropDownSelectComponent implements OnInit, AfterViewInit  {

  @Input() question: QuestionBase<any>;
  action: HttpActions = HttpActions.GET;
  labels:string[] =[]
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiUrl.getJWTToken()
    })
  }

  page = 1;
  results: { label: string, value: any }[];
  showList: any = false;
  selectValue: any;
  _selectedObject :any;

  set selectedObject(item:any){
    this._selectedObject = item;
  }
  get selectedObject(){
    if(this._selectedObject== null)
      return null;
    return this.labels.map(clm=> this._selectedObject[clm]).join(",")
  };
  onFocusIn($eveny: Event){
    this.showList = true;
  };

  onFocusOut($eveny: Event){
    setTimeout(()=>{
      this.showList = false;
    },100)

  };

  constructor(private http: HttpClient, private apiUrl: ApiUrl, public dynamicFormDialogService:DynamicFormDialogService, public translate:TranslateService) {
    this.dynamicFormDialogService.getClickEvent().subscribe(res=>{})
    this.dynamicFormDialogService.getDeleteEvent().subscribe(res=>{
      if(this.question.key == res){
       this._selectedObject = null
      }
    })
  }

  ngAfterViewInit(): void {}
  ngOnInit(): void {
    this._selectedObject = this.dynamicFormDialogService.getQuestion(this.question.key)
    this.labels = this.question.dropDownLabels;
  }

  requestData() {
    const pathFetch = this.apiUrl.pathTranslate(this.question.callBackFunction, this.dynamicFormDialogService, this.question.relatedField, this.question);
    this.http.request(this.action, pathFetch.path, this.options )
        .subscribe((data: any) => {
          if(data.results != null){
            this.results = data.results;
          }else {
            this.results = data;
          }

        }, (error) => {
          console.error('Error:', error);
        });
  }


  selectItem($event: MouseEvent, selectedValue:any) {
    this._selectedObject = selectedValue;
    this.dynamicFormDialogService.addQuestion(this.question.key, this._selectedObject);
  }

  getValueFromRes(res: any, path: string): any {
    // Split the path string into an array of keys
    const keys = path.split('.');

    // Use Array.reduce to access each nested property
    return keys.reduce((acc, key) => acc ? acc[key] : undefined, res);
  }
  handleClearClick($event) {
    this.selectedObject = null
    this.dynamicFormDialogService.deleteQuestion(this.question.key);
  }
}
