import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 


import {AuthAdminGuard, AuthGuard} from "./shared/auth/auth.guard";
import {PagesComponentComponent} from "./pages/pages-component.component";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {AdsComponent} from "./pages/personal/ads/ads.component";
import {WishlistComponent} from "./pages/personal/wishlist/wishlist.component";
import {InfoComponent} from "./pages/personal/info/info.component";
import {PersonalComponent} from "./pages/personal/personal.component";
import {CreateAdvComponent} from "./pages/create-adv/create-adv.component";
import {MainCategoryComponent} from "./pages/create-adv/main-category-component/main-category.component";
import {CarAdvFormComponent} from "./pages/create-adv/car-adv-form/car-adv-form.component";
import {ProductsComponent} from "./pages/products/products.component";
import {ProductComponent} from "./pages/product/product.component";
import {ShopComponent} from "./pages/shop/shop.component";
import {SingleComponent} from "./pages/single/single.component";



export const routes: Routes = [
    { 
        path: '', 
        component: PagesComponentComponent, children: [
            { path: '', component: HomePageComponent },
            { path: 'products', component: ProductsComponent },
            { path: 'product', component: ProductComponent},
            { path: 'shop/:id', component: ShopComponent},
            { path: 'shop/:id/category/:id', component: ShopComponent},
            { path: 'single/:id', component: SingleComponent},
            { path: 'personal',
                canActivate: [AuthGuard],
                component: PersonalComponent,
                children:[
                    {
                        path: '', // child route path
                        component: AdsComponent, // child route component that the router renders
                    },
                    {
                        path: 'user-info', // child route path
                        component: InfoComponent, // child route component that the router renders
                    }
                    ,
                    {
                        path: 'wishlist', // child route path
                        component: WishlistComponent, // child route component that the router renders
                    }
                ]
            },
            {
                path: 'publish',
                canActivate: [AuthGuard],
                component: CreateAdvComponent, // this is the component with the <router-outlet> in the template
                children: [
                    {
                        path: '', // child route path
                        component: MainCategoryComponent, // child route component that the router renders
                    },
                    {
                        path: 'car', // child route path
                        component: CarAdvFormComponent, // child route component that the router renders
                    },
                    {
                        path: 'nadlan', // child route path
                        component: CarAdvFormComponent, // child route component that the router renders
                    },
                    {
                        path: 'sale2', // child route path
                        component: CarAdvFormComponent, // child route component that the router renders
                    }
                ],
            },
            { path: 'contact', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact' } },
        ]
    },
    { path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule), },
    { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),canActivate: [AuthAdminGuard] },


];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            initialNavigation: 'enabledBlocking'  // for one load page, without reload
            // useHash: true
        })
    ],
    declarations: [
    ],
    exports: [
        RouterModule,
    ]
})
export class AppRoutingModule { }
