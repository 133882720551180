<div mat-dialog-content class="phoneValidationMsg">
  <h1 class="title">{{ "sale4all.label.phone.validation.header" | translate }}</h1>
  <p class="msgParagraph"> {{ "sale4all.label.phone.validation.p" | translate }} : {{data.phoneNumber}} </p>
  <div style="padding-top:5px;padding-bottom:5px;">
    <div class="row keep-top-far" >
      <div class="col">
        <table class="checkBoxTable">
          <tr>
            <td style="padding-left: 5px;"><input  [(ngModel)]="acceptTermsSetter" type="checkbox"/></td>
            <td><span> קראתי ואישרתי את תקנון</span></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row keep-top-far">
      <div class="col">
        <table class="checkBoxTable">
          <tr>
            <td style="padding-left: 5px;"><input  [(ngModel)]="acceptAdvSetter"  type="checkbox"/></td>
            <td><span> אני רוצה לקבל עדכונים רלוונטיים למודעה שלי במייל</span></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <p class="timeLimit"> <mat-icon color="warn">alarm_on</mat-icon> {{ "sale4all.label.phone.validation.limitTime" | translate }} </p>
  <input [@shakeit]="this.states['state1']" id="presonContact" (@shakeit.done)="shakeEnd('state1', $event)" [class]="inputStatusGetter" class="form-control" [(ngModel)]="validationNumberSetter" placeholder="{{ 'sale4all.label.phone.validation.validation.code' | translate }}"  type="text" />
  <p *ngIf="!inputStatus" style="color: darkred">{{ "sale4all.label.phone.validation.error" | translate }}</p>

</div>
<div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <button [disabled]="validationButton(validationNumberGetter)"  mat-raised-button color="primary"(click)="confirmValidation()">{{ "sale4all.label.phone.validation.button.confirm" | translate }} </button>
    </div>
</div>
