import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../pages/create-adv/models/question-base";
import {
  PathOptions,
} from "../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";
import {ApiUrl} from "../../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {Forms, QuestionPriority, SubCategory} from "../../constans/formsQuestions";
import {FormSubCategory} from "../../models/forms.model";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {SystemApp} from "../../constans/SystemConfigurations";

export interface CategoryObject {
  id: string;
  name: string;
  icon: string;
}

@Component({
  selector: 'app-select-big-box',
  templateUrl: './select-big-box.component.html',
  styleUrls: ['./select-big-box.component.css']
})
export class SelectBigBoxComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  @Input()
  questionBase!: QuestionBase<string>;
  @Input()
  questionKey!: any;
  selectedData!: null;
  firstCategory: FormSubCategory [] = [];
  secondCategory: FormSubCategory [] = [];
  thirdCategory: FormSubCategory [] = [];
  @Input()
  myProp: any;
  base_url: String = SystemApp.base_url_api;

  constructor(private apiKey: ApiUrl, private http: HttpClient, private formService: AdvFormService) {
    this.loadData()
  }

  highlightSelectedData(category: FormSubCategory): string{
    const index = this.questionKey;
    if(this.formService.getQuestion(index) ===category)
      return 'selectBigBoxSelected';
    return '';
  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getSubCategory(): Observable<any>{
    const nameSpace = this.apiKey.getNameSpace()
    return this.http.get(this.apiKey.getSubCategory(nameSpace), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  loadData(): void {
    this.getSubCategory().subscribe(res=>{
      res.forEach((cate:any) => {
        if(cate.priority === QuestionPriority.FIRST) {
          this.firstCategory.push(cate);
        }
        else if(cate.priority === QuestionPriority.SECOND){
          this.secondCategory.push(cate);
        }else if(cate.priority === QuestionPriority.THIRD){
          this.thirdCategory.push(cate);
        }
      })
    })
  }

  ngOnInit(): void {
    if(this.questionBase != null){
    }
  }

  SelectMainCategory(category: FormSubCategory) {
    this.myProp = category;
    const index = this.questionKey;
    let secParams = category?.keyArr ? JSON.parse(category?.keyArr) : '';
    this.formService.deleteQuestion(index);
    this.formService.addQuestion(index,category);
    Object.keys(secParams).forEach((key, value)=>{
      this.formService.deleteQuestion(key);
      this.formService.addQuestion(key,secParams[key]);
    })
  }
}
