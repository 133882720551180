<div class="controlsBox">
    <div class="titleControlBox">מה ברצונך לעשות</div>
    <div class="controlsButton row">
        <div class="col-3 actionButtonStyle"><button [disabled]="deleteDisabled" (click)="deleteAds()" [matTooltip]="deleteDisabledToolTip" class="btn btn-info">{{"sale4you.label.ads.delete" | translate}}<mat-icon>delete</mat-icon></button></div>
        <div *ngIf="adsItem.statusAds != StatusAds.Pause" class="col-3 actionButtonStyle"><button [disabled]="resumeDisabled"  [matTooltip]="resumeDisabledToolTip" (click)="pauseAds()" class="btn btn-info">{{"sale4you.label.ads.resume" | translate}}<mat-icon>pause</mat-icon></button></div>
        <div *ngIf="adsItem.statusAds == StatusAds.Pause" class="col-3 actionButtonStyle"><button [disabled]="playDisabled"  [matTooltip]="playDisabledToolTip" (click)="playAds()" class="btn btn-info">{{"sale4you.label.ads.play" | translate}}<mat-icon>play_circle_filled</mat-icon></button></div>
        <div class="col-3 actionButtonStyle"><button [disabled]="editeDisabled"  [matTooltip]="editeDisabledToolTip" (click)="editAds()" class="btn btn-info">{{"sale4you.label.ads.edite" | translate}}<mat-icon>edit</mat-icon></button></div>
        <div class="col-3 actionButtonStyle"><button [disabled]="payDisabled"    [matTooltip]="payDisabledToolTip" (click)="doneAds()" class="btn btn-info">{{"sale4you.label.ads.pay" | translate}}<mat-icon>done</mat-icon></button></div>
        <div *ngIf="isAdminUser && adsItem.statusAds != StatusAds.Blocked" class="col-3 actionButtonStyle"><button [disabled]="blockDisabled"  [matTooltip]="resumeDisabledToolTip" (click)="blockAds()" class="btn btn-info">{{"sale4you.label.ads.block" | translate}}<mat-icon>block</mat-icon></button></div>
        <div *ngIf="isAdminUser &&  adsItem.statusAds == StatusAds.Blocked" class="col-3 actionButtonStyle"><button [disabled]="blockDisabled"  [matTooltip]="playDisabledToolTip" (click)="playAds()" class="btn btn-info">{{"sale4you.label.ads.play" | translate}}<mat-icon>play_circle_filled</mat-icon></button></div>
        <div *ngIf="isAdminUser" class="col-3 actionButtonStyle"><button [disabled]="approveDisabled"  [matTooltip]="playDisabledToolTip" (click)="playAds()" class="btn btn-info">{{"sale4you.label.ads.active" | translate}}<mat-icon>play_circle_filled</mat-icon></button></div>
    </div>
</div>
