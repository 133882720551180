
<div #parentElement class="top-filter">
<!--  <div class="row">-->
<!--    <div class="col-6"><h1 style="text-align: right; white-space: nowrap;">{{'sale4you.label.filter.header' | translate}}</h1></div>-->
<!--    <div class="col-6" style="padding-top:1em; white-space: nowrap;"><span class="searchEmailAlert">{{'sale4you.label.filter.receive-mail-msg' | translate}}</span></div>-->
<!--  </div>-->

  <hr>
  <div class="row">
  <div class="field-box" *ngFor="let filterBase of filterBaseService.filterBases | sortBy:'order' | filterBasesFilter:'advanced':false">
    <app-filter-base [filterBoxWidth]="filterBoxWidth"  [filterBase]="filterBase"></app-filter-base>
  </div>
    <button type="button" (click)="openAdvancedSearch()" class="advancedSearchBtn btn btn-outline-warning">{{'sale4you.label.advancedSearch' | translate}} </button>
    <button type="button" class="searchBtn btn btn-outline-warning" (click)="searchApply()">{{'sale4you.label.search' | translate}}</button>
  </div>

</div>
<div class="advancedSearch" *ngIf="advancedSearch">
  <hr/>
  <div class="row" style="direction: rtl; padding: 0; margin: 0;" >
    <div class="col field-box"  *ngFor="let filterBase of filterBaseService.filterBases | sortBy:'order' | filterBasesFilter:'advanced':true" [ngClass]="getRequiredClass(filterBase)">
      <app-filter-base [filterBoxWidth]="filterBoxWidth"   [filterBase]="filterBase"></app-filter-base>
    </div>
  </div>
  <hr/>
  <div class="row">
    <div class="col" style="text-align: center; padding: 0.5em;">
      <button type="butto" class="advancedSearchBtn btn btn-outline-warning" (click)="searchApply()">{{'sale4you.label.search' | translate}}</button>
    </div>
  </div>
</div>


