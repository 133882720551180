import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByIdName implements PipeTransform {
  transform(items:Array<any>, name?) {
    if(name ==  null || name == '' ){
      return items
    }
    if(items){
      return items.filter(item => {
        return item.name.includes(name) || (item.parentId != null && item.parentId.name.includes(name))
      } );
    }
  }
}


@Pipe({
  name: 'filterAdvType'
})
export class FilterByAdvType implements PipeTransform {
  transform(items:Array<any>, name?) {
    if(name ==  null || name == 'ALL' ){
      return items
    }
    if(items){
      return items.filter(item => {
        return item.resourcetype.toLowerCase() == name.toLowerCase()
      } );
    }
  }
}
