export enum QuestionPriority{
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD'
}
export enum Category {
  CAR = "CAR",
  NADLAN = "NADLAN",
  SALE2 = "SALE2",
  BUSINESS = "BUSINESS",
  WORKER = "WORKER",
  NADLANBUSINESS = "NADLANBUSINESS",
  CELLULAR = "CELLULAR",
  FURNITURE = "FURNITURE",
  TOURISM = "TOURISM",
  FASHION = "FASHION",
  PETS = "PETS",
  BOYBABY = "BOYBABY",
  TEACHERS = "TEACHERS",
  REQUIRED = "REQUIRED"
}
export enum SubCategory {
  LUXURYCAR = "LUXURYCAR",
  VESSEL = "VESSEL",
  PRIVATE = "PRIVATE",
  VANS = "VANS",

}
export const Forms  ={
  subCategory:[
    {
      name: 'מסחרי',
      icon: 'assets/icons/vans.png',
      category: Category.CAR,
      subCategory : SubCategory.VANS,
      priority: QuestionPriority.FIRST,
    },
    {
      name: 'פרטי רכב',
      icon: 'assets/icons/sport-car.png',
      category: Category.CAR,
      subCategory : SubCategory.PRIVATE,
      priority: QuestionPriority.FIRST,
    },
    {
      name: 'כלי שייט',
      icon: 'assets/icons/vessel.png',
      category: Category.CAR,
      subCategory : SubCategory.VESSEL,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'רכב יוקרה',
      icon: 'assets/icons/luxry_car.png',
      category: Category.CAR,
      subCategory : SubCategory.LUXURYCAR,
      priority: QuestionPriority.THIRD,
    }
  ],
  mainCategory:[
    {
      name: 'רכב',
      icon: 'directions_car',
      category: Category.CAR,
      priority: QuestionPriority.FIRST,
    },
    {
      name: 'נדל"ן',
      icon: 'home',
      category: Category.NADLAN,
      priority: QuestionPriority.FIRST,
    },
    {
      name: 'יד שניה',
      icon: 'store',
      category: Category.SALE2,
      priority: QuestionPriority.FIRST,
    },
    {
      name: 'עסקים למכירה',
      icon: 'building',
      category: Category.BUSINESS,
      priority: QuestionPriority.SECOND,
    },
    {
      name: 'בעלי מקצוע',
      icon: 'worker',
      category: Category.WORKER,
      priority: QuestionPriority.SECOND,
    },
    {
      name: 'נדלן מסחרי',
      icon: 'local_mall',
      category: Category.NADLANBUSINESS,
      priority: QuestionPriority.SECOND,
    },
    {
      name: 'סלולר',
      icon: 'computer',
      category: Category.CELLULAR,
      priority: QuestionPriority.SECOND,
    },
    {
      name: 'ריהוט',
      icon: 'None',
      category: Category.CELLULAR,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'תיירות ונופש',
      icon: 'None',
      category: Category.TOURISM,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'אופנה וטיפוח',
      icon: 'None',
      category: Category.FASHION,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'חיות מחמד',
      icon: 'None',
      category: Category.PETS,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'לתינוק ולילד',
      icon: 'None',
      category: Category.BOYBABY,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'מורים פרטיים',
      icon: 'None',
      category: Category.TEACHERS,
      priority: QuestionPriority.THIRD,
    },
    {
      name: 'דרושים',
      icon: 'None',
      category: Category.REQUIRED,
      priority: QuestionPriority.THIRD,
    }
  ]
}
