
<div #dropdown class="sale4u-dropdow" [ngStyle]="getDynamicStyle()">
  <table>
    <tr>
      <td>
        <label style="font-weight: bold; white-space: nowrap;"> {{ !hideTitle ? filterBase.label : '&nbsp;'}}</label>
      </td>
    </tr>
    <tr>
      <td>
        <div class="boxC" (click)="openMenu();onClickFld();">
          <div class="inputBox">
            <div class="clickedSpace">
              <button #clearButton class="clear-button" mat-button *ngIf="selectedValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearField()">
                <mat-icon>close</mat-icon>
              </button>
            </div>
            <input #dropdownSearchField disabled="true" placeholder="{{placeholder}}"  (blur)="onBlur()" [(ngModel)]="selectedValue"  class="dropdown-list-search" type="text">
          </div>

        </div>
      </td>
    </tr>
  </table>

<div  *ngIf="isOpened" class="open-menu">
  <input #searchInputElement (keyup)="onChange($event)" (click)="searchInputClick()" [(ngModel)]="searchInput"  id="searchInput" class="search-input" type="text"/>
  <ul class="dropdown-list" [ngStyle]="getDropdownListStyle()">
    <li *ngIf="loadingData==false && items.length === 0">no result data</li>
    <li *ngIf="loadingData">
      <div class="row" style="padding: 0; margin: 0;">
        <div class="col-5" style="padding: 0; margin: 0; text-align: center;"><mat-progress-spinner [diameter]="20" mode="indeterminate" ></mat-progress-spinner></div>
        <div class="col-7" style="padding: 0; margin: 0; text-align: right;">טעינת נתונים</div>
      </div>
    <li  *ngFor="let item of items | filterByString: searchInput" [className]="getClass(item)" (click)="selectItem(item)"> {{item.label}}</li>
  </ul>
</div>
</div>
