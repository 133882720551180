<div class="navbar-top pt-2 pb-2">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 text-center">
        <a href="shop.html" class="mb-0 text-white top-label">
            <strong>{{'sale4you.label.upBanner' | translate}}<span class="text-light">Sale4you <span class="mdi mdi-tag-faces"></span></span> </strong>
        </a>
      </div>
    </div>
  </div>
</div>
