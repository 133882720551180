<div [class]="selectClass">
  <div class="selectImageBox" (click)="openImageLoader()">

    <div *ngIf="selectClass == 'main-class'">
    <h2>{{title}}</h2>
      <div class="dashedFrame">
        <img *ngIf="croppedImage != undefined" class="croppedImage" src="{{croppedImage}}" alt="">
        <table *ngIf="croppedImage == undefined" class="iconTable">
          <tr>
            <td>
              <img *ngIf="imageSelected!=null" width="30" height="30" [src]="imageSelected" alt="">
              <img *ngIf="imageSelected==null" width="30" height="30" src="/assets/icons/plus.png" alt="">
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="selectClass == 'personal-area'">
      <h2>{{title}}</h2>
      <div class="profileFrame">
        <img *ngIf="croppedImage != undefined" class="croppedImage" src="{{croppedImage}}" alt="">
        <div *ngIf="croppedImage == undefined">
          <img *ngIf="imageSelected!=null" width="30" height="30" [src]="imageSelected" alt="">
          <img *ngIf="imageSelected==null" width="30" height="30" src="/assets/icons/plus.png" alt="">
        </div>
      </div>
    </div>
  </div>
  <input #imageBoxInput appImgCompressor [uploader]="uploader" class="inputFileInput" type="file" accept="image/*" (change)="fileChangeEvent($event)" />

  <div class="FancyBoxImageCropper" *ngIf="cropperOpen">
    <image-cropper
      style="margin-bottom: 1em;"
      [maintainAspectRatio]="false"
      [imageChangedEvent]="imageChangedEvent"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
    <button class="btn btn-warning imageCropperBtn" (click)="cropImage()">{{'sale4you.label.cropImage.cropBtn' | translate}}</button>
    <button class="btn btn-danger imageCropperBtn" (click)="closeWindow()">{{"sale4you.label.close" | translate}}</button>
  </div>
</div>

