import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {Observable, throwError} from "rxjs";
import {catchError, filter, map} from "rxjs/operators";
import {FilterBase} from "../../../models/filter-base";
import {FilterbaseService} from "./filter-base/services/filterbase.service";
import {error} from "protractor";
import {selectOptionData} from "../../create-adv/dynamic-form-question/dynamic-form-question.component";


@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.css']
})
export class TopFilterComponent implements OnInit, AfterViewInit {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  @Output()
  callBackFunction = new EventEmitter<string>();
  advancedSearch: boolean = false;
  _filterBoxWitdh: any;



  getRequiredClass(filterBase: FilterBase):string{
    if(filterBase.widthRate != null){
      return filterBase.widthRate;
    }
    return '';
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public filterBaseService: FilterbaseService, private elementRef: ElementRef, private apiUrl: ApiUrl) {
    this.loadData();
    this.filterBaseService.getClickEvent().subscribe(res => {
      this.initBindSelect();
    })
  }

  initBindSelect() {
    this.filterBaseService.filterBases.filter(ques => ques.type === "bindSelect").forEach(qs => {
      const quesParam = this.apiUrl.pathTranslate(qs.callBackFunction, this.filterBaseService);
      let isDisplay = true;
      quesParam.requiredField.forEach(res => {
        if (res.value != '' && res.value != null) {
          isDisplay = false;
        }
      })
      if (!isDisplay) {
        this.http.get<selectOptionData[]>(quesParam.path).subscribe((res: selectOptionData[]) => {
              if (res != null && res.length > 0) {
                qs.bindSelectHide = !isDisplay;
              } else {
                qs.bindSelectHide = isDisplay;
              }
            }
        )
      }else{
        qs.bindSelectHide = !isDisplay;
      }
    })
  }

  ngOnInit(): void {

  }

  loadData(): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getFilterBase().subscribe((res)=>{
      this.filterBaseService.filterBases = res.sort((a, b) => {
          return a.order < b.order;
      });
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    },()=>{
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })
  }

  private handleObserverError(error: any): Observable<any>{
    this.loadingMaskServiceService.setOnLoadingMask(false, false);
    return throwError(error.error || error)
  }

  getFilterBase(): Observable<any>{
    const namespance = this.apiKey.getNameSpacePos("/",3);
    return this.http.get(this.apiKey.getFilterBase(namespance), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }

  openAdvancedSearch() {
    if(this.advancedSearch){
      this.advancedSearch = false;
    }else{
      this.advancedSearch = true;
    }
  }

  searchApply() {
    this.callBackFunction.emit()
  }

  set filterBoxWidth(width: number){
    this._filterBoxWitdh = width;
  }
  get filterBoxWidth(){
    return this._filterBoxWitdh;
  }
  ngAfterViewInit(): void {
    const componentWidth = this.elementRef.nativeElement.offsetWidth;
    this.filterBoxWidth = componentWidth;
  }
}
