import {Component, Input, OnInit} from '@angular/core';
import {ApiUrl} from "../../shared/models/apiurl.models";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TopProduct} from "../../models/top-product";
import {SystemApp} from "../../constans/SystemConfigurations";
import {LoadingMaskServiceService} from "../loading-frame/loading-frame.component";
// import {selectOptionData} from "../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";
import {TranslateService} from '@ngx-translate/core';
import {AppService } from '../../../app/app.service';
import {Product} from "../../models/product";

declare function itemsSlider(category: string): any;
@Component({
  selector: 'app-product-items',
  templateUrl: './product-items.component.html',
  styleUrls: ['./product-items.component.css']
})
export class ProductItemsComponent implements OnInit {
  //@Input() product!: Product;
  @Input() header!: string;
  @Input() namespace!: string;
  public count:number = 1;
  productList: TopProduct[];
  product:Product[];
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  base_url: any;
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public translate:TranslateService,public appService:AppService) {
    this.base_url = SystemApp.base_url_api;
    // @ts-ignore
    this.productList = [1,2,3,4,5,6];
    this.product=[];
    this.loadData()
  }
  initData():void{
    this.productList = this.productList.map(product=> { return{
      ...product,
      isNewAds: this.isNewAds(product.createTime)
    }})
  }

  loadData(): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getToProduct().subscribe((res) => {
      if(this.namespace == 'Nadlan'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
          id: res.id,
          name: res.name,
          shortDescription: res.shortDescription,
          image: res.images[0].image,
          link: 'None',
          createTime: res.createTime,
          discount: res.discount,
          availibilityCount: res.availibilityCount,
          price: res.price,
          images: res.images,


        }})
      }
      if(this.namespace == 'car'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
            id: res.id,
            name: res.name,
            shortDescription: res.shortDescription,
            image: res.images[0].image,
            createTime: res.createTime,
            discount: res.discount,
            price: res.price,
            images: res.images,
            availibilityCount: res.availibilityCount,
            link: 'None',
            color: res.Color?{
              colorName: res.Color.colorName,
              colorCode:res.Color.colorCode
            }: null
        }})

      }
      if(this.namespace == 'Sale2'){
        this.productList = res[''+this.namespace].map((res:any) =>{return {
          id: res.id,
          name: res.name,
          shortDescription: res.shortDescription,
          discount: res.discount,
          image: res.images[0].image,
          createTime: res.createTime,
          price: res.price,
          availibilityCount: res.availibilityCount,
          images: res.images,
          link: 'None'
        }})
      }
      setTimeout(() => { itemsSlider("owl-carousel-featured-"+this.namespace) }, 300);
      this.initData()
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })

  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }
  getToProduct(): Observable<any>{
    return this.http.get(this.apiKey.getTopProducts(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  ngOnInit() {

  }

  isNewAds(date:Date|string): boolean {
    date = new Date(date)
    if(date == null || date == undefined) {return false};
    const currentDay = new Date();

    const currentNowDay = currentDay.getDate() ;
    const currentMonth = currentDay.getMonth() ;
    const currentYear = currentDay.getFullYear() ;

    const adsNowDay = date.getDate() ;
    const sdsMonth = date.getMonth() ;
    const adsYear = date.getFullYear()

    const calculateDay= currentNowDay-adsNowDay;
    const calculateMonth= currentMonth-sdsMonth;
    const calculateYear= currentYear-adsYear;


    if(calculateDay <=4 &&  (calculateMonth == 0 && calculateYear == 0)){
      return true;
    }
    return false;
  }


  public addToWishList($event:any,product: Product){
    $event.preventDefault();
    this.appService.addToWishList(product);
  }
}
