import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuestionBase} from "../../pages/create-adv/models/question-base";
import {ControlValueAccessor, FormControl, ReactiveFormsModule} from "@angular/forms";
import {FormatInputDirective} from "../../shared/format-input-directive/format-input-directive";

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  standalone: true,
  providers:[FormatInputDirective],
  imports: [
    ReactiveFormsModule
  ],
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit, ControlValueAccessor  {

  @Input() question: QuestionBase<string>
  @Output() onValueChange: EventEmitter<void> = new EventEmitter<void>();
  @Input() formControlName: string;
  @Input() control: FormControl;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.control.setValue(value);
    this.onChange(value);
    this.onTouch(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  executeParentFunction($event:any) {
    this.onValueChange.emit($event);
  }
  ngOnInit(): void {

  }

}
