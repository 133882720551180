import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject, throwError} from 'rxjs';
import {Store, User, UserProfile} from './user.model';
import {ApiUrl} from "../../shared/models/apiurl.models";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class UsersService {
    private subject = new Subject<any>();
    private options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.apiUrl.getJWTToken()
        })
    }



    public url = "api/users";
    constructor(public http: HttpClient, public apiUrl: ApiUrl) { }

    private handleObserverError(error: any): Observable<any>{
        console.log(error)
        return throwError(error.error || error)
    }

    get userObjDetails(): UserProfile{
        if(localStorage.getItem('userDetails')){
            const userObJson = JSON.parse(localStorage.getItem('userDetails'))
            return {
                firstName: userObJson.firstName,
                lastName: userObJson.lastName,
                gender: userObJson.gender,
                image: userObJson.firstName,
                birthday: userObJson.birthday,
                fullName: userObJson.firstName + " "+ userObJson.lastName,
                avatar: userObJson.avatar
            }
        }
        return null;

    }

    sendClickEvent(obj: any = null){
        this.subject.next(obj);
    }

    getClickEvent():Observable<any>{
        return this.subject.asObservable();
    }

    getUser(): Observable<any> {
        return this.http.get<any>(this.apiUrl.getStoreByUserToken(), this.options).pipe(map((res) => res ? res : null ),catchError(this.handleObserverError));
    }
    getStores(): Observable<any> {
        return this.http.get<any>(this.apiUrl.getStores(), this.options).pipe(map((res) => res ? res : null ),catchError(this.handleObserverError));
    }

    addStore(store:any): Observable<any>{
        return this.http.post<any>(this.apiUrl.getStores(), JSON.stringify(store), this.options);
    }

    updateUser(user:User){
        return this.http.put(this.url, user);
    }

    partialUpdateUser(store, payload:any): Observable<any>{
        return this.http.patch(this.apiUrl.getStores() + store.id + "/", payload, this.options);
    }

    deleteUser(id: number) {
        return this.http.delete(this.url + "/" + id, this.options);
    }
    getUserDetails(){
        return this.http.get(this.apiUrl.getUserDetails(), this.options);
    }
    getAllUsers(){
        return this.http.get(this.apiUrl.getAllUsers(), this.options);
    }
    getAdmunStores(): Observable<any> {
        return this.http.get<any>(this.apiUrl.getAdminStores(), this.options).pipe(map((res) => res ? res : null ),catchError(this.handleObserverError));
    }
} 
