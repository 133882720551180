import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {Category} from "../../app.models";

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {

  @Input() categories;
  @Input() categoryParentId;
  @Output() change: EventEmitter<any> = new EventEmitter();
  mainCategories:Category[]=[];
  opened = [];

  constructor() {

  }

  public stopClickPropagate(event: any){
    if(window.innerWidth < 960){
      event.stopPropagation();
      event.preventDefault();
    }    
  }

  public changeCategory(event){
    console.log(event)
    this.change.emit(event);
  }

  ngOnInit(): void {
    if(this.categories && this.mainCategories) {
        this.mainCategories = this.categories.filter(category => category.parentId?.id == this.categoryParentId);
    }
  }

  openMenu(categoryId) {
    this.opened[categoryId] = true;
  }

  closeMenu(categoryId) {
    this.opened[categoryId] = false;
  }
}
