import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'clearWhiteSpace',
    pure: true
})
export class ClearWhiteSpace implements PipeTransform {
    transform(items: string): any {
        //@ts-ignore
        return items.trim().toLowerCase().replaceAll(" ","-");
    }
}
