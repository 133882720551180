import { Component, OnInit } from '@angular/core';
import {AdvFormService} from "../services/adv-form.service";

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.css']
})
export class ImageListComponent implements OnInit {
  imageList = new Map();
  constructor(private formService: AdvFormService) { }

  ngOnInit(): void {}

  onChange(imageFile: string, position: number) {
    this.imageList.set(position, imageFile);
    this.formService.addQuestion('images', Array.from(this.imageList));
  }
}
