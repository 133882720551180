import {Component, Input, OnInit} from '@angular/core';
import {MainCategory} from "../../../models/product";
import {UserAds} from "../../../models/user-ads";
import {TranslateService} from '@ngx-translate/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {SystemApp} from "../../../constans/SystemConfigurations";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {Product} from "../../../models/product";
import { Router } from '@angular/router';
import {LoginAction,LoginService} from "../../../components/session/login/login.service";
import {AppService} from "../../../app.service";
/**interface Product{
  name: string,
  description: string,
  price:number,
  typeProduct: string,
  mainCategory: MainCategory,
  sconderyCategory:[] ,
  area:string,
  deviceStatus:string,
  createTime:string,
  updateTime:string,
  User:UserAds,
  userContactDetails:any,
  armatureList:[],
  images: [];
  avatar: string,
  active:string,
}**/

interface UserInfo {
  email: string,
  first_name: string,
  last_name: string,
  mainPhone: number,
  scondryPhone: number,
  country: string,
  city: string,
  street: string,
  streetNumber:number,
  department: number,
  birthday: string,
  gender: string,
  idExDate: string,
  idNum: number,
  profilePhoto: string,
  neighborhood: string,
  zipCode: number,
  PoBox: number,
  area: string,
  FamilyStatus: string,
  date_joined: string,
  avatar: string,
}
@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.css']
})
export class WishlistComponent implements OnInit {
  @Input() product!: Product;
  allWishlistProduct = this.appservice.Data.wishList
  public href: string = "10.0.0.22:8000/user/user_ads/";
  userInfo: UserInfo;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  base_url: String;
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService,public translate:TranslateService,private router: Router, private loginService: LoginService,private appservice:AppService) {
    this.base_url = SystemApp.base_url_api;
    // @ts-ignore
    this.userInfo={};
    this.loadData()
  }


  loadData(): void{
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.getToUserInfo().subscribe((res) => {
      this.userInfo = {
        email: res.email,
        first_name: res.first_name,
        last_name: res.last_name,
        mainPhone: res.mainPhone,
        scondryPhone: res.scondryPhone,
        country: res.country,
        city: res.city,
        street: res.street,
        streetNumber:res.streetNumber,
        department: res.department,
        birthday: res.birthday,
        gender: res.gender,
        idExDate: res.idExDate,
        idNum: res.idNum,
        profilePhoto: res.profilePhoto,
        neighborhood: res.neighborhood,
        zipCode: res.zipCode,
        PoBox: res.PoBox,
        area: res.area,
        FamilyStatus: res.FamilyStatus,
        date_joined: res.date_joined,
        avatar: res.avatar,
      }
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
    })
  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  updateUserInfo(): Observable<any>{
    return this.http.put(this.apiKey.getUserInfo(),this.userInfo ,this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError));
  }

  getToUserInfo(): Observable<any>{
    return this.http.get(this.apiKey.getUserInfo(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  ngOnInit(): void {
    this.href = this.router.url;
  }
  logout() {
    localStorage.removeItem('token')
    this.loginService.sendClickEvent(LoginAction.Close);
  }


}
