import {Component, Input, OnInit} from '@angular/core';
import {FilterBase} from "../../../../../models/filter-base";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss']
})
export class DateFieldComponent implements OnInit {

  @Input() filterBase!: FilterBase;
  @Input() widthSize: number = 160;
  @Input() hideTitle: boolean =false;
  selectedValue: any;
  constructor(private filterBaseService: FilterbaseService) { }

  ngOnInit(): void {
  }

  set selectDate(value: Date){
    this.filterBaseService.addQuestion(this.filterBase.key, value);
  }

}
