import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-text-area-ads',
  templateUrl: './text-area-ads.component.html',
  styleUrls: ['./text-area-ads.component.scss']
})
export class TextAreaAdsComponent implements OnInit {

  @Input() question: QuestionBase<string>;
  @Input() value: string;

  constructor() { }

  ngOnInit(): void {
  }

}
