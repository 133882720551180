import {Component, Input, OnInit} from '@angular/core';
import {UserAds} from "../../../../../models/user-ads";

@Component({
  selector: 'app-ads-socialmedia',
  templateUrl: './ads-socialmedia.component.html',
  styleUrls: ['./ads-socialmedia.component.scss']
})
export class AdsSocialmediaComponent implements OnInit {

  @Input() adsItem: UserAds;

  @Input() shareUrl: string = '';
  @Input() shareText: string = '';
  @Input() shareImage: string = '';

  ngOnInit() {
  }

  share(platform: string) {
    let shareLink: string;

    switch (platform) {
      case 'facebook':
        shareLink = `https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`;
        break;
      case 'twitter':
        shareLink = `https://twitter.com/intent/tweet?url=${this.shareUrl}&text=${this.shareText}`;
        break;
      case 'linkedin':
        shareLink = `https://www.linkedin.com/shareArticle?url=${this.shareUrl}&title=${this.shareText}`;
        break;
      case 'pinterest':
        shareLink = `https://www.pinterest.com/pin/create/button/?url=${this.shareUrl}&media=${this.shareImage}&description=${this.shareText}`;
        break;
      default:
        break;
    }

    if (shareLink) {
      window.open(shareLink, '_blank');
    }
  }

}
