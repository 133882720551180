

  <!--<div class="shop-head">
    <a href="#"><span class="mdi mdi-home"></span> Home</a> <span class="mdi mdi-chevron-right"></span> <a href="#">Fruits & Vegetables</a> <span class="mdi mdi-chevron-right"></span> <a href="#">Fruits</a>
    <div class="btn-group float-right mt-2" style="direction: rtl">
      <button type="button" class="btn btn-dark dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{'sale4you.label.sort-by-products' | translate}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </button>
      <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="#">{{'sale4you.label.relevance' | translate}}</a>
        <a class="dropdown-item" href="#">{{'sale4you.label.price' | translate}}</a>
        <a class="dropdown-item" href="#">{{'sale4you.label.discount' | translate}}</a>
        <a class="dropdown-item" href="#">{{'sale4you.label.name' | translate}}</a>
      </div>
    </div>
   <h5 class="mb-3">Fruits</h5>
  </div>-->
  <div #scrollContainer class="scroll-container">
      <div class="product-list">
    <div class="row no-gutters productContainer">
      <div *ngFor="let product of productsList; let i = index" [attr.data-index]="i" class="col-md-4">
        <div class="product">
             <a [routerLink]="['/single/'+product.id]">
            <div class="product-header">
              <span class="badge badge-success" *ngIf="productsList[i].discount!=null">{{"sale4you.label.discount" | translate}} {{productsList[i].discount}}{{"sale4you.label.Percent" | translate}}</span>

              <img class="img-fluid" [src]="productsList[i].images[0].image!= null ? productsList[i].images[0].image:''" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
             </a>
             <a [routerLink]="['/single/'+product.id]">
            <div class="product-body">
              <h5><b>{{productsList[i].name}}</b></h5>
              <h6><strong><span class="mdi mdi-approval" *ngIf="productsList[i].description!=null"></span>{{productsList[i].description}}</strong></h6>
            </div>
             </a>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right" (click)="addToWishList($event,product)">{{"sale4you.label.CartSlider.ProceedToCheckout" | translate}} </button>
              <p class="offer-price mb-0" *ngIf="productsList[i].price!=null">{{"sale4you.label.WishList.shekel" | translate}} {{productsList[i].price}} <i class="mdi mdi-tag-outline"></i></p>
            </div>

        </div>
      </div>
    </div>
  </div>
<!--  <div class="row no-gutters">-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row no-gutters">-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="row no-gutters">-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="col-md-4">-->
<!--      <div class="product">-->
<!--        <a [routerLink]="['/single/1']">-->
<!--          <div class="product-header">-->
<!--            <span class="badge badge-success">50% OFF</span>-->
<!--            <img class="img-fluid" src="assets/img/about.jpg" alt="">-->
<!--            <span class="veg text-success mdi mdi-circle"></span>-->
<!--          </div>-->
<!--          <div class="product-body">-->
<!--            <h5>Product Title Here</h5>-->
<!--            <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>-->
<!--          </div>-->
<!--          <div class="product-footer">-->
<!--            <button type="button" class="btn btn-secondary btn-sm float-right"><i class="mdi mdi-cart-outline"></i> Add To Cart</button>-->
<!--            <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>-->
<!--          </div>-->
<!--        </a>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <nav>
    <ul class="pagination justify-content-center mt-4 unselectable">

      <li class="page-item" [ngClass]="this.filterbaseService.offset <=1 ?'disabled':''" >
        <a class="page-link" (click)="previousPage()">{{"sale4you.label.Wishlist.Previous" | translate}}</a>
      </li>
      <li *ngFor="let pageNum of range(totalPages); " class="page-item" [ngClass]="pageNum == this.filterbaseService.offset ? 'active' : '' "><a class="page-link"  (click)="pageChange(pageNum)" >{{pageNum}}</a></li>
<!--      <li class="page-item active"><span class="page-link">2</span></li>-->
<!--      <li class="page-item"><a class="page-link" href="#">3</a></li>-->
      <li class="page-item" [ngClass]="this.filterbaseService.offset >=totalPages ?'disabled':''">
        <a class="page-link"  (click)="nextPage()">{{"sale4you.label.Wishlist.Next" | translate}}</a>
      </li>
    </ul>
  </nav>
