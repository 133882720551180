<div *ngFor="let product of productList; let i = index" [attr.data-index]="i" class="item">
  <div class="product">
    <a href="#">
      <div class="product-header" >
        <span class="badge badge-success">50% OFF</span>
        <img alt="" src="{{product.image}}" class="img-fluid">
        <span class="veg text-success mdi mdi-circle"></span>
      </div>
      <div class="product-body">
        <h5>{{productList[i].name}}</h5>
        <h6><strong><span class="mdi mdi-approval"></span>{{product.shortDescription}}</strong></h6>
      </div>
      <div class="product-footer">
        <button class="btn btn-secondary btn-sm float-right search-filter-btn" type="button"><i class="mdi mdi-cart-outline"></i> {{this.translate.instant('sale4you.label.WishList.AddToCart')}}</button>
        <p class="offer-price mb-0">{{this.translate.instant('sale4you.label.WishList.shekel')}}450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price"> {{this.translate.instant('sale4you.label.WishList.shekel')}} 800.99</span></p>
      </div>
    </a>
  </div>
</div>
