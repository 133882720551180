import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Category, Forms, QuestionPriority} from "../../constans/formsQuestions";
import {FormMainCategory} from "../../models/forms.model";
import {Router} from "@angular/router";
import {LoadingMaskServiceService} from "../../components/loading-frame/loading-frame.component";

@Component({
  selector: 'app-create-adv',
  templateUrl: './create-adv.component.html',
  styleUrls: ['./create-adv.component.css']
})
export class CreateAdvComponent implements OnInit, AfterViewInit {



  constructor(private router: Router, private loadingMas: LoadingMaskServiceService) {
    this.loadingMas.setOnLoadingMask(true,true)
    this.loadData()
  }

  loadData (): void{

  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(()=>this.loadingMas.setOnLoadingMask(false,false),250)
  }



}
