<section class="pt-3 pb-3 page-info section-padding border-bottom bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <a href="#"><strong><span class="mdi mdi-home"></span> Home</strong></a> <span class="mdi mdi-chevron-right"></span> <a href="#">Fruits & Vegetables</a> <span class="mdi mdi-chevron-right"></span> <a href="#">Fruits</a>
      </div>
    </div>
  </div>
</section>
<section class="shop-single section-padding pt-3" *ngIf="productObj != undefined">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="shop-detail-left">
          <div class="shop-detail-slider">
            <div class="favourite-icon">
              <a class="fav-btn" title="" data-placement="bottom" data-toggle="tooltip" href="#" data-original-title="59% OFF"> <i class='far fa-bookmark'></i><i class="mdi mdi-tag-outline"></i></a>
            </div>
            <div id="sync1" class="owl-carousel">
              <div *ngFor="let productImage of productObj.images" class="item"><img alt="" style="object-fit: cover;  width: 100%;" src="{{productImage.image}}" class="img-fluid img-center"></div>
            </div>
            <div id="sync2" class="owl-carousel">
              <div *ngFor="let productImage of productObj.images" class="item"><img alt="" style="object-fit: cover;" src="{{productImage.image}}" class="img-fluid img-center"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6" >
        <div class="shop-detail-left ">
         <!-- <span class="badge badge-success">{{"sale4you.label.product.greenLabel" | translate}}</span>-->
          <span class="badge badge-success" *ngIf="productObj.discount!=null">{{'sale4you.label.discount' | translate}} : {{productObj.discount}}{{'sale4you.label.Percent' | translate}} </span>

          <h2 class="padingTopFivePx">{{productObj.name}}</h2>
          <!--<h6 style="direction: rtl;"><strong><span class="mdi mdi-approval"></span> {{'sale4you.label.location' | translate}}</strong> - {{productObj.area}}</h6>-->
          <!--<p class="regular-price" *ngIf="productObj.price!=null"><i class="mdi mdi-tag-outline"></i> {{productObj.price}} price</p>-->
          <p class="offer-price  padingButtomFivePx" *ngIf="productObj.price!=null">{{'sale4you.label.price' | translate}} : <span class="text-success">{{productObj.price}}</span> </p>
         <!-- <p class="offer-price  padingButtomFivePx" *ngIf="productObj.discount!=null">{{'sale4you.label.discount' | translate}} : <span class="text-success"> {{productObj.discount}}{{'sale4you.label.Percent' | translate}}</span> </p>-->
          <button type="button" class="btn btn-secondary btn-lg" (click)="addToWishList($event,productObj)"><i class='fas fa-cart-plus'></i> {{'sale4you.label.addToWishList' | translate}}</button>



          <!-- <p class="offer-price mb-0" *ngIf="productObj.discount!=null">price : <span class="text-success">{{productObj.price}} price</span></p>-->
          <div id="detailsTable" style="padding-top: 10px" >
            <div class="row TableDetails" >
              <div class="col itemTableDetails"  *ngFor="let item of tableDetailsList" >
                <div  [outerHTML]=" item | getValueItemProduct:productObj" ></div>
              </div>
            </div>
          </div>
          <div class="short-description">
            <h5 style="padding-right: 5px">
              {{'sale4you.label.quickOverview' | translate}}
              <p class="float-right">{{'sale4you.label.availability' | translate}}:  <span *ngIf="productObj.deviceStatus" class="badge badge-success">{{'sale4you.label.availability.active' | translate}}</span><span *ngIf="!productObj.deviceStatus" class="badge-danger">{{'sale4you.label.availability.inactive' | translate}}</span></p>
            </h5>
            <p class="TextAlignRight">  {{productObj.description}} </p>
          </div>
          <h6 class="mb-3 mt-4">{{"sale4you.label.whySale4you" | translate}}</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="feature-box">
                <i class="mdi fas fa-car-alt" style='color:white'></i>
                <h6 class="text-info">{{"sale4you.label.freeDelivery" | translate}}</h6>
                <p>{{"sale4you.label.freeDelivery.value" | translate}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="feature-box">
                <i class="mdi far fa-handshake" style='color:white'></i>
                <h6 class="text-info">{{"sale4you.label.Guarantee" | translate}}</h6>
                <p>{{"sale4you.label.Guarantee.value" | translate}}</p>
              </div>
            </div>
          </div>
         <!-- <div class="short-description">
            <h5 class="quick-overview padingTopFivePx padingButtomFivePx">
              {{'sale4you.label.quickOverview' | translate}}
              <p class="float-right availability-tag">{{'sale4you.label.availability' | translate}}
                <span *ngIf="productObj.deviceStatus" class="badge badge-success">{{'sale4you.label.availability.active' | translate}}</span>
                <span *ngIf="!productObj.deviceStatus" class="badge-danger">{{'sale4you.label.availability.inactive' | translate}}</span>
              </p>
            </h5>
            <p class="statusdevceStyle">
              {{productObj.description}}
            </p>
          </div>-->

          <!--<h6 class="mb-3 mt-4">{{"sale4you.label.whySale4you" | translate}}</h6>
          <div class="row">
            <div class="col-md-6">
              <div class="feature-box">
                <i class='fas fa-truck-moving'></i>
                <h6 class="text-info">{{"sale4you.label.freeDelivery" | translate}}</h6>
                <p>{{"sale4you.label.freeDelivery.value" | translate}}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="feature-box">
                <i class="mdi mdi-basket"></i>
                <h6 class="text-info">{{"sale4you.label.Guarantee" | translate}}</h6>
                <p>{{"sale4you.label.Guarantee.value" | translate}}</p>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</section>
<!--<app-best-offers></app-best-offers>-->
<app-product-items [header]="'sale4you.label.top6products.car'" [namespace]="'car'"></app-product-items>
