import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Settings, AppSettings } from './app.settings';
import { TranslateService } from '@ngx-translate/core';
import {CookieService} from "ngx-cookie-service";
import {MetaTagService} from "./services/meta-tag.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading: boolean = false;
  public settings: Settings;
  private cookieValue: string;
  constructor(public appSettings: AppSettings, 
              public router: Router,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private metaTagService: MetaTagService,
              public translate: TranslateService){
    this.metaTagService.setMetaTag('title', 'New Page Title');
    this.metaTagService.setMetaTag('icon', 'New Page Title');
    this.metaTagService.setMetaTag('description', 'Description for the page.');
    this.metaTagService.setMetaTag('keywords', 'keyword1, keyword2, keyword3');
    this.settings = this.appSettings.settings;
    // translate.addLangs(['en','de','fr','ru','tr','he','ar']);
    translate.addLangs(['he']);
    translate.setDefaultLang('he');
    translate.use('he');


    translate.setDefaultLang('he');
    translate.use('he');
    let text;
    this.cookieValue = this.cookieService.get('Password');
    if(this.cookieValue == "0528330429" || this.cookieValue == "0507273222" || this.cookieValue == "0545734554"){

    }else{
      let lock = prompt("Please enter your password:", "Harry Potter2");
      if (lock == "0528330429" || lock == "0507273222" || lock == "0545734554") {
        this.cookieService.set('Password',lock);
      } else {
        window.location.href="http://www.google.com"
      }
    }
  }

  ngOnInit() {
   // this.router.navigate(['']);  //redirect other pages to homepage on browser refresh    
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0,0);
        } 
      }
    })  
  }
}
