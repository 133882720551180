import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {OrderSessionDialogComponent} from "../../../sales/orders/order-session-dialog/order-session-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ImageShowcaseDialogComponent} from "./image-dialog/image-dialog.component";


@Component({
  selector: 'image-cell-renderer',
  templateUrl: './image-cellrenderer.component.html',
  styleUrls: ['./image-cellrenderer.component.scss'],
})
export class ImageCellrendererComponent implements ICellRendererAngularComp {
  imageList: any = [];
  val: any;
  params: any;

  constructor( public dialog: MatDialog) {
  }
  agInit(params: any) {
    this.params = params;
    this.imageList = params.value;
  }

  refresh(params: any): boolean {
    this.imageList = params.value;
    return true;
  }


  public openSessionDialog(imageUrl: any) {

    this.dialog.open(ImageShowcaseDialogComponent, {
      data: imageUrl
    })
  }

}
