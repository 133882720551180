import {Component, Directive, HostListener, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../models/question-base";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SectionUI} from "../dynamic-form/dynamic-form.component";
import {ValidationService} from "../../../shared/inputValidations/validation-service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {AdvFormService} from "../services/adv-form.service";
import {TranslateService} from "@ngx-translate/core";



export interface LicenseCodeDes{
  producer: string,
  engineCapacity: number,
  fourWheelDrive: string,
  year: number,
  finishLevel: string,
  fuel: string,
  hp: number,
  seats: number,
  commercialNickname: string
}

@Directive({
  selector: '[appAutoTab]'
})
export class AutoTabDirective {
  @Input('appAutoTab') appAutoTab!:any[]
  @Input('appReverseTab') appReverseTab!:any
  @HostListener('keyup', ['$event']) onDelete(input:any) {
    if(input.target.value == "" && input.key =="Backspace"){
      if(this.appAutoTab[1] != null){
        this.appAutoTab[1].focus()
      }
    }
  }

  @HostListener('input', ['$event.target']) onInput(input:any) {
    const inputValue = input.value
    if(inputValue!=''){
      input.value = inputValue%10
    }
    const length = input.value.length
    const maxLength = 1
    const empty = 0;
    if (length == maxLength) {
      if(this.appAutoTab[0] == null){return}
      this.appAutoTab[0].focus()
    }
    if (length == empty) {
      if(this.appAutoTab[1] == null){return}
      this.appAutoTab[1].focus()
    }
  }
}

@Component({
  selector: 'app-car-type',
  templateUrl: './car-type.component.html',
  styleUrls: ['./car-type.component.css']
})
export class CarTypeComponent implements OnInit {
  public error: string = "";
  public subtitle: string = "";
  public licenseCodeDes!: LicenseCodeDes | null;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    })
  }
  @Input() questions!: QuestionBase<string>[] | undefined;
  @Input() form!: FormGroup;
  @Input() section!: SectionUI;
  isLicenceCode: boolean = true;
  licenceCodeForm: any;
  carInfo!: string;
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private apiUl:ApiUrl, private advFormService: AdvFormService, private translate: TranslateService) {
    this.resetForm();
  }

  resetForm(): void{
    this.licenceCodeForm = this.formBuilder.group({
      licenceCode: ['',[Validators.required, ValidationService.oneDigit]],
    });
    this.resetResults();
    this.clear();
  }
  ngOnInit(): void {

  }

  switchFunc(action: string) {
   this.isLicenceCode = action == 'manual' ? false : true;
   this.resetForm();

  }

  resetResults(): void{
    this.error = ''
    this.subtitle = ''
  }

  clear(){
    this.advFormService.deleteQuestion('producerDescription');
    this.advFormService.deleteQuestion('mainModelDescription');
    this.advFormService.deleteQuestion('year');
    this.advFormService.deleteQuestion('description');
    this.advFormService.deleteQuestion('licenseNumber', 'enable');
    this.licenseCodeDes = null;
  }

  onSubmit($event: any) {
    if (this.licenceCodeForm.dirty && this.licenceCodeForm.valid) {
      this.searchCarByLicenceCode().subscribe(res=>{
        this.advFormService.addQuestion('producerDescription',{
          "id": null,
          "label": res.producer,
          "name": "producerDescription",
          "value": {
            "value": res.producer,
            "name": "producerDescription"
          }
        })
        this.advFormService.addQuestion('mainModelDescription',{
          "id": null,
          "label": res.commercialNickname,
          "name": "mainModelDescription",
          "value": {
            "value": res.commercialNickname,
            "name": "mainModelDescription"
          }
        })
        this.advFormService.addQuestion('year',{
          "id": null,
          "label": res.year,
          "name": "year",
          "value": {
            "value": res.year,
            "name": "year"
          }})
        this.advFormService.addQuestion('description',  {
          "label": this.translate.instant('sale4you.label.licenseCodeDes.header.2', res),
          "name": "description",
          "value": {
            "value": this.translate.instant('sale4you.label.licenseCodeDes.header.2', res),
            "name": "description"
          }
        })
        this.advFormService.addQuestion('licenseNumber', this.licenceCodeForm.get('licenceCode').value, 'disable')
        this.licenseCodeDes = res;
        this.error = res.title
      },error => {
        this.clear();
        this.resetResults();
        this.error = 'לא נמצאת רכב מתאים במשרד הרישוי, נא לבדוק את המספר מחדש'
      })
    }

  }

  searchCarByLicenceCode(): Observable<any>{
    const url = this.apiUl.getLicenceCarUrl()
    const licenceCar = Object.values(this.licenceCodeForm.value).concat('').join('');
    const uri ="?licenseCode=" + licenceCar
    // @ts-ignore
    return this.http.get(url+uri.replaceAll('-',''),this.options)
  }

  onReset() {
    this.resetForm()
  }
}
