import {Injectable} from "@angular/core";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Injectable()
export class AdsService {
    private options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.apiUrl.getJWTToken()
        })
    }
    constructor(private apiUrl: ApiUrl,public http:HttpClient, public snackBar: MatSnackBar, public router:Router) { }
    public updateAds(adsId:number, partial= {}){
        return this.http.patch(this.apiUrl.getAdsUrl() + adsId + "/", partial, this.options);
    }

}