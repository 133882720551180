<div class="container categoryListContainer">
  <span *ngFor="let cate of subcategories">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="cb8">
      <label class="custom-control-label">
        <a href="{{'/shop/'+selectedCategory.toLowerCase()}}/category/{{cate.id}}" class="nav-link">{{cate.name}}</a>
      </label>
    </div>
  </span>
</div>

