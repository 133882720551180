import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {Observable, of, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class AdvService {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }

  private advStack:any[] = []




  constructor(private http: HttpClient, private apiKey:ApiUrl) {

  }

  ngOnInit(): void {

  }

  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  public getAdvertisement(num: number): Observable<any>{
    return this.http.get(this.apiKey.getAdvertisement(num), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
}
