import {Component, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {QuestionBase} from "../models/question-base";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {HttpClient} from "@angular/common/http";
import {SystemApp} from "../../../constans/SystemConfigurations";
import {AdvFormService} from "../services/adv-form.service";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";


export interface selectOptionData{
  id: string;
  label: string;
  name: string;
  value:{
    value: string;
    name: string;
  }
  selected: boolean;
}

export interface PathOptions{
  path: string;
  requiredField: Array<{ index: string; value: string }>;
  displayField: string
  absoluteUrl: string
}

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html',
  styleUrls:['./dynamic-form-question.component.css']
})
export class DynamicFormQuestionComponent implements OnInit{
  @Input() question!: QuestionBase<string>;
  @Input() form!: FormGroup;

  pathOption!:{ path: string; requiredField: Array<{ index: string; value: string }>; displayField: string };

  get isValid() { return this.form.controls[this.question.key].valid; }
  constructor(private apiUrl: ApiUrl, private http: HttpClient, private formService: AdvFormService, private loadingMaskServiceService: LoadingMaskServiceService) {

  }

  getPathOption(): PathOptions{
    return this.apiUrl.pathTranslate(this.question.callBackFunction,this.formService, this.question.additionalParam);
  }

  fetchDropDownData = (playLoadOpt: PathOptions) => {
    const questionOp = this.question
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    if(questionOp !== undefined && questionOp !== null){
      this.http.get<selectOptionData[]>(playLoadOpt.path).subscribe((res:selectOptionData[]) => {
        this.question.options = res
        this.loadingMaskServiceService.setOnLoadingMask(false, false);
      })
    }
    return
  };

  ngOnInit(): void {
    if(this.question.controlType === 'dropdown'){
      const playLoadOpt = this.getPathOption()
      this.fetchDropDownData(playLoadOpt)
    }

    // add the subject injector
    this.formService.getQOClickEvent().subscribe(res=>{
        const question = this.formService.getQuestion(this.question.key)
        if( question != null ) {
          this.form.controls[this.question.key].setValue(question);
          if(res.action == 'disable'){
            this.form.controls[this.question.key].disable({onlySelf:true})
          }
          if(res.action == 'enable'){
            this.form.controls[res.index].setValue(null);
            this.form.controls[res.index].enable();
          }
        }
    })
  }

  dropDownScroll($event: Event) {
  }

  selectOnClick() {
      const pathOpt = this.getPathOption()
      if(pathOpt.requiredField.length == 0){
        pathOpt.requiredField.forEach(res => {
          if(res.value == undefined){
            alert("Required prevois data")
          }
        })
      }else{
        const playLoadOpt = this.getPathOption()
        this.fetchDropDownData(playLoadOpt)
      }
  }

  valuechange($event: any) {
    const index = this.question.key
    if(this.question.type ==='checkbox'){
      const value = $event.target.checked
      this.formService.addQuestion(index,value)
    }else{
      const value = $event.target.value
      this.formService.addQuestion(index,value)
    }
  }
}
