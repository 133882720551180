import { Component, OnInit } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
@Component({
  selector: 'app-section-padding',
  templateUrl: './section-padding.component.html',
  styleUrls: ['./section-padding.component.css']
})
export class SectionPaddingComponent implements OnInit {

  constructor(private  translateService: TranslateService) { }

  ngOnInit(): void {
  }

}
