<!-- your-component.component.html -->

<div class="credit-card-form row" style="direction: rtl; text-align: right;">
    <div  class="adsBox col-sm-12 col-md-6">
        <img class="adsImageBox" [src]="adsRoute.image" alt="">
        <h2 class="header">{{adsRoute.title}}</h2>
        <h2 class="price">{{adsRoute.price}}₪</h2>
        <p class="description">{{adsRoute.description}}</p>
    </div>
    <div class="col-sm-12 col-md-6 credit-card-info-box" >
        <form #creditCardForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="cardholderName">שם מחזיק הכרטיס:</label>
                <input type="text" id="cardholderName" [(ngModel)]="cardholderName" name="cardholderName" required>
            </div>

            <div class="form-group">
                <label for="email">כתובת מייל:</label>
                <input type="email" id="email" [(ngModel)]="email" name="email" required>
            </div>

            <div class="form-group">
                <label for="phoneNumber">מספר טלפון:</label>
                <input type="tel" id="phoneNumber" [(ngModel)]="phoneNumber" name="phoneNumber" required>
            </div>

            <div class="row">
                <div class="col">
                    <label for="cardNumber">פרטי כרטיס אשראי:</label>
                    <div class="credit-card-details">
                        <div class="credit-card-number">
                            <div class="border text-center" style="direction: ltr">
                                <input type="text" id="cardNumber" [(ngModel)]="cardNumber" name="cardNumber" pattern="\d{4} \d{4} \d{4} \d{4}"
                                       maxlength="19" inputmode="numeric"
                                       placeholder="מספר כרטיס" required (input)="onCardNumberChange()">
                                <div class="cardTypeBox">
                                    <img *ngIf="isCardType(CreditCardType.VISA)" [src]="getCardIcon(CreditCardType.VISA)" alt="Credit Card Icon" class="credit-card-icon">
                                    <img *ngIf="isCardType(CreditCardType.MASTER_CARD)" [src]="getCardIcon(CreditCardType.MASTER_CARD)" alt="Credit Card Icon" class="credit-card-icon">
                                    <img *ngIf="isCardType(CreditCardType.AM)" [src]="getCardIcon(CreditCardType.AM)" alt="Credit Card Icon" class="credit-card-icon">
                                    <img *ngIf="isCardType(CreditCardType.DINERS)" [src]="getCardIcon(CreditCardType.DINERS)" alt="Credit Card Icon" class="credit-card-icon">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col" style="padding-left: 0">
                    <div class="border text-center">
                        <input type="text" id="expirationDate" [(ngModel)]="expirationDate" name="expirationDate"
                               maxlength="5" inputmode="numeric"
                               pattern="(0[1-9]|1[0-2])\/\d{2}" placeholder="MM/YY" required (input)="onExpirationDateChange()">
                    </div>
                </div>
                <div class="col" style="padding-right: 0">
                    <div class="border text-center">
                        <input type="text" id="cvv" [(ngModel)]="cvv" name="cvv" maxlength="3" pattern="\d{3}" placeholder="CVV" required  (input)="onCvvChange()" />
                    </div>
                </div>
            </div>


            <div class="form-group">
                <label for="installments">מספר תשלומים:</label>
                <input type="number" id="installments" [(ngModel)]="numOfPays" name="installments" value="1" disabled required>
            </div>
            <input *ngIf="payProcess == PaymentProcessStatus.TODO"  [ngClass]="!CheckIfValid(creditCardForm)?'submitBtnDisabled':'submitBtn'"  value="שלח תשלום" [disabled]="!CheckIfValid(creditCardForm)" type="submit"/>
            <button *ngIf="payProcess == PaymentProcessStatus.IN_PROGRESS" class="btn btn-primary" type="button" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden"> התשלום מתבצע... </span>
            </button>
            <button *ngIf="payProcess == PaymentProcessStatus.DONE" class="btn btn-success" type="button" disabled>
                <i class="fa fa-check"></i>
                <span class="visually-hidden"> תשלום בוצע בהצלחה </span>
            </button>
        </form>
    </div>
</div>
