import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-text-box-input',
  templateUrl: './text-box-input.component.html',
  styleUrls: ['./text-box-input.component.scss']
})
export class TextBoxInputComponent implements OnInit {
  @Input() question: QuestionBase<string>;
  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
