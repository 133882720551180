import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from "../../app.service";

declare function RightSideBar(): any;
@Component({
  selector: 'app-cart-sidebar',
  templateUrl: './cart-sidebar.component.html',
  styleUrls: ['./cart-sidebar.component.css']
})
export class CartSidebarComponent implements OnInit {
  allWishlistProduct = this.appservice.Data.wishList
  constructor(public translate:TranslateService,private appservice:AppService) { }

  ngOnInit(): void {
    RightSideBar()
  }

}
