<div class="options transition" [ngClass]="{'show': showOptions}">
    <button mat-raised-button (click)="showOptions = !showOptions" class="options-icon mat-elevation-z0">
        <mat-icon>palette</mat-icon>
    </button>
    <mat-card fxLayout="column" fxLayoutAlign="space-between center">
        <span class="skin-icon blue" (click)="changeTheme('blue')"></span>
        <span class="skin-icon green" (click)="changeTheme('green')"></span>
        <span class="skin-icon red" (click)="changeTheme('red')"></span> 
        <span class="skin-icon pink" (click)="changeTheme('pink')"></span> 
        <span class="skin-icon purple" (click)="changeTheme('purple')"></span>
        <span class="skin-icon grey" (click)="changeTheme('grey')"></span>  
        <button mat-mini-fab color="warn" (click)="settings.rtl = !settings.rtl" matTooltip="LTR / RTL">
            <mat-icon>swap_horiz</mat-icon>
        </button>     
    </mat-card>
</div>