import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-check-box-ads',
  templateUrl: './check-box-ads.component.html',
  styleUrls: ['./check-box-ads.component.scss']
})
export class CheckBoxAdsComponent implements OnInit {

  @Input() question: QuestionBase<string>;
  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
