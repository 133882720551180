import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../../shared/models/apiurl.models";
import {SystemApp} from "../../../../constans/SystemConfigurations";
import {DynamicFormDialogComponent} from "../../dynamic-form-dialog/dynamic-form-dialog.component";
import {HttpActions} from "../../../../app.models";
import {ConfirmationDialogComponent} from "../../../../shared/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-edit-remove-cell-renderer',
  templateUrl: './edit-remove-cell-renderer.component.html',
  styleUrls: ['./edit-remove-cell-renderer.component.scss']
})
export class EditRemoveCellRendererComponent implements  ICellRendererAngularComp {
  private rowId: any;
  private tableUrl: string;
  private params: ICellRendererParams
  private callBackFunction: Function;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey: ApiUrl, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onDeleteClick() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '250px',
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this item?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // User clicked 'Yes', perform delete action
        return this.http.delete( this.tableUrl.replace(/^\/+|\/+$/g, '') +'/'+ this.rowId +"/", this.options).subscribe(res=>{
          this.callBackFunction();
        });
      }
    });

  }

  onEditClick() {
    this.params.colDef['parent'].dialog.open(DynamicFormDialogComponent,{
      data: {
        url: this.params.colDef['parent'].dataUrl,
        callBackFunction: this.params.colDef['callBackFunction'],
        action: HttpActions.UPDATE,
        rowData: this.params.data,
        rowId: this.rowId,
        columns: this.params.colDef['parent'].columnDefs.map((field:any)=> {return {key: field.key, type: field.typeInput, url: field.url, headerName: field.headerName, parentMapValue:field.parentMapValue, relatedField: field.relatedField, dropDownLabels:field.dropDownLabels}})
      }});
  }

  agInit(params: ICellRendererParams): void {
    this.params = params
    this.tableUrl = params.colDef['tableUrl'];
    this.callBackFunction = params.colDef['callBackFunction'];
    this.rowId = params.value;
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }
}
