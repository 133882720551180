<section class="product-items-slider section-padding bg-white border-top">
  <div class="container">
    <div class="section-header">
      <h5 class="heading-design-h5">{{"sale4you.label.bestOffers.label" | translate}} <span class="badge badge-primary">{{"sale4you.label.bestOffers.label" | translate}}</span>
        <a class="float-right text-secondary" href="shop.html">{{"sale4you.label.viewAll" | translate}}</a>
      </h5>
    </div>
    <div class="owl-carousel owl-carousel-featured">
      <div *ngFor="let i of [1,2,3,4,5,6,7,8,9]" class="item">
        <div class="product">
          <a [routerLink]="['/single/1']">
            <div class="product-header">
              <span class="badge badge-success">{{"sale4you.label.bestOffers.greenLabel" | translate}}</span>
              <img class="img-fluid" src="assets/img/about.jpg" alt="">
              <span class="veg text-success mdi mdi-circle"></span>
            </div>
            <div class="product-body">
              <h5>Product Title Here</h5>
              <h6 style="direction: rtl;"><strong><span class="mdi mdi-approval"></span> {{"sale4you.label.location" | translate}}</strong> - 500 gm</h6>
            </div>
            <div class="product-footer">
              <button type="button" class="btn btn-secondary btn-sm float-right" style="direction: rtl;"><i class="mdi mdi-cart-outline"></i> {{"sale4you.label.view" | translate}}</button>
              <p class="offer-price mb-0">$450.99 <i class="mdi mdi-tag-outline"></i><br><span class="regular-price">$800.99</span></p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
