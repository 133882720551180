import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LoadingMaskServiceService} from "../../loading-frame/loading-frame.component";
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";

import {LoginAction, LoginService} from "./login.service";
import {ValidationService} from "../../../shared/inputValidations/validation-service";
import {animate, keyframes, state, style, transition, trigger} from "@angular/animations";
import {delay} from "rxjs/operators";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";


interface UserLogin {
  username: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [
    trigger('popOverState',[
      state('show', style({
        opacity:1
      })),
      state('hide', style({
        opacity:1
      })),
      transition('show => hide', animate('500ms ease-in',     keyframes([
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
      ]))),
    ])
  ]
  // trigger('shakeit', [
  //   state('shakestart', style({
  //     transform: 'scale(1)',
  //   })),
  //   state('shakeend', style({
  //     transform: 'scale(1)',
  //   })),
  //   transition('* => shakeend', animate('5000ms ease-in')),
  // ])


})
export class LoginComponent implements OnInit, AfterViewInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  @ViewChild('loginModel') loginModel:ElementRef;

  passwordRegex= '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$';
  loginForm: any;
  registerForm: any;
  loginError: boolean = false;
  showAnimation: boolean = false;
  states:any = {};
  isDimmed!: boolean;
  constructor(
    private http: HttpClient,
    private apiKey: ApiUrl,
    private loadingMaskServiceService: LoadingMaskServiceService,
    private formBuilder: FormBuilder,
    private route: Router,
    private modalService: NgbModal,
    public el: ElementRef,
    private loginService:LoginService
  ) {
    this.states['state1'] = 'shakestart';
    this.states['state2'] = 'shakeend';

    this.loginForm = this.formBuilder.group({
      username: '',
      password: ''
    });
    this.registerForm = this.formBuilder.group({
      username: ['', [Validators.required, ValidationService.emailValidator]],
      password: ['',[Validators.required, ValidationService.passwordValidator]],
      first_name:['',[Validators.required]],
      last_name:['',[Validators.required]],
      confirmPassword:['',[Validators.required, ValidationService.passwordValidator]]
    })

    this.loginModel = el;
    this.loginService.getClickEvent().subscribe(res=>{
      if(res === LoginAction.Open){
        this.openLoginModal()
      }else {
        this.closeLoginModal()
      }

    })
  }
  show = true;
  get statusName(){
    return this.show?'show':'hide';
  }
  shakeMe(){
    this.show = false;
    setTimeout(()=>this.show = true, 500)
  }

  ngAfterViewInit() {
    this.apiKey.getUrlParam('loginOpenForm').subscribe((res:boolean)=>{
      if(res)
        this.loginService.sendClickEvent();
    })


  }

  ngOnInit(): void {

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  closeLoginModal(){
    this.modalService.dismissAll(this.loginModel)
  }
  openLoginModal(){
    this.modalService.open(this.loginModel).result.then((result) => {
    }, (reason) => {
      this.route.navigate([] )
       console.log(`Dismissed ${this.getDismissReason(reason)}`);
    });
  }
  onRegisterSubmit() {
    if (this.registerForm.dirty && this.registerForm.valid) {
      this.http.post<any>(this.apiKey.registerUser(), JSON.stringify(this.registerForm.value), this.options).subscribe(data => {
        console.log(data)
      })
    }else{
      console.log(this.registerForm.valid)
    }

  }
  onLoginSubmit() {

    this.http.post<any>(this.apiKey.loginUser(), JSON.stringify(this.loginForm.value), this.options).subscribe(data => {
      if(data['non_field_errors'] != null){
        this.shakeMe();
        this.loginError = true;
      }else{
        localStorage.setItem("token", data.token)
        localStorage.setItem("is_superuser", data['is_superuser']);
        localStorage.setItem("first_name", data['first_name']);
        localStorage.setItem("last_name", data['last_name']);
        localStorage.setItem("email", data['email']);
        localStorage.setItem("avatar", data['avatar']);

        this.loginService.sendClickEvent(LoginAction.Close)
      }

    },error => {
      this.shakeMe();
      this.loginError = true;
    })
  }


  onclick() {
    this.showAnimation = true;
  }
}
