import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StatusAds, UserAds} from "../../../../../models/user-ads";
import {ApiUrl} from "../../../../../shared/models/apiurl.models";
import {AdsService} from "../../services/ads.service";
import {AuthService} from "../../../../../shared/auth/auth.service";

@Component({
  selector: 'app-ads-controls',
  templateUrl: './ads-controls.component.html',
  styleUrls: ['./ads-controls.component.scss']
})
export class AdsControlsComponent implements OnInit {
  @Input() adsItem: UserAds;
  @Output() adsItemChange = new EventEmitter<UserAds>();


  deleteDisabledToolTip: string = "";
  resumeDisabledToolTip: string = "";
  playDisabledToolTip: string = "";
  editeDisabledToolTip: string = "";
  payDisabledToolTip: string = "";

  constructor(private apiUrl: ApiUrl, public adsService:AdsService, private auth: AuthService) { }

  ngOnInit(): void {
  }


  deleteAds() {
    const payload = {
      resourcetype: this.adsItem.resourcetype,
      statusAds: 'Delete'
    };
    this.adsService.updateAds(this.adsItem.id, payload).subscribe((res:any)=>{
      this.adsItem = res;
      this.adsItemChange.emit(this.adsItem);
    })
  }


  playAds(){
    const payload = {
      resourcetype: this.adsItem.resourcetype,
      statusAds: 'Active',
      active: true
    };
    this.adsService.updateAds(this.adsItem.id, payload).subscribe((res:any)=>{
      this.adsItem = res;
      this.adsItemChange.emit(this.adsItem);
    })
  }

  pauseAds() {
    const payload = {
      resourcetype: this.adsItem.resourcetype,
      statusAds: 'Pause'
    };
    this.adsService.updateAds(this.adsItem.id, payload).subscribe((res:any)=>{
      this.adsItem = res;
      this.adsItemChange.emit(this.adsItem);
    })
  }

  editAds() {

  }

  doneAds() {
    const payload = {
      resourcetype: this.adsItem.resourcetype,
      statusAds: 'Done'
    };
    this.adsService.updateAds(this.adsItem.id, payload).subscribe((res:any)=>{
      this.adsItem = res;
      this.adsItemChange.emit(this.adsItem);
    })
  }

  protected readonly StatusAds = StatusAds;

  get deleteDisabled():boolean {
    if(this.adsItem.statusAds == StatusAds.Done){ this.deleteDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false;
  };
  get blockDisabled(): boolean{
    if(this.adsItem.statusAds == StatusAds.Done){ this.deleteDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false;
  }
  get approveDisabled():boolean{
    if(this.adsItem.statusAds == StatusAds.inReview){ return false}
    return true;
  }
  get playDisabled():boolean {
    if(this.adsItem.statusAds == StatusAds.Done){ this.deleteDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false
  }
  get resumeDisabled():boolean {
    if(this.adsItem.statusAds == StatusAds.Blocked){ this.resumeDisabledToolTip="המודעה חסומה ממנהל האתר"; return true}
    if(this.adsItem.statusAds == StatusAds.Delete){ this.resumeDisabledToolTip="המודעה נמחקה"; return true}
    if(this.adsItem.statusAds == StatusAds.Done){ this.resumeDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false
  }
  get editeDisabled():boolean {
    if(this.adsItem.statusAds == StatusAds.Delete){ this.editeDisabledToolTip="המודעה נמחקה"; return true}
    if(this.adsItem.statusAds == StatusAds.Done){ this.editeDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false
  }
  get payDisabled():boolean {
    if(this.adsItem.statusAds == StatusAds.Delete){ this.payDisabledToolTip="המודעה נמחקה"; return true}
    if(this.adsItem.statusAds == StatusAds.Done){ this.payDisabledToolTip="המודעה שלך סומן כנמכר\פג תוקף"; return true}
    return false
  }

  get isAdminUser(){
    return this.auth.isSuperUser();
  }

  blockAds() {
    const payload = {
      resourcetype: this.adsItem.resourcetype,
      statusAds: 'Blocked'
    };
    this.adsService.updateAds(this.adsItem.id, payload).subscribe((res:any)=>{
      this.adsItem = res;
      this.adsItemChange.emit(this.adsItem);
    })
  }
}
