import { Component, OnInit } from '@angular/core';
import {PriceViewService} from "./price-view.service";

@Component({
  selector: 'app-price-view',
  templateUrl: './price-view.component.html',
  styleUrls: ['./price-view.component.scss']
})
export class PriceViewComponent implements OnInit {

  constructor(public priceViewService: PriceViewService) { }

  ngOnInit(): void {
  }

  toggle() {
    this.priceViewService.viewPrice = !this.priceViewService.viewPrice;
  }
}
