import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss']
})
export class DropdownFieldComponent implements OnInit {

  @Input() question: QuestionBase<string>;
  @Input() value: string;
  constructor() { }

  ngOnInit(): void {
  }

}
