import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FilterBase} from "../../../../../models/filter-base";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, Subject, throwError} from "rxjs";
import {ApiUrl} from "../../../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../../../components/loading-frame/loading-frame.component";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";
import {catchError, map} from "rxjs/operators";
import {SystemApp} from "../../../../../constans/SystemConfigurations";
import {SelectOption} from "../select-field/select-field.component";

@Component({
  selector: 'app-range-select',
  templateUrl: './range-select.component.html',
  styleUrls: ['./range-select.component.css']
})
export class RangeSelectComponent implements OnInit {

  @Input() filterBase!: FilterBase;
  filterBaseFrom!: FilterBase;
  filterBaseTo!:FilterBase;


  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService, private filterBaseService: FilterbaseService) {}

  setValue(dir:string, value: any):void{
    const key = value.name;
    const questionIsExist = this.filterBaseService.getQuestion(key)
    if(questionIsExist == undefined){
      const object = {[dir]:value,range:true};
      this.filterBaseService.addQuestion(key, object);
    }else{
      questionIsExist[dir] = value
      this.filterBaseService.addQuestion(key, questionIsExist);
    }
  }

  ngOnInit(): void {
    this.filterBaseFrom = this.filterBase;
    this.filterBaseTo = this.filterBase;
  }

}
