import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {QuestionControlService} from "../services/question-control.service";
import {QuestionBase, Section} from "../models/question-base";
import {AdvFormService} from "../services/adv-form.service";
import {empty} from "rxjs";
import {Router} from "@angular/router";
import {sectionFunction} from "./shared/sectionFunction";
import {TranslateService} from "@ngx-translate/core";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {PathOptions, selectOptionData} from "../dynamic-form-question/dynamic-form-question.component";
import {HttpClient} from "@angular/common/http";
import {MatInput} from "@angular/material/input";


export interface SectionUI {
    description: string;
    number: number;
    namespace: string;
    expanded: boolean;
    overflow: boolean;
    callBackFunction: string;
    questions: QuestionBase<String>[];
    requiredQuestion: QuestionBase<string> [];
}

@Component({
    selector: 'app-dynamic-form',
    styleUrls: ['./dynamic-form.component.css'],
    templateUrl: './dynamic-form.component.html',
    providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

    @Input() questions: QuestionBase<string>[] | null = [];
    form!: FormGroup;
    payLoad = '';
    sections!: SectionUI[];
    sectionMissingFlg: boolean[] = [];
    sectionDescription: string[] = [];
    greenPass: boolean = false;
    @Input() expandFlag: boolean = false;

    constructor(private qcs: QuestionControlService, private formService: AdvFormService, private router: Router, private translateService: TranslateService, private apiUrl: ApiUrl, private http: HttpClient) {
        const mainCategory = this.formService.getQuestion('mainCategory')
        if (mainCategory == undefined)
            this.router.navigate(['/publish']);

        // this.formService.getClickEvent().subscribe(res => {})

        this.formService.getQOClickEvent().subscribe(res=>{
            this.setMissingRequiredData();
            this.setTabDescription();
            if(['images', 'userContactDetails','armatureList'].indexOf(res.index) ==-1 ){
                this.closeTabIfCompleted();
            }
            this.greenPass = this.setGreenPass();
        })
    }


    initBindSelect() {
        this.questions.filter(ques => ques.type === "bindSelect").forEach(qs => {
            const quesParam = this.apiUrl.pathTranslate(qs.callBackFunction, this.formService);
            let isDisplay = true;
            console.log(qs)
            quesParam.requiredField.forEach(res => {
                if (res.value != '' && res.value != null) {
                    isDisplay = false;
                }
            })
            if (!isDisplay) {
                this.http.get<selectOptionData[]>(quesParam.path).subscribe((res: selectOptionData[]) => {
                        if (res != null && res.length > 0) {
                            qs.bindSelectHide = !isDisplay;
                            qs.required = isDisplay;
                        } else {
                            qs.bindSelectHide = isDisplay;
                            qs.required = !isDisplay;
                        }
                    }
                )
            }else{
                qs.bindSelectHide = !isDisplay;
                qs.required = isDisplay;
            }

        })
    }

    setGreenPass() {
        this.initBindSelect();
        let flg = true;
        this.sectionMissingFlg.forEach(res => {
            if (typeof res == "boolean") {
                if (res == true) {
                    flg = false;
                }
            }
        });
        return flg;
    }

    setSectionQuestions(questions: QuestionBase<string>[] | null): SectionUI[] {
        let section: SectionUI[] = [] || null;
        let SectionMap = new Map();
        questions?.forEach((res: QuestionBase<string>) => {
            // @ts-ignore
            var qS: SectionUI = res.section
            if (SectionMap.get(qS.number) == undefined) {
                SectionMap.set(qS.number, {
                    description: qS.description,
                    number: qS.number,
                    namespace: qS.namespace,
                    expanded: qS.expanded,
                    questions: [],
                    requiredQuestion: [],
                    callBackFunction: qS.callBackFunction
                })
            }
            SectionMap.get(qS.number).questions.push(res);
            if (res.required === true) {
                SectionMap.get(qS.number).requiredQuestion.push(res);
            }
        })
        SectionMap.forEach((value, index) => {
            section.push(value);
        })
        return section;
    }

    private getDescriptionTab(section: SectionUI) {
        const selectedValue = section.questions.map((q: any) => this.formService.getQuestion(q.key))
        if (section.callBackFunction != null && Object.keys(sectionFunction).indexOf(section.callBackFunction) != -1) {
            return sectionFunction[section.callBackFunction](selectedValue, this.translateService);
        } else {
            return sectionFunction['generalFunction'](selectedValue, this.translateService);
        }
    }

    scrollToTop() {
        const target = document.getElementById("formBoxId");
        target?.scrollIntoView({behavior: 'smooth'});
    }

    closeTabIfCompleted() {
        let openedSections = this.sections.find(sec => sec.expanded)
        let completed = true;
        let monitor: any[] = []
        if (openedSections && openedSections?.questions.length > 0) {
            openedSections?.questions.forEach(q => {
                monitor[q['key']] = this.formService.getQuestion(q['key'])
            })

        }
        if (Object.values(monitor).indexOf(undefined) == -1) {
            completed = false
        }
        if (openedSections && !completed) {
            openedSections.expanded = completed;
            this.scrollToTop()
        }
    }

    setTabDescription() {
        this.sections.forEach(sec => {
            if (!this.sectionMissingFlg[sec.number]) {
                this.sectionDescription[sec.number] = this.getDescriptionTab(sec);
            } else {
                this.sectionDescription[sec.number] = ""
            }

        })
    }

    setMissingRequiredData() {
        this.sections.forEach(sec => {
            this.sectionMissingFlg[sec.number] = false
            if (sec.requiredQuestion.length == 0) {
                this.sectionMissingFlg[sec.number] = false

            } else {
                sec.requiredQuestion.forEach(rQ => {
                    if (this.formService.getQuestion(rQ.key) === undefined) {
                        this.sectionMissingFlg[sec.number] = true;
                    }
                })
            }
        })

    }

    ngOnInit() {
        this.sections = this.setSectionQuestions(this.questions).sort(function (a, b) {
            return a.number === b.number ? 0 : a.number < b.number ? -1 : 1;
        });
        ;

        this.setMissingRequiredData();
        this.form = this.qcs.toFormGroup(this.questions as QuestionBase<string>[]);
    }

    onSubmit() {
        this.payLoad = JSON.stringify(this.form.getRawValue());
    }

    getQuestions(stage: number) {
        return this.questions?.filter(res => res.page === stage)
    }

    openTab($event: any) {
        this.expandFlag = false;
    }

    expandWindow(section: any) {
        this.expandFlag = true;
        setTimeout(() => this.expandFlag = false, 250)
        this.sections.map((res: SectionUI) => {
            if (section == res) {
                res.expanded = true
            } else {
                res.expanded = false
            }
        })
    }

    submitAdv() {

    }

    onToggle() {

    }

    onClickPanel(panel: any) {
        setTimeout(()=> {
            const inputs = Array.from(panel._body.nativeElement.getElementsByClassName('input-field-focus'))
            const emptyInputs = inputs.filter((input: HTMLInputElement) => !input.value);
            const hasFocusedInput = emptyInputs.some((input: HTMLInputElement) => input === document.activeElement);

            if(hasFocusedInput){
                return;
            }
            if (emptyInputs.length > 0) {
                // @ts-ignore
                emptyInputs[0].focus();
                // @ts-ignore
                inputElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

            }
        },250);
    }
}
