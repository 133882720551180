import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css'],

})
export class PersonalComponent implements OnInit {
  public href: string = "10.0.0.22:8000/user/user_ads/";
  constructor(private router: Router,public translate:TranslateService) {}

  ngOnInit(): void {
    this.href = this.router.url;
  }

}
