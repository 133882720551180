import { Component, OnInit } from '@angular/core';
import {QuestionBase} from "../models/question-base";
import {Observable} from "rxjs";
import {QuestionService} from "../services/question.service";
import {ApiUrl} from "../../../shared/models/apiurl.models";

@Component({
  selector: 'app-car-adv-form',
  templateUrl: './car-adv-form.component.html',
  styleUrls: ['./car-adv-form.component.css'],
  providers:  [QuestionService]
})
export class CarAdvFormComponent implements OnInit {

  questions$!: Observable<QuestionBase<any>[]>;

  constructor(service: QuestionService,private apiUrl: ApiUrl) {
    const namespace = this.apiUrl.getNameSpace()
    service.getServerQuestions(namespace).subscribe((res)=>{
      this.questions$ = res;
    })


  }

  ngOnInit(): void {
  }

}
