import {Injectable} from "@angular/core";
import {SystemApp} from "../../constans/SystemConfigurations";
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Color, Size} from "../../app.models";
import {AdvFormService} from "../../pages/create-adv/services/adv-form.service";
import {PathOptions} from "../../pages/create-adv/dynamic-form-question/dynamic-form-question.component";
import {FilterbaseService} from "../../pages/shop/top-filter/filter-base/services/filterbase.service";
import {DynamicFormDialogService} from "../../admin/components/dynamic-form-dialog/dynamic-form-dialog.service";
import {QuestionBase} from "../../pages/create-adv/models/question-base";

@Injectable()

export class ApiUrl{

  constructor(private router: Router, private activeRouter: ActivatedRoute, public http:HttpClient) {}

  private apikTenant = this.getCookie('apiKey')
  private externalURL = this.getCookie('external_apiUrl')

  public getJWTToken(){
    if(localStorage.getItem('token')){
      const token = 'Token '+ (localStorage.getItem('token') !=null ?localStorage.getItem('token')+"":'');
      return token;
    }
    return "";
  }

  public getCookie(cname:string): string{
    const name = cname + "="
    const ca = document.cookie.split(';')
    for(let i = 0; i < ca.length; i++){
      let c = ca[i];
      while (c.charAt(0) == ' '){
        c = c.substring(1);
      }
      if(c.indexOf(name) == 0){
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  getSliders(): string {
    return SystemApp.base_url_api + '/api/slider/'
  }

  getColor(): string {
    return SystemApp.base_url_api + '/api/colors/'
  }
  getSize(): string {
    return SystemApp.base_url_api + '/api/sizes/'
  }



  getProducts(mainCategory:string, subCategory:string|null = null): string{
    let url = SystemApp.base_url_api + '/clientAjax/product/?mainCategory=' + mainCategory;
    if(subCategory != null){
      url += "&subCategory="+subCategory;
    }
    return url;
  }

  getPages(): string{
    return SystemApp.base_url_api + '/pages/'
  }

  getCategories(): string {
    return SystemApp.base_url_api + '/api/mainCategory/'
  }

  getBanners(): string {
    return SystemApp.base_url_api + '/api/banners/'
  }



  getProduct(productId:string): string{
    return SystemApp.base_url_api + '/clientAjax/product/' + productId + '/';
  }
  getAdminAdvs(): string{
    return SystemApp.base_url_api + '/myAdmin/advs/';
  }
  getAdminStores(): string {
    return SystemApp.base_url_api + '/myAdmin/store/';
  }
  getStoreByUserToken(): string {
    return SystemApp.base_url_api + '/store/store_info/';
  }

  getStores(): string {
    return SystemApp.base_url_api + '/api/store/';
  }

  loginUser(): string{
    return SystemApp.base_url_api + '/api-token-auth/'
  }

  getCategory(): string {
    return SystemApp.base_url_api + '/api/mainCategory/';
  }

  getBrands(): string {
    return SystemApp.base_url_api + '/api/brands/';
  }
  getOrder(): string {
    return SystemApp.base_url_api + '/api/orders/';
  }
  getUserDetails(): string {
    return SystemApp.base_url_api + '/api/users/';
  }
  getAllUsers(): string{
    return SystemApp.base_url_api + '/admin-api/users/'
  }
  getBanner(): string {
    return SystemApp.base_url_api + '/admin-api/banners/';
  }
  getSlider(): string {
    return SystemApp.base_url_api + '/api/slider/';
  }
  getVideo(): string {
    return SystemApp.base_url_api + '/api/video/';
  }

  public getUrlParam(params: string): Observable<any>{
    const a = new Observable(observer => {
      this.activeRouter.queryParams.subscribe(res =>{
        observer.next(res[params])
      });
    })
    return a;
  }


  registerUser(): string{
    return SystemApp.base_url_api + '/api/users/'
  }

  getTopcategory(): string{
    return SystemApp.base_url_api + '/ads/topcategory'
  }

  getCarouselSlider(): string{
    return SystemApp.base_url_api + '/clientAjax/carouselSlider'
  }

  getTopProducts(): string{
    return SystemApp.base_url_api + '/clientAjax/top6products'
  }

  getUserInfo(): string{
    return SystemApp.base_url_api + '/user/user_info/'
  }

  getAds(): string{
    return SystemApp.base_url_api + '/user/user_ads/'
  }

  public getNameSpacePos(delimiter:string, position:number){
    const url = this.router.url
    return url.$piece(delimiter,position)
  }

  public pathTranslate(pathUrl: string, formService: AdvFormService | FilterbaseService | DynamicFormDialogService, requiredParam: string='', questionObj: QuestionBase<any> = null):PathOptions {
    pathUrl = pathUrl.trim();
    const orginalPath = SystemApp.base_url_api +'/'+ pathUrl.$piece("*",1)
    //fetch id parameters
    const absolutePath = orginalPath.$piece("?",1)
    const uri = orginalPath.$piece("?",2);
    const parmasListArr:Array<{index: string, value: string}>=[];
    let fullPath = absolutePath
    if(uri != undefined){
      fullPath = fullPath + "?"
      const parmasList = uri.split("&")
      parmasList.forEach(res => {
        const index=res.$piece("=",1),value = formService.getQuestion(res.$piece("=",2));
        const question  = formService.getQuestion(res.$piece("=",2))
        let dataQueVlue ="";
        if(question != undefined){
          if(questionObj?.parentMapValue != null){
            dataQueVlue = formService.getQuestion(res.$piece("=",2))[questionObj.parentMapValue.toString()];
          }else{
            dataQueVlue =  formService.getQuestion(res.$piece("=",2)).value?.value;
          }
        }
        parmasListArr.push({index: res.$piece("=",1), value: dataQueVlue})
        fullPath += index + "="+ dataQueVlue+"&"
      })
    }
    const fieldToDisplay = pathUrl.$piece("*",2)
    if(requiredParam != ''){
      const num = requiredParam.$len(",")
      let uri = ""
      for(let i=1;i<=num+1;i++){
        uri = uri+requiredParam.$piece(",", i);
      }
      if(fullPath.includes("?")){
        fullPath = fullPath + uri
      }else{
        fullPath = fullPath + "?" + uri
      }
    }
    return { path: fullPath, displayField:fieldToDisplay, requiredField:parmasListArr, absoluteUrl: absolutePath}
  }

  public getNameSpace(): string{
    const url = this.router.url
    return url.$piece('/publish/',2)
  }

  getFormsQuestion(namespance: string): string{
    return SystemApp.base_url_api + '/dynamicForm/?namespace='+namespance
  }

  getFormsQuestionUrl(): string{
    return SystemApp.base_url_api + '/clientAjax/adv/publish/'
  }
  getMainCategory(): string{
    return SystemApp.base_url_api + '/clientAjax/mainCategory'
  }
  getSubCategory(nameSpace: string): string{
    return SystemApp.base_url_api + '/clientAjax/subCategory?namespace='+nameSpace;
  }
  getLicenceCarUrl(){
    return SystemApp.base_url_api + '/api/Archive/car/GetCarDescription'
  }
  getCarDescription(producer: string, mainModel: string, year: String): string{
    return SystemApp.base_url_api + '/api/Archive/car/GetCarDescription?codeProducer='+producer+'&mainModel='+mainModel+'&year='+year;
  }
  getBrowserSearch(query: string) {
    return SystemApp.base_url_api + '/clientAjax/browserSearch?q='+query
  }

  getAdvertisement(num: number): string{
    return SystemApp.base_url_api + '/adv/generate/?num='+num;
  }


  getPhoneValidationUrl(): string{
    return SystemApp.base_url_api + '/clientAjax/adv/phone/validation/'
  }

  getFilterBase(namespace: string): string{
    return SystemApp.base_url_api + '/dynamicFilterForms/?namespace=' + namespace;
    // return 'http://localhost:8000/dynamicFilterForms/?namespace=' + namespace;
  }
  private getValue(){

  }



  /*
  translateData(object:any, value:string): string{
    const delimiterNum = value.$len("*")
    if(delimiterNum == 0 ){
      const strIndex = value.$piece("*",1)
      return object !=null ? object[strIndex]: '';
    }else{
      const strIndex = value.$piece("*",1)
      const valueSelect = value.$piece("*",2)
      for(var i=1; i<=delimiterNum+1 ;i++){
        if(object == null)
          break

        if(valueSelect == 'label'){
          object = object[value.$piece("*", 1)]['label']
          return object !=null? object : '';
        }else if(valueSelect == 'value'){
          const selectedValue = Object.getOwnPropertyDescriptor(object,strIndex)?.value
          object = selectedValue?.['value']?.['value']
          return object !=null? object : '';
        }else{
          object = object[value.$piece("*", i)]
          return object !=null? object : '';
        }

      }
      return object !=null? object : '';
    }
  }
  */
  translateData(object:any, value:string): string{
    const delimiterNum = value.$len("*")
    if(delimiterNum == 0 ){
      const strIndex = value.$piece("*",1)
      return object !=null ? object[strIndex]: null;
    }else{
      for(var i=1; i<=delimiterNum+1 ;i++){
        if(object == null)
          break
        object = object[value.$piece("*", i)]
      }
      return object !=null && object !='' ? object : null;
    }
  }

  getCarFilters(params: any ,arrPram: any){
    arrPram['CodeProducer'] = this.translateData(params, 'codeProducer*label')
    arrPram['MainModel'] = this.translateData(params, 'mainModel*label')
    arrPram['min_year'] = this.translateData(params, 'year*from*label')
    arrPram['max_year'] = this.translateData(params, 'year*to*label')
    arrPram['min_price'] = this.translateData(params, 'price*from')
    arrPram['max_price'] = this.translateData(params, 'price*to')
    arrPram['min_NumberOfOwner'] = this.translateData(params, 'numberOnwer*from*label')
    arrPram['max_NumberOfOwner'] = this.translateData(params, 'numberOnwer*to*label')
    arrPram['gearBox'] = this.translateData(params, 'gearBox*value*value')
    arrPram['min_mileage'] = this.translateData(params, 'mileage*from*label')
    arrPram['max_mileage'] = this.translateData(params, 'mileage*to*label')
    arrPram['CurrentOwner'] = this.translateData(params, 'CurrentOwner*value*value')
    arrPram['Color'] = this.translateData(params, 'color*value*value')
    arrPram['min_engineCapacity'] = this.translateData(params,'engineCapacity*from*label')
    arrPram['max_engineCapacity'] = this.translateData(params,'engineCapacity*to*label')
    return arrPram;
  }

  getNadlanFilters(params: any ,arrPram: any){
    arrPram['entrance'] = this.translateData(params, 'entrance')
    arrPram['sconderyCategory'] = this.translateData(params, 'sconderyCategory*label')
    arrPram['nadlanType'] = this.translateData(params, 'nadlanType*label')
    arrPram['availabilityEntrance'] = this.translateData(params, 'availabilityEntrance')
    arrPram['armatureList'] = this.translateData(params, 'armatureList')
    arrPram['min_Floor'] = this.translateData(params, 'Floor*from*label')
    arrPram['max_Floor'] = this.translateData(params, 'Floor*to*label')
    arrPram['min_numberOfRooms'] = this.translateData(params, 'numberOfRooms*from*label')
    arrPram['max_numberOfRooms'] = this.translateData(params, 'numberOfRooms*to*label')
    arrPram['min_buildingMeter'] = this.translateData(params, 'buildingMeter*from')
    arrPram['max_buildingMeter'] = this.translateData(params, 'buildingMeter*to')
    arrPram['min_price'] = this.translateData(params, 'price*from')
    arrPram['City'] = this.translateData(params, 'City*value*value')
    arrPram['max_price'] = this.translateData(params, 'price*to')
    return arrPram;
  }


  getSale2Filters(params: any ,arrPram: any){
    arrPram['Sale2ThirdCategory_degem'] = this.translateData(params, 'Sale2ThirdCategory_degem*label')
    arrPram['sale2ThirdCategory_thirdCategory'] = this.translateData(params, 'sale2ThirdCategory_thirdCategory*label')
    arrPram['subCategory'] = this.translateData(params, 'subCategory*value*value')
    arrPram['sconderyCategory'] = this.translateData(params, 'sconderyCategory*value*value')
    return arrPram;
  }
  // getFilterBaseUrl(params:any): string{
  //   var arrPram:any = {}
  //
  //   if(params['mainCategory'].toLowerCase() == 'Car'.toLowerCase()){
  //     arrPram = this.getCarFilters(params, arrPram);
  //   }
  //
  //   if(params['mainCategory'].toLowerCase() == 'Nadlan'.toLowerCase()){
  //     arrPram = this.getNadlanFilters(params, arrPram);
  //   }
  //
  //   if(params['mainCategory'].toLowerCase() == 'Sale2'.toLowerCase()){
  //     arrPram = this.getSale2Filters(params, arrPram);
  //   }
  //
  //
  //   const paramArr = Object.keys(arrPram)
  //   let url = "";
  //   paramArr.forEach((key)=>{
  //     url=url+key+"="+arrPram[key]+"&"
  //   })
  //   return SystemApp.base_url_api + '/clientAjax/shop/search/'+params['mainCategory']+'?'+url
  // }

  getFilterBasePostUrl(mainCategory, limit, offset){
    return SystemApp.base_url_api + `/clientAjax/productVFilteriewSet?limit=${limit}&offset=${offset}&model_class=${mainCategory}`
  }
  getAccessories(mainCategoryId: string): string{
    return SystemApp.base_url_api + '/tables/GetArmatureList/?MainCategory=' + mainCategoryId;
  }


  buildPayload(mainCategory, subCategory, arrJson){
    var arrPram:any = {}

    if(arrJson['mainCategory'].toLowerCase() == 'Car'.toLowerCase()){
      arrPram = this.getCarFilters(arrJson, arrPram);
    }

    if(arrJson['mainCategory'].toLowerCase() == 'Nadlan'.toLowerCase()){
      arrPram = this.getNadlanFilters(arrJson, arrPram);
    }

    if(arrJson['mainCategory'].toLowerCase() == 'Sale2'.toLowerCase()){
      arrPram = this.getSale2Filters(arrJson, arrPram);
    }
    return arrPram
  }

  getAdsUrl() {
    return SystemApp.base_url_api + "/clientAjax/productAds/"
  }

  getFixedUrl(url: string):string{
    return SystemApp.base_url_api + '/' + url.replace(/^\/+|\/+$/g, '') + '/';
  }
  getArmatureUrl(){
    return SystemApp.base_url_api + '/admin-api/armatures/'
  }

  generateZCreditSessionUrl() {
  return "https://pci.zcredit.co.il/webcheckout/api/WebCheckout/CreateSession";
  // return SystemApp.base_url_api+ "/clientAjax/zCreditSession/";
  }

  getAdsRouteUrl() {
    return SystemApp.base_url_api + '/admin-api/ads-route/'
  }

  getOrdersUrl() {
    return SystemApp.base_url_api + '/admin-api/orders/'
  }

  getClientAdsRouteUrl() {
    return SystemApp.base_url_api + '/client-api/ads-routes/'
  }

  checkoutAdv(): string {
    return SystemApp.base_url_api + '/client-api/check-out/';
  }
  getLableLanguage() {
    return SystemApp.base_url_api + '/admin-api/label-translate/'
  }
  getDGMCar() {
    return SystemApp.base_url_api + '/admin-api/label-translate/'
  }
  getTriggerAsyncTask(){
    return SystemApp.base_url_api + `/async-task/taskmetadata/latest`
  }
}
