import {Component, Input, OnInit} from '@angular/core';
import {QuestionBase} from "../../../../create-adv/models/question-base";

@Component({
  selector: 'app-multi-select-box-ads',
  templateUrl: './multi-select-box-ads.component.html',
  styleUrls: ['./multi-select-box-ads.component.scss']
})
export class MultiSelectBoxAdsComponent implements OnInit {
  @Input() question: QuestionBase<string>;
  @Input() value: {
      "id": number,
      "ArmatureDesc": string,
      "ArmatureIcon": string,
      "MainCategory": number,
      "SocondryCategory": number
    };
  constructor() { }

  ngOnInit(): void {
    console.log(this.value)
  }

}
