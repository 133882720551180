import {Injectable} from "@angular/core";
import {ApiUrl} from "../shared/models/apiurl.models";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {CarouselSlider} from "../models/carousel-slider";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class CarouselSliderService{

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey:ApiUrl) {
  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }

  getCarouselSlider(): Observable<any>{
    return this.http.get(this.apiKey.getCarouselSlider(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
    );
  }
}
