import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UsersService} from "../../admin/users/users.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private userService: UsersService, private router: Router) { }


  IsAdminLogin():Promise<boolean>{
    return new Promise((resolve, reject) => {
      if(!this.IsLoggedIn()){
        this.router.navigate(['/'],{ queryParams: {loginOpenForm:true } });
          resolve(false)
      }else{
        this.userService.getUserDetails().subscribe(res=>{
          res = res['results'][0];
          if(res['is_superuser']){
            localStorage.setItem("is_superuser", res['is_superuser']);
            resolve(res['is_superuser'])
          }else{
            this.router.navigate(['/'],{ queryParams: { } }).then(()=>{
              window.location.reload();
            });
          }
        })
      }
    })
  }

  isSuperUser(): boolean{
    return localStorage.getItem('is_superuser')=='true' ? true : false;
  }
  IsLoggedIn(){
    return !!localStorage.getItem('token')
  }
}
