import {Component, Input, OnInit} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {AdvFormService} from "../services/adv-form.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {adsRoute, CheckoutRequestData} from "../../../app.models";
import {response} from "express";
import {Observable} from "rxjs";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {MatDialog} from "@angular/material/dialog";
import {CreditCardComponent} from "./credit-card/credit-card.component";


export enum RouteType{
  LEVEL1="LEVEL1",
  LEVEL2="LEVEL2",
  LEVEL3="LEVEL3"
}

@Component({
  selector: 'app-select-route',
  templateUrl: './select-route.component.html',
  styleUrls: ['./select-route.component.css']
})
export class SelectRouteComponent implements OnInit {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiUrl.getJWTToken()
    })
  }
  closeResult = '';
  @Input()
  greenPass: boolean = false;

  selectedRoute!: RouteType;
  dynamicMessage: string = "ניצלת את חבילת הפרסום - יש לבחור מסלול אחר";
  adsDescription(adsDescription: string){
    return adsDescription.split("\r");
  };
  constructor(private modalService: MatDialog, public AdvFormService: AdvFormService, private http: HttpClient, private apiUrl:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService, public advFormService: AdvFormService) { }

  ngOnInit(): void {

  }
  publishAdv():Observable<any>{
    return this.AdvFormService.publishData();
  }

  buildCheckoutRequestData(): any{

    return {
      "Key": "f6f86b77a4ff4d9254253cfa7eb854d3462ad4c6d4f2a0c6cd564dc40e2cb68a",
      "Local": "He",
      "UniqueId": "",
      "SuccessUrl": "",
      "CancelUrl": "",
      "CallbackUrl": "",
      "PaymentType": "regular",
      "CreateInvoice": "false",
      "AdditionalText": "",
      "ShowCart": "true",
      "ThemeColor": "005ebb",
      "BitButtonEnabled": "true",
      "ApplePayButtonEnabled": "true",
      "GooglePayButtonEnabled": "true",
      "Installments": {
        "Type": "regular" ,
        "MinQuantity": "1",
        "MaxQuantity": "12"
      },
      "Customer": {
        "Email": "someone@gmail.com",
        "Name": "Demo Client" ,
        "PhoneNumber":  "077-3233190",
        "Attributes": {
          "HolderId":  "none" ,
          "Name":  "required" ,
          "PhoneNumber":  "required" ,
          "Email":  "optional"
        }
      },
      "CartItems": [{
        "Amount": "10.20",
        "Currency": "ILS",
        "Name": "My Item1 Name",
        "Description": "My Item description , comes below the name" ,
        "Quantity": 2 ,
        "Image": "https://www.z-credit.com/site/wp-content/themes/z-credit/img/decisions/decision2.png" ,
        "IsTaxFree":  "false",
        "AdjustAmount": "false"
      },
        {
          "Amount": "2",
          "Currency": "ILS",
          "Name": "My Item2 Name",
          "Description": "My Item description , comes below the name" ,
          "Quantity": 1 ,
          "Image": "" ,
          "IsTaxFree":  "false",
          "AdjustAmount": "false"
        }],
      "FocusType": "None",
      "CardsIcons": {
        "ShowVisaIcon": "true",
        "ShowMastercardIcon": "true",
        "ShowDinersIcon": "true",
        "ShowAmericanExpressIcon": "true",
        "ShowIsracardIcon": "true",
      },
      "IssuerWhiteList": [1,2,3,4,5,6],
      "BrandWhiteList": [1,2,3,4,5,6],
      "UseLightMode": "false",
      "UseCustomCSS": "false",
      "BackgroundColor": "FFFFFF",
      "ShowTotalSumInPayButton": "true",
      "ForceCaptcha": "false"
    }
  }

  generateZCreditSession(): Observable<any>{
    const payload = this.buildCheckoutRequestData();
    return this.http.post(this.apiUrl.generateZCreditSessionUrl(), payload, this.options);
  };
  openCreditWindow(): void{
    this.generateZCreditSession().subscribe(zCreditUrl=>{
      const popupUrl = zCreditUrl.Data.SessionUrl;
      // Specify the features of the popup window (e.g., width, height)
      const popupFeatures = 'width=600,height=400';

      // Open the popup window
      const popupWindow = window.open(popupUrl, '_blank', popupFeatures);

      // Focus on the new window (optional)
      if (popupWindow) {
        popupWindow.focus();
      }
    });
  }
  selectRoute(dialogList: {freeAdv:any, creditCard:any}, routeType: adsRoute) {
    this.advFormService.addQuestion("adsRoute",routeType.level)
    const selectedRoute = this.advFormService.adsRoutes.find(ads=>ads.level == routeType.level)
    if(routeType.level === RouteType.LEVEL1.toString()){

      this.publishAdv().subscribe(res=>{
        this.open(dialogList.freeAdv)
        this.loadingMaskServiceService.setOnLoadingMask(false, false);
      });
    }else if(routeType.level === RouteType.LEVEL2.toString()){

      this.publishAdv().subscribe(res=>{
        this.modalService.open(CreditCardComponent, {data: {adsRoute: selectedRoute, adsId: res['id']}})
      })

    }else if(routeType.level === RouteType.LEVEL3.toString()){
      this.publishAdv().subscribe(res=> {
        this.modalService.open(CreditCardComponent, {data: {adsRoute: selectedRoute, adsId: res['id']}})
      })
    }

  }

  open(content: any, zCreditSessionUrl=null) {
    if(content == "")
    this.modalService.open(content, {data: {zCreditSessionUrl: zCreditSessionUrl}})
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
