<div *ngIf="categories" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">
        <div *ngFor="let category of categories" class="swiper-slide">
            <mat-card class="product-item myProductItem">
<!--                <mat-chip-list *ngIf="product.discount">-->
<!--                    <mat-chip color="warn" selected="true">{{product.discount}}% OFF</mat-chip>-->
<!--                </mat-chip-list>-->
                <a [routerLink]="['/categories', category.id, category.name.replace(' ','-')]" class="image-link">
                    <img *ngIf="categories" style="height: 250px; object-fit: cover;" [src]="category.image" [attr.data-src]="category.image" class="swiper-lazy"/>
                </a>
<!--                <h4 class="category text-muted">{{ category?.description }}</h4>-->
                <a [routerLink]="['/categories', category.id, category.name.replace(' ','-')]" class="title text-truncate ">
                    <span class="titleCategory">{{category.name}}</span>
                </a>
            </mat-card>
        </div>
    </div>
    <button mat-mini-fab class="swiper-button-prev swipe-arrow"><mat-icon>keyboard_arrow_left</mat-icon></button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow"><mat-icon>keyboard_arrow_right</mat-icon></button>
</div>
