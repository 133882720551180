import {Injectable} from "@angular/core";
import {ApiUrl} from "../shared/models/apiurl.models";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Router} from "@angular/router";

@Injectable()
export class AdminService {

    constructor(private apiUrl: ApiUrl,public http:HttpClient, public snackBar: MatSnackBar, public router:Router) { }

    signOut() {

        localStorage.removeItem("token");
        localStorage.removeItem("is_superuser");
        localStorage.removeItem("token")
        localStorage.removeItem("is_superuser");
        localStorage.removeItem("first_name");
        localStorage.removeItem("last_name");
        localStorage.removeItem("email");
        localStorage.removeItem("avatar");
        localStorage.removeItem("userDetails");
        this.router.navigate(['/sign-in'], ).then(()=>{
            window.location.reload();
        });
    }
}
