<ng-template #loginModel >
  <div [@popOverState]="statusName" class="modal-dialog modal-lg modal-dialog-centered"  role="document" >
    <div class="modal-content">
      <div class="modal-body" >
        <div class="login-modal">
          <div class="row">
            <div class="col-lg-6 pad-right-0">
              <div class="login-modal-left">
              </div>
            </div>
            <div class="col-lg-6 pad-left-0">
              <button type="button" class="close close-top-right" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="mdi mdi-close"></i></span>
                <span class="sr-only">Close</span>
              </button>

                <div class="login-modal-right">
                  <!-- Tab panes -->
                  <div class="tab-content">
                    <div class="tab-pane active" id="login" role="tabpanel">
                      <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" style="direction: rtl; text-align: right">
                      <h5 class="heading-design-h5">{{'sale4you.label.login.heading-h5' | translate}}</h5>
                      <fieldset class="form-group">
                        <label>{{'sale4you.label.login.usernameOrEmail' | translate}}</label>
                        <input type="text" class="form-control" formControlName="username" placeholder="{{'sale4you.label.register.placeholder.username' | translate}}">
                      </fieldset>
                      <fieldset class="form-group">
                        <label>{{'sale4you.label.login.password' | translate}}</label>
                        <input type="password" class="form-control" formControlName="password" placeholder="{{'sale4you.label.register.placeholder.password' | translate}}">
                      </fieldset>
                      <fieldset class="form-group">
                        <button type="submit" class="btn btn-lg btn-secondary btn-block">{{'sale4you.label.login.btn.enterYourAccount' | translate}}</button>
                        <app-field-message [(errorFlg)]="loginError"  [message]="'sale4you.label.msg.loginError'| translate" ></app-field-message>
                      </fieldset>
                      <div class="login-with-sites text-center">
                        <p>{{'sale4you.label.login.label.loginWithSocialMedia' | translate}}</p>
                        <button class="btn-facebook login-icons btn-lg"><i class="mdi mdi-facebook"></i> Facebook</button>
                        <button class="btn-google login-icons btn-lg"><i class="mdi mdi-google"></i> Google</button>
                        <button class="btn-twitter login-icons btn-lg"><i class="mdi mdi-twitter"></i> Twitter</button>
                      </div>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                        <label class="custom-control-label" for="customCheck1">{{"sale4you.label.login.rememberMe" | translate}}</label>
                      </div>
                      </form>
                    </div>
                    <div class="tab-pane" id="register" role="tabpanel">
                      <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()" style="direction: rtl; text-align: right">
                      <h5 class="heading-design-h5">{{"sale4you.label.register.register" | translate}}</h5>
                      <fieldset class="form-group">
                        <label>{{"sale4you.label.register.emailOrPhone" | translate}}</label>
                        <input type="text" class="form-control" formControlName="username" id="username" placeholder="{{'sale4you.label.register.placeholder.username' | translate}}">
                        <app-control-messages [control]="registerForm.get('username')"></app-control-messages>
                      </fieldset>
                      <fieldset class="form-group">
                        <label>{{"sale4you.label.register.lastName" | translate}}</label>
                        <input type="text" class="form-control" [required]="true"  formControlName="last_name" id="lastName" placeholder="{{'sale4you.label.register.placeholder.lastName' | translate}}">
                        <app-control-messages [control]="registerForm.get('last_name')"></app-control-messages>
                      </fieldset>
                      <fieldset class="form-group">
                        <label>{{"sale4you.label.register.firstName" | translate}}</label>
                        <input type="text" class="form-control" [required]="true"  formControlName="first_name" id="firstName" placeholder="{{'sale4you.label.register.placeholder.firstName' | translate}}">
                        <app-control-messages [control]="registerForm.get('first_name')"></app-control-messages>
                      </fieldset>
                      <fieldset class="form-group">
                        <label>{{"sale4you.label.register.EnterPassword" | translate}}</label>
                        <input type="password" class="form-control" [pattern]="passwordRegex" formControlName="password" id="password" placeholder="{{'sale4you.label.register.placeholder.password' | translate}}">
                        <app-control-messages [control]="registerForm.get('password')"></app-control-messages>
                      </fieldset>
                      <fieldset class="form-group">
                        <label>{{"sale4you.label.register.EnterConfirmPassword" | translate}} </label>
                        <input type="password" class="form-control" [pattern]="passwordRegex" formControlName="confirmPassword" id="confirmPassword"  placeholder="{{'sale4you.label.register.placeholder.password' | translate}}">
                        <app-control-messages [control]="registerForm.get('confirmPassword')"></app-control-messages>
                      </fieldset>

                      <fieldset class="form-group">
                        <button type="submit" class="btn btn-lg btn-secondary btn-block" [disabled]="!registerForm.valid">{{"sale4you.label.register.btn.createYourAccount" | translate}}</button>
                      </fieldset>
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck2">
                        <label class="custom-control-label" for="customCheck2">{{"sale4you.label.register.btn.agreeTermsAndConditions" | translate}}</label>
                      </div>
                      </form>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="text-center login-footer-tab">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#login" role="tab"><i class="mdi mdi-lock"></i> {{'sale4you.label.login.btn.login' | translate}}</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#register" role="tab"><i class="mdi mdi-pencil"></i>  {{'sale4you.label.login.btn.register' | translate}}</a>
                      </li>
                    </ul>
                  </div>
                  <div class="clearfix"></div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
