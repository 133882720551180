import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { InputFileConfig, InputFileModule } from 'ngx-input-file';
const config: InputFileConfig = {
  fileAccept: '*'
};

import { AdminComponent } from './admin.component';
import { MenuComponent } from './components/menu/menu.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FullScreenComponent } from './components/fullscreen/fullscreen.component'; 
import { MessagesComponent } from './components/messages/messages.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import {ImageCellrendererComponent} from "./components/ag-grid-cellrenderers/image-cellrenderer/image-cellrenderer.component";
import {ImageShowcaseDialogComponent} from "./components/ag-grid-cellrenderers/image-cellrenderer/image-dialog/image-dialog.component";
import {SearchAdminFieldComponent} from "./components/search-field/search-admin-field.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {
    PriceCellRendererComponent
} from "./components/ag-grid-cellrenderers/price-cell-renderer-component/price-cell-renderer.component";
import { DateFormateCellrenderersComponent } from './components/ag-grid-cellrenderers/date-formate-cellrenderers/date-formate-cellrenderers.component';
import { LoadingBarCellRendererComponent } from './components/ag-grid-cellrenderers/loading-bar-cell-renderer/loading-bar-cell-renderer.component';
import { StatusCellRendererComponent } from './components/ag-grid-cellrenderers/status-cell-renderer/status-cell-renderer.component';
import { CheckVCellRendererComponent } from './components/ag-grid-cellrenderers/check-vcell-renderer/check-vcell-renderer.component';
import { SwitchButtonComponent } from './components/ag-grid-cellrenderers/switch-button/switch-button.component';
import { TablesComponent } from './tables/tables.component';
import { DynamicFormDialogComponent } from './components/dynamic-form-dialog/dynamic-form-dialog.component';
import { DropDownSelectComponent } from './components/drop-down-select/drop-down-select.component';
import { EditRemoveCellRendererComponent } from './components/ag-grid-cellrenderers/edit-remove-cell-renderer/edit-remove-cell-renderer.component';
import { IconCellRendererComponent } from './components/ag-grid-cellrenderers/icon-cell-renderer/icon-cell-renderer.component';

export const routes = [ 
  { 
    path: '', 
    component: AdminComponent, children: [
      { path: '', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) }, 
      { path: 'products', loadChildren: () => import('./products/products.module').then(m => m.ProductsModule) },
      { path: 'homepage', loadChildren: () => import('./homepage/homepage.module').then(m => m.HomepageModule) },
      { path: 'sales', loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
      { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule), data: { breadcrumb: 'Customers' } },
      { path: 'coupons', loadChildren: () => import('./coupons/coupons.module').then(m => m.CouponsModule), data: { breadcrumb: 'Coupons' } },
      { path: 'withdrawal', loadChildren: () => import('./withdrawal/withdrawal.module').then(m => m.WithdrawalModule), data: { breadcrumb: 'Withdrawal' } },
      { path: 'analytics', loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule), data: { breadcrumb: 'Analytics' } },
      { path: 'refund', loadChildren: () => import('./refund/refund.module').then(m => m.RefundModule), data: { breadcrumb: 'Refund' } },
      { path: 'followers', loadChildren: () => import('./followers/followers.module').then(m => m.FollowersModule), data: { breadcrumb: 'Followers' } },
      { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule), data: { breadcrumb: 'Support' } },
      { path: 'reviews', loadChildren: () => import('./reviews/reviews.module').then(m => m.ReviewsModule), data: { breadcrumb: 'Reviews' } } ,
      { path: 'advertisements', loadChildren: () => import('./advertisements/advertisements.model').then(m => m.AdvertisementsModule), data: { breadcrumb: 'Advertisements' } },
      { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule), data: { breadcrumb: 'Advertisements' } },
      { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule), data: { breadcrumb: 'ecommerce' } },
      { path: 'i18n', loadChildren: () => import('./i18n/IEighteenN.model').then(m => m.IEighteenNModule), data: { breadcrumb: 'IEighteenN' } },
    ]
  } 
];

@NgModule({
    declarations: [
        AdminComponent,
        MenuComponent,
        UserMenuComponent,
        FullScreenComponent,
        MessagesComponent,
        SearchAdminFieldComponent,
        BreadcrumbComponent,
        ImageShowcaseDialogComponent,
        ImageCellrendererComponent,
        PriceCellRendererComponent,
        DateFormateCellrenderersComponent,
        LoadingBarCellRendererComponent,
        StatusCellRendererComponent,
        CheckVCellRendererComponent,
        SwitchButtonComponent,
        DynamicFormDialogComponent,
        DropDownSelectComponent,
        EditRemoveCellRendererComponent,
        IconCellRendererComponent,

    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        RouterModule.forChild(routes),
        SharedModule,
        InputFileModule.forRoot(config),
    ],
    exports: [
        SearchAdminFieldComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AdminModule { }
