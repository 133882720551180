<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger" >
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <span (mouseleave)="userMenuTrigger.closeMenu()">
        <mat-toolbar color="primary">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                <img [src]="userImage" alt="user-image" width="80" class="mat-elevation-z6">
                <p>{{'sale4you.label.admin.user.name' | translate}} <br> <small>{{'sale4you.label.admin.user.position' | translate}}</small></p>
            </div>  
        </mat-toolbar>
        <a mat-menu-item routerLink="/admin"> 
            <mat-icon>person</mat-icon>
            <span>{{'sale4you.label.Profile' | translate }}</span>
        </a>
        <a mat-menu-item routerLink="/admin"> 
            <mat-icon>edit</mat-icon>
            <span>{{'sale4you.label.eitProfile' | translate }}</span>
        </a>
        <a mat-menu-item routerLink="/admin"> 
            <mat-icon>settings</mat-icon>
            <span>{{'sale4you.label.Settings' | translate }}</span>
        </a>
        <a mat-menu-item routerLink="/admin"> 
            <mat-icon>lock</mat-icon>
            <span>{{'sale4you.label.Lockscreen' | translate }}</span>
        </a>
        <a mat-menu-item routerLink="/admin"> 
            <mat-icon>help</mat-icon>
            <span>{{'sale4you.label.help' | translate }}</span>
        </a>
        <div class="divider"></div>
        <a mat-menu-item (click)="logout()">
            <mat-icon>power_settings_new</mat-icon>
            <span>{{'sale4you.label.logOut' | translate }}</span>
        </a>
    </span>
</mat-menu>
