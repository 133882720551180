<form class="example-form">
    <mat-form-field fxFlex="100"  class="example-full-width"  appearance="fill" >
        <mat-label>{{dataStructure.title}}</mat-label>
        <input type="search"
               [formControl]="myControl"
               [matAutocomplete]="auto"
               style="text-align: center"
               matAutocompletePosition="below"
               [placeholder]="dataStructure.title"
               aria-label="Number"
               [(ngModel)]="myModel"
               class="input-field-focus"
               matInput
               (input)="onMySearch($event)"
               (focus)="onClickFunc()"
               (blur)="onValueChange()"
               >
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" matAutocompletePosition  >
            <mat-option *ngFor="let option of filteredOptions | async | filterByString: myControl.value"  [value]="option" >
                {{option}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
