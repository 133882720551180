import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {HttpActions} from "../../../app.models";
import {DynamicFormDialogService} from "./dynamic-form-dialog.service";
import {QuestionBase} from "../../../pages/create-adv/models/question-base";
import {ApiUrl} from "../../../shared/models/apiurl.models";

@Component({
  selector: 'app-dynamic-form-dialog',
  templateUrl: './dynamic-form-dialog.component.html',
  styleUrls: ['./dynamic-form-dialog.component.scss']
})
export class DynamicFormDialogComponent implements OnInit {

  columns: { key: string; type: string, url: string, headerName: string, parentMapValue: string, relatedField: string, dropDownLabels: string[] }[] = [];
  action: HttpActions;
  url: string;
  dynamicForm!: FormGroup;
  rowId: number;
  callBackFunction: Function;
  questions: QuestionBase<any>[]=[];


  constructor(private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<DynamicFormDialogComponent>,public dynamicFormDialogService:DynamicFormDialogService, private apiUrl:ApiUrl) {
    this.url = this.data.url;
    this.action = this.data.action
    this.columns = this.data.columns
    this.rowId = this.data.rowId
    this.callBackFunction = this.data.callBackFunction
    this.initQuestions();
  }

  private initQuestions():void {
    this.columns.forEach(ques=>{
      const questionObj  = new QuestionBase();
      questionObj.callBackFunction = ques.url;
      questionObj.label = ques.headerName;
      questionObj.key = ques.key;
      questionObj.type = ques.type;
      questionObj.parentMapValue = ques.parentMapValue
      questionObj.relatedField = ques.relatedField
      questionObj.dropDownLabels = ques.dropDownLabels;
      this.questions.push(questionObj);
    })
    this.dynamicFormDialogService.url = this.url;
    this.dynamicFormDialogService.questionList = this.questions

  }
  parseURL(){
    // const pathFetch = this.apiUrl.pathTranslate(this.question.callBackFunction, this.dynamicFormDialogService, this.question.relatedField);
    // console.log(pathFetch)
  }

  async getBase64ImageFromUrl(imageUrl): Promise<any>  {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  async convertImageToImageList(image: any): Promise<{ link: any, preview: any }[]> {
    if (image instanceof Object) {
      return image.map(img => {
        return {link: img, preview: img}
      })
    } else {
      const convertedImg = await this.getBase64ImageFromUrl(image)
      return [{link: convertedImg, preview: convertedImg}];
    }
  }
  async initFormData() {
    const formControls: { [key: string]: any } = {};
    for (const ques of this.questions) {
      if (ques.type == 'image') {
        formControls[ques.key] = [await this.convertImageToImageList(this.data.rowData[ques.key])];
        this.dynamicFormDialogService.addQuestion(ques.key, formControls[ques.key]);
      } else {
        formControls[ques.key] = this.data.rowData[ques.key];
        this.dynamicFormDialogService.addQuestion(ques.key, formControls[ques.key]);
      }
    }
    this.dynamicForm = this.fb.group(formControls);
  }
  ngOnInit(): void {
    // Create form controls dynamically based on the columns array
    const formControls: { [key: string]: any } = {};
    this.columns.forEach((column) => {
      formControls[column.key] = ['']; // You can set default values if needed
    });

    // Create the form group


    if(this.action == HttpActions.UPDATE){
      this.initFormData()
    }else{
      this.dynamicForm = this.fb.group(formControls);
    }

  }

  onSubmit() {
    // Implement your patch action here using the dynamicForm value
    console.log('Form submitted with values:', this.dynamicForm.value);
    // You can now send the form data to your API for patch action
  }

  protected readonly HttpActions = HttpActions;
  publishData(): void{
    if(this.action == HttpActions.UPDATE){
      this.dynamicFormDialogService.updateData(this.rowId, this.data.url).subscribe(res=>{
        this.callBackFunction();
        this.dialogRef.close();
      })
    }else if(this.action == HttpActions.POST){
      this.dynamicFormDialogService.publishData().subscribe(res=>{
        this.callBackFunction();
        this.dialogRef.close();
      })
    }

  };
  convertFile(file : File, key: string){
    let format;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    if(file.type.indexOf('image')> -1){
      format = 'image';
    } else if(file.type.indexOf('video')> -1){
      format = 'video';
    }
    reader.onload = (event) => {
      this.dynamicForm.value.image = (<FileReader>event.target).result;
      this.dynamicFormDialogService.addQuestion(key, this.dynamicForm.value.image);
    }
  }

  onChange($event, key) {
    if(key == 'image' || key == 'images'){
      if(this.dynamicForm.value.image[0]['file'] != null){
        this.convertFile(this.dynamicForm.value.image[0]['file'], key);
      }else{
        this.convertFile($event.target.value, key);
      }

    }else{
      this.dynamicFormDialogService.addQuestion(key, $event.target.value);
    }
  }
}
