// typing-message.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-typing-message',
  templateUrl: './typing-message.component.html',
  styleUrls: ['./typing-message.component.scss'],
  animations: [
    trigger('typing', [
      state('start', style({ width: '0%' })),
      state('end', style({ width: '100%' })),
      transition('start => end', animate('3.5s ease-in-out')),
    ]),
  ],
})
export class TypingMessageComponent implements OnInit {
  @Input() message: string = '';
  currentMessage: string = '';
  state = 'start';
  private index: number = 0;

  private container: HTMLElement | null = null;
  ngOnInit() {
    this.container = document.getElementById('typing-container');
    this.type();
  }

  private type() {
    if (this.container) {
      this.container.innerHTML = this.message.slice(0, this.index);
      this.index++;

      if (this.index <= this.message.length) {
        setTimeout(() => this.type(), 50);
      } else {
        this.index = 0;
        setTimeout(() => this.type(), 1000); // Pause before restarting
      }
    }
  }
}
