import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-text-wrapper',
  templateUrl: './text-wrapper.component.html',
  styleUrls: ['./text-wrapper.component.css']
})
export class TextWrapperComponent implements OnInit {

  @Input() toolTip: string;
  constructor() { }

  ngOnInit(): void {
    // console.log(this)
  }

}
