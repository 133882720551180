<div *ngIf="greenPass">
    <div class="card-deck">

        <div *ngFor="let adsRote of AdvFormService.adsRoutes | sortBy:'level'" style="padding: 1em;" (click)="adsRote.blocked ? null : selectRoute({freeAdv:freeAdv, creditCard:creditCard},adsRote)" class="card">

            <img class="card-img-top adsSubsImage" [src]="adsRote.image" alt="Card image cap">
            <div class="card-body">
                <h1 class="card-title"><b>{{adsRote.title | translate}}</b></h1>
                <ul class="uiDesc">
                    <li *ngFor="let line of adsDescription(adsRote.description)">{{line | translate}}</li>
                </ul>
            </div>
            <div class="card-footer">
                    <b><span>{{adsRote.price}}₪</span></b> // <b><span> {{adsRote.lifeSpan}} ימים </span></b>
            </div>
            <div class="blockBox" *ngIf="adsRote.blocked">
                <app-typing-message [message]="dynamicMessage"></app-typing-message>
            </div>
        </div>

<!--        <div style="padding: 1em;" (click)="selectRoute(freeAdv,0)" class="card">-->
<!--            <img class="card-img-top adsSubsImage" src="./assets/img/good.jpg" alt="Card image cap">-->
<!--            <div class="card-body">-->
<!--                <h1 class="card-title"><b>{{"sale4all.label.adv.type.green" | translate}}</b></h1>-->
<!--                <ul class="uiDesc">-->
<!--                    <li>{{"sale4all.label.adv.type.green.description.1" | translate}}</li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="card-footer">-->
<!--                <small class="text-muted">{{"sale4all.label.adv.type.green.price" | translate}}</small>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div style="padding: 1em;" (click)="selectRoute(content,1)" class="card">-->
<!--            <img class="card-img-top adsSubsImage" src="../../../../assets/img/better.jpg" alt="Card image cap">-->
<!--            <div class="card-body">-->
<!--                <h1 class="card-title"><b>{{"sale4all.label.adv.type.highlighted" | translate}}</b></h1>-->
<!--                <ul class="uiDesc">-->
<!--                  <li>{{"sale4all.label.adv.type.highlighted.description.1" | translate}}</li>-->
<!--                  <li>{{"sale4all.label.adv.type.highlighted.description.2" | translate}}</li>-->
<!--                  <li>{{"sale4all.label.adv.type.highlighted.description.3" | translate}}</li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="card-footer">-->
<!--                <small class="text-muted">{{'sale4all.label.adv.type.highlighted.price' | translate}}</small>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div style="padding: 1em;" (click)="selectRoute(content,2)" class="card">-->
<!--            <img class="card-img-top adsSubsImage" src="./assets/img/best.jpg" alt="Card image cap">-->
<!--            <div class="card-body">-->
<!--                <h1 class="card-title"><b>{{"sale4all.label.adv.type.gold" | translate}}</b></h1>-->
<!--                <ul class="uiDesc">-->
<!--                  <li>{{'sale4all.label.adv.type.gold.description.1' | translate}}</li>-->
<!--                  <li>{{'sale4all.label.adv.type.gold.description.2' | translate}}</li>-->
<!--                  <li>{{'sale4all.label.adv.type.gold.description.3' | translate}}</li>-->
<!--                  <li>{{'sale4all.label.adv.type.gold.description.4' | translate}}</li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="card-footer">-->
<!--                <small class="text-muted">{{'sale4all.label.adv.type.gold.price' | translate}}</small>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</div>
<ng-template #freeAdv let-modal>
    <app-free-adv [dialogRef]="freeAdv"></app-free-adv>
</ng-template>
<!--<ng-template #creditCard let-modal>-->
<!--    <app-credit-card #content></app-credit-card>-->
<!--</ng-template>-->


