import { Injectable } from '@angular/core';


import {Observable, of, throwError} from 'rxjs';
import {QuestionBase} from "../models/question-base";
import {DropdownQuestion} from "../models/question-dropdown";
import {TextboxQuestion} from "../models/question-textbox";
import {catchError, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {TextAreaQuestion} from "../models/question-textarea";
import {SelectBigBox} from "../models/select-big-box";
import {MultiSelectBox} from "../models/multi-select-box";
import {ImagesBox} from "../models/images-box";
import {PersonContact} from "../models/person-contact";
import {SelectRelatedDescription} from "../models/select-related-description";
import {CheckboxQuestion} from "../models/checkbox-question";

@Injectable()
export class QuestionService {

  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private http: HttpClient, private apiKey: ApiUrl) {
  }
  // TODO: get from a remote source of question metadata
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }
  dataProccessing(questionList:any){
    const questions: QuestionBase<string>[] = [];
    questionList.forEach((question:any)=>{
        if(question.controlType === 'SelectField') {
          questions.push(
            new DropdownQuestion({
            key: question.key,
            label: question.label,
            page: question.page,
            type:question.type,
            section: {
              description: question.section.description,
              namespace: question.section.namespace,
              number: question.section.number,
              expanded: question.section.expanded,
              callBackFunction: question.section.callBackFunction
            },
            options: [],
            required: question.required,
            callBackFunction : question.callBackFunction,
            relatedField: question.relatedField,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam

          })
        )
      }
      if(question.controlType === 'ImagesBox') {
        questions.push(
          new ImagesBox({
            key: question.key,
            label: question.label,
            page: question.page,
            section: {
              description: question.section.description,
              namespace: question.section.namespace,
              number: question.section.number,
              expanded: question.section.expanded,
              callBackFunction: question.section.callBackFunction
            },
            options: [],
            callBackFunction : question.callBackFunction,
            relatedField: question.relatedField,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam

          }),
        )
      }
      if(question.controlType === 'PersonContact') {
        questions.push(
          new PersonContact({
            key: question.key,
            label: question.label,
            page: question.page,
            section: {
              description: question.section.description,
              namespace: question.section.namespace,
              number: question.section.number,
              expanded: question.section.expanded,
              callBackFunction: question.section.callBackFunction
            },
            options: [],
            callBackFunction : question.callBackFunction,
            relatedField: question.relatedField,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
      if(question.controlType === 'MultiSelectBox') {

        questions.push(
          new MultiSelectBox({
            key: question.key,
            label: question.label,
            page: question.page,
            section: {
              description: question.section.description,
              namespace: question.section.namespace,
              number: question.section.number,
              expanded: question.section.expanded,
              callBackFunction: question.section.callBackFunction
            },
            options: [],
            callBackFunction : question.callBackFunction,
            relatedField: question.relatedField,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
      if(question.controlType === 'SelectBigBox') {
        questions.push(
          new SelectBigBox({
            key: question.key,
            label: question.label,
            page: question.page,
            section: {
              description: question.section.description,
              namespace: question.section.namespace,
              number: question.section.number,
              expanded: question.section.expanded,
              callBackFunction: question.section.callBackFunction
            },
            options: [],
            callBackFunction : question.callBackFunction,
            relatedField: question.relatedField,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
      if(question.controlType === 'SelectRelatedDescription') {
        questions.push(
          new SelectRelatedDescription({
            key: question.key,
            label: question.label,
            page: question.page,
            section: question.section,
            value: question.value =="None" ?undefined : question.value,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          })
        )
      }
      if(question.controlType === 'Text') {
        questions.push(
          new TextboxQuestion({
            key: question.key,
            label: question.label,
            page: question.page,
            section: question.section,
            value: question.value =="None"?'' : question.value,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
      if(question.controlType === 'CheckBox') {
        questions.push(
          new CheckboxQuestion({
            key: question.key,
            type: 'checkbox',
            label: question.label,
            page: question.page,
            section: question.section,
            value: question.value =="None"?'' : question.value,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
      if(question.controlType === 'TextArea') {
        questions.push(
          new TextAreaQuestion({
            key: question.key,
            label: question.label,
            page: question.page,
            value: question.value =="None"?'' : question.value,
            section: question.section,
            required: question.required,
            order: question.order,
            addClass: question.addClass,
            additionalParam: question.additionalParam
          }),
        )
      }
    });
    return of(questions.sort((a, b) => a.order - b.order));
  }
  fetchQuestions(selectedNamespace: string): Observable<QuestionBase<string>>{
    const questions: QuestionBase<string>[] = [];
    return this.http.get(this.apiKey.getFormsQuestion(selectedNamespace), this.options)
      .pipe(map((res) => res ? this.dataProccessing(res) : null ),catchError(this.handleObserverError)
      );
  }
  getServerQuestions(namespace: string): Observable<any>{
    return this.fetchQuestions(namespace);
  }
  // getServerQuestions(namespace: string):Observable<QuestionBase<any>[]>{
  //
  //     return this.fetchQuestions(namespace).subscribe(questionList => {
  //       let generatedArr:any = [];
  //       questionList.forEach((question:any)=>{
  //         if(question.type === 'select') {
  //           generatedArr.push(
  //             new DropdownQuestion({
  //             key: 'brave',
  //             label: 'Bravery Rating',
  //             options: [
  //               {key: 'solid', value: 'Solid'},
  //               {key: 'great', value: 'Great'},
  //               {key: 'good', value: 'Good'},
  //               {key: 'unproven', value: 'Unproven'}
  //             ],
  //             order: 3
  //           })
  //           )
  //         }
  //
  //       });
  //
  //     })
  // }

  getQuestions() {

    const questions: QuestionBase<string>[] = [

      new DropdownQuestion({
        key: 'brave',
        label: 'Bravery Rating',
        options: [],
        order: 3
      }),

      new TextboxQuestion({
        key: 'firstName',
        label: 'First name',
        value: 'Bombasto',
        required: true,
        order: 1
      }),

      new TextboxQuestion({
        key: 'emailAddress',
        label: 'Email',
        type: 'email',
        order: 2
      })
    ];

    return of(questions.sort((a, b) => a.order - b.order));
  }
}
