<div *ngIf="selectedData == null" id="CreateAdvContainer">
  <div class="row">
    <div  *ngFor="let category of firstCategory" class="col categoryBox first" [ngClass]="highlightSelectedData(category)" (click)="SelectMainCategory(category)" >
      <img src="{{category.icon}}" alt="">
      <label>{{category.name}}</label>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let category of secondCategory" class="col categoryBox second" [ngClass]="highlightSelectedData(category)" (click)="SelectMainCategory(category)">
      <mat-icon aria-hidden="false"  aria-label="Example home icon">{{category.icon}}</mat-icon>
      <label>{{category.name}}</label>
    </div>
  </div>
  <div class="row">
    <div *ngFor="let category of thirdCategory" class="col categoryBox third" [ngClass]="highlightSelectedData(category)" (click)="SelectMainCategory(category)">
      <label>{{category.name}}</label>
    </div>
  </div>
</div>
