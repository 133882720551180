import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterByString'
})
export class FilterByStringPipe implements PipeTransform {
    transform(items: any[], filterString: string): any[] {
        if (!items || filterString == null) {
            return items;
        }

        filterString = filterString.toString().toLowerCase().trim();
        if(filterString == null  || filterString == ''){
            return items;
        }

        return items.filter(item => {
            if(item?.label != null){
                return item.label.toString().toLowerCase().includes(filterString);
            }
            return item.toString().toLowerCase().includes(filterString);
        });
    }
}
