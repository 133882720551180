import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFormatInput]'
})
export class FormatInputDirective {

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.formatInput(value);
    this.control.control?.setValue(formattedValue, { emitEvent: false });
    this.el.nativeElement.value = formattedValue;
  }

  formatInput(value: string): string {
    // Example formatting based on question.type
    // You can extend this based on your requirements
    if (this.control.name === 'price') {
      // Assuming input type is price and you want to format it like 1,235.00
      const floatValue = parseFloat(value.replace(/[^\d.-]/g, ''));
      const formattedValue = floatValue.toLocaleString('en-US', { minimumFractionDigits: 2 });
      return formattedValue;
    }
    // Add more conditions for other types if needed
    return value;
  }
}