import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FilterBase} from "../../../../models/filter-base";

@Component({
  selector: 'app-filter-base',
  templateUrl: './filter-base.component.html',
  styleUrls: ['./filter-base.component.css']
})
export class FilterBaseComponent implements OnInit {
  @Input() filterBase!: FilterBase;
  @Input() filterBoxWidth: number;
  @ViewChild('filterBaseContainer') filterBaseContainer: ElementRef;
  parentWith: number = 160;
  constructor() { }

  getParentWith(parentWidth: number = 160){
    return {width: parentWidth+'px'}
  }
  ngOnInit(): void {
  }

}
