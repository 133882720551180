import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
  selector: 'app-price-cell-renderer-component',
  templateUrl: './price-cell-renderer.component.html',
  styleUrls: ['./price-cell-renderer.component.scss']
})
export class PriceCellRendererComponent implements ICellRendererAngularComp {

  value: number;
  constructor() { }


  agInit(params: ICellRendererParams): void {
    this.value = params.value
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

}
