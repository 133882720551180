<div class="cart-list-product">
  <a class="float-right remove-cart" href="#"><i class="mdi mdi-close"></i></a>
  <img class="img-fluid" src="{{product.images[0].image}}" alt="">
  <span class="badge badge-success" *ngIf="product.discount!=null">{{"sale4you.label.discount" | translate}} {{product.discount}}{{"sale4you.label.Percent" | translate}}</span>
  <h5><a href="#">{{product.name}}</a></h5>
  <h6><strong><span class="mdi mdi-approval"></span> Available in</strong> - 500 gm</h6>
  <p class="offer-price mb-0" *ngIf="product.price!=null">{{"sale4you.label.WishList.shekel" | translate}}{{product.price}} <i class="mdi mdi-tag-outline"></i>
    <span class="regular-price">$800.99</span>
  </p>
</div>
