
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'ag-grid-enterprise';

if (environment.production) {
  enableProdMode();
}
declare global{
  interface Array<T>{
    $remove(pos: number):[]
  }
}
declare global {
  interface String {
    $piece(string: string, postition: number) : string;
    $len(parameter: string): number;
  }
}
document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
});
