export class Store{
  id: number;
  name: string;
  website: string;
  branchList: Branch[]
  user:User;
}
export class Branch{
  id: number;
  address: string;
  openingHours: string;
}
export class User {
  id: number;
  username: string;
  password: string;  
  profile: UserProfile;
  work: UserWork;
  contacts: UserContacts;
  social: UserSocial;
  settings: UserSettings;
}

export class UserProfile {
  firstName: string;
  lastName: string;
  birthday: string;
  gender: string;
  image: string;
  fullName: string;
  avatar: string
}

export class UserWork {
  company: string;
}

export class UserContacts{
  email: string;
  phone: string;
  address: string;  
}

export class UserSocial {
  facebook: string;
  twitter: string;
  google: string;
}

export class UserSettings{
  isActive: boolean;
  isDeleted: boolean;
  registrationDate: Date;
  joinedDate: Date;
}
