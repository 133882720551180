import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {SystemApp} from "../../../constans/SystemConfigurations";
import {ColDef, ColGroupDef, GridOptions, GridReadyEvent} from "ag-grid-community";
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from "@angular/common";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatDialog} from "@angular/material/dialog";
import {AdsPageComponentDialog} from "./ads-page/ads-page-component-dialog.component";
import {ProductType} from "../../../app.models";
import {StatusAds} from "../../../models/user-ads";

interface CarsDataDetails{
  name:string;
}
@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.css']
})
export class AdsComponent implements OnInit {
  @ViewChild('adsModel') adsModel!:ElementRef;

  protected readonly ProductType = ProductType;
  public gridOptions: GridOptions;
  private paramsParams: GridReadyEvent
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  /*base_url: any;*/
  base_url: string;
  selectedProductType: ProductType = ProductType.CAR;
  constructor(
    private http: HttpClient,
    private apiKey:ApiUrl,
    public translate:TranslateService,
    private loadingMaskServiceService: LoadingMaskServiceService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    public dialog: MatDialog
  )  {
    this.base_url = SystemApp.base_url_api;
  }

  ngOnInit(): void {
    this.columnDefs = this.getGridColumn;
  }

  public openAdsDialog(selectedAdsItem:any){
    let dialogRef;
    dialogRef =this.dialog.open(AdsPageComponentDialog, {
      data: {selectAds: selectedAdsItem, namespace: this.selectedProductType}
    });

    dialogRef.afterClosed().subscribe(student => {
      this.loadData().subscribe(res=>{
        this.rowData = res;
      })
    });
  }
  private setDefaultFilter(): void {
    const defaultFilterModel = {
      resourcetype: { type: 'equals', filter: this.selectedProductType },
    };

    // Set default filter using setFilterModel
    this.paramsParams.api.setFilterModel(defaultFilterModel);
  }
  //===================================================================================
  public defaultColDef: ColDef = {
    // set the default column width

    // make every column editable
    editable: false,
    suppressMenu: true,
    // make every column use 'text' filter by default
    filter: 'agTextColumnFilter',
    // enable floating filters by default
    floatingFilter: false,
    // make columns resizable
    resizable: true,
  };
  public defaultColGroupDef: Partial<ColGroupDef> = {
    marryChildren: true,
  };
  public columnTypes: {
    [key: string]: ColDef;
  } = {
    numberColumn: { width: 130, filter: 'agNumberColumnFilter' },
    medalColumn: { width: 100, columnGroupShow: 'open', filter: false },
    nonEditableColumn: { editable: false },
    dateColumn: {
      // specify we want to use the date filter
      filter: 'agDateColumnFilter',
      // add extra parameters for the date filter
      filterParams: {
        // provide comparator function
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = cellValue.split('/');
          const day = Number(dateParts[0]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[2]);
          const cellDate = new Date(year, month, day);
          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
  };
  public rowData!: any[];
  loadData(param='cars'){
    return this.http.get<any[]>(this.apiKey.getAds(),this.options)
  }
  onGridReady(params: GridReadyEvent) {
    this.paramsParams = params;
    this.loadData().subscribe(res=>{
      this.rowData = res;
      params.api.refreshHeader();
      this.setDefaultFilter();
      params.api.sizeColumnsToFit();
    })

  }
  imageRenderCell = (params:any) =>{
    return "<a href="+SystemApp.base_url_api+params.value[0].image+"><img style='height: 100%; width: 35px; height: 35px; object-fit: cover; border-radius: 100%'  src='"+params.value[0].image+"'/></a>";
  }
  dateRenderCell = (date: any)=>{
    return this.datePipe.transform(date.value,"dd/MM/yyyy hh:mm a");
  }


  createHyperLink(params:any): any {
    if (!params.data) { return; }
    const spanElement = document.createElement('span');
    spanElement.innerHTML = ` ${params.value}`;
    // spanElement.addEventListener('click', ($event) => {
    //   $event.preventDefault();
    //   this.openAdsDialog(params.data)
    //   // The below code is used to navigate from one page to another page in angular. you can change it          // according to your requirement.
    //   // this.router.navigate([this.homeUrl]);
    // });
    return spanElement;
  }

  get homeUrl(): string {
    return 'home';
  }
  productTypeRenderCel = (params: any) =>{
    if(params.value.toLowerCase() == ProductType.CAR.toLowerCase()){
      return `<span><i class="fa fa-car"></i></span>`
    }
    if(params.value.toLowerCase() == ProductType.NADLAN.toLowerCase()){
      return `<span><i class="fa fa-building"></i></span>`
    }
    if(params.value.toLowerCase() == ProductType.SALE2.toLowerCase()){
      return `<span><i class="fa fa-product-hunt"></i></span>`
    }
    return params.value
  }
  booleanRenderCell = (params:any) =>{
    return params.value? `<span style="color:limegreen;font-weight: bold;">`+this.translate.instant('sale4you.label.status.active')+`<span>`:`<span style="color:darkred;font-weight: bold;">`+this.translate.instant('sale4you.label.status.inactive')+`<span>`;
  }
  geHeaderName= (params:any) =>{
    return this.translate.instant("sale4you.label."+params.colDef.field)
  }


  public columnDefs: (ColDef | ColGroupDef)[];

  setProductTypeCplumn(){
    this.paramsParams.api.setColumnDefs(this.getGridColumn)
  }
  loadAds(productType: ProductType) {
    this.selectedProductType = productType
    console.log()
    this.setProductTypeCplumn();
    this.paramsParams.api.sizeColumnsToFit();
    this.setDefaultFilter()
  }

  get getGridColumn(){
    if(this.selectedProductType == ProductType.CAR){
      return[
        // using default ColDef
        { field: 'name',type:['nonEditableColumn'], minWidth: 150, headerValueGetter: ()=>this.translate.instant("sale4you.label.name"), cellRenderer: this.createHyperLink.bind(this) },
        { field: 'CodeProducer', minWidth: 140,type:['nonEditableColumn'],headerValueGetter: ()=>this.translate.instant("sale4you.label.producer")},
        { field: 'MainModel', minWidth: 150,type:['nonEditableColumn'],headerValueGetter: ()=>this.translate.instant("sale4you.label.model")},
        { field: 'NumberOfOwner.numberOnwer', minWidth: 140,type:['nonEditableColumn'],headerValueGetter: ()=>this.translate.instant("sale4you.label.numberOwner")},
        { field: 'images', minWidth: 100,type:['nonEditableColumn'] , cellRenderer:this.imageRenderCell,headerValueGetter: ()=>this.translate.instant("sale4you.label.image")},
        { field: 'year', minWidth: 100, type: ['numberColumn', 'nonEditableColumn'], headerValueGetter: ()=>this.translate.instant("sale4you.label.year")},
        { field: 'createTime', minWidth: 140, type: [ 'nonEditableColumn'], cellRenderer:this.dateRenderCell, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.createTime")},
        { field: 'resourcetype', minWidth: 140, type: ['nonEditableColumn'], cellRenderer:this.productTypeRenderCel, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.resourceType")},
        { field: 'statusAds', minWidth: 140, type: [ 'nonEditableColumn'], cellRenderer:this.statusAdsRenderCel, width: 200,headerValueGetter: ()=>this.translate.instant("sale4you.label.active")},
      ]
    }

    if(this.selectedProductType == ProductType.NADLAN){
      return [
        { field: 'name',type:['nonEditableColumn'], minWidth: 150, headerValueGetter: ()=>this.translate.instant("sale4you.label.name"), cellRenderer: this.createHyperLink.bind(this) },
        { field: 'images', minWidth: 100,type:['nonEditableColumn'] , cellRenderer:this.imageRenderCell,headerValueGetter: ()=>this.translate.instant("sale4you.label.image")},
        { field: 'createTime', minWidth: 140, type: [ 'nonEditableColumn'], cellRenderer:this.dateRenderCell, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.createTime")},
        { field: 'resourcetype', minWidth: 140, type: ['nonEditableColumn'], cellRenderer:this.productTypeRenderCel, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.resourceType")},
        { field: 'statusAds', minWidth: 140, type: ['numberColumn', 'nonEditableColumn'], cellRenderer:this.statusAdsRenderCel, width: 200,headerValueGetter: ()=>this.translate.instant("sale4you.label.active")},
      ]
    }

    if(this.selectedProductType == ProductType.SALE2){
      return [
        { field: 'name',type:['nonEditableColumn'], minWidth: 150, headerValueGetter: ()=>this.translate.instant("sale4you.label.name"), cellRenderer: this.createHyperLink.bind(this) },
        { field: 'images', minWidth: 100,type:['nonEditableColumn'] , cellRenderer:this.imageRenderCell,headerValueGetter: ()=>this.translate.instant("sale4you.label.image")},
        { field: 'createTime', minWidth: 140, type: [ 'nonEditableColumn'], cellRenderer:this.dateRenderCell, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.createTime")},
        { field: 'resourcetype', minWidth: 140, type: ['nonEditableColumn'], cellRenderer:this.productTypeRenderCel, width: 100,headerValueGetter: ()=>this.translate.instant("sale4you.label.resourceType")},
        { field: 'statusAds', minWidth: 140, type: ['numberColumn', 'nonEditableColumn'], cellRenderer:this.statusAdsRenderCel, width: 200,headerValueGetter: ()=>this.translate.instant("sale4you.label.active")},
      ]
    }


  }
  getClass(productType: ProductType) {
    if(this.selectedProductType.toLowerCase() == productType.toLowerCase()){
      return 'selectedTab'
    }
    return ''
  }

  openModel(params:any) {
    this.openAdsDialog(params.data)
  }

  protected readonly event = event;

  private statusAdsRenderCel = (params:any) =>{
    if(params.value == StatusAds.Active) return `<span style="color:limegreen;font-weight: bold;">`+this.translate.instant('sale4you.label.status.active') + `<span>`;
    if(params.value == StatusAds.Done) return `<span style="color:limegreen;font-weight: bold;">`+this.translate.instant('sale4you.label.status.Done') + `<span>`;
    if(params.value == StatusAds.Delete) return `<span style="color:darkred;font-weight: bold;">`+this.translate.instant('sale4you.label.status.Delete') + `<span>`;
    if(params.value == StatusAds.inReview) return `<span style="color:darkred;font-weight: bold;">`+this.translate.instant('sale4you.label.status.inReview') + `<span>`;
    if(params.value == StatusAds.PayPending) return `<span style="color:darkred;font-weight: bold;">`+this.translate.instant('sale4you.label.status.payPending') + `<span>`;
    if(params.value == StatusAds.Pause) return `<span style="color:darkred;font-weight: bold;">`+this.translate.instant('sale4you.label.status.Pause') + `<span>`;
    return ''
  }
}
