import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {SelectRemoteComponent} from "../../../components/forms/select-remote/select-remote.component";
import {QuestionBase} from "../models/question-base";
import {SystemApp} from "../../../constans/SystemConfigurations";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {AdvFormService} from "../services/adv-form.service";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class DropdownComponent extends SelectRemoteComponent{

  @Input() question!: QuestionBase<any>;
  @Input() questionKey!: string;
  @ViewChild('dropDown') input:any;
  selectedData: any;
  fetchUrl!: string;

  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();

  constructor(protected http: HttpClient, protected apiUrl:ApiUrl,protected elRef: ElementRef, protected renderer: Renderer2, private formService: AdvFormService) {
    super(http,apiUrl);
  }

  ngOnInit(): void {
    this.fetchUrl = SystemApp.base_url_api + '/' + this.question.callBackFunction.$piece("*",1);

  }


  onClick() {
    // const pathFetch = this.apiUrl.pathTranslate(this.question.callBackFunction, this.formService,this.question.relatedField);
    // this.fetchUrl = pathFetch.path

  }

  onChange(value:any) {
    const index = this.question.key
    this.formService.addQuestion(index,value)
  }
}
