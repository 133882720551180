import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
    transform(array: any[], field: string): any[] {
        if (!Array.isArray(array) || array.length <= 1) {
            return array;
        }

        return array.slice().sort((a, b) => {
            return a[field] < b[field] ? -1 : 1;
        });
    }
}