<app-search-admin-field [(myModel)]="inputValue" (onChange)="onChange($event)" (onSearch)="onSearch($event)" (onClick)="onClick()" [setOptions]="dataList" [dataStructure]="{name:'label', value:'value', title:'' }" ></app-search-admin-field>
<!--<div class="remote-select">-->
<!--  <div class="input-group">-->
<!--    <input #select  (focus)="onClick()" [(ngModel)]="inputValue" (blur)="onBlur($event)"  (search)="onSearch($event)" (keydown)="onChange($event)"  type="search" class="form-control" placeholder="" aria-label="Username" aria-describedby="basic-addon1">-->
<!--    <div *ngIf="isLoading" id="loading">-->
<!--      <div class="row" style="padding: 0; margin:0;">-->
<!--        <table style="width: 100%;">-->
<!--          <tr>-->
<!--            <td style="text-align: left; width: 60%;">{{'sale4all.label.loading' | translate}}</td>-->
<!--            <td style="text-align: right; width: 40%;"><mat-spinner style="display: block; position: relative;"  [diameter]="20"></mat-spinner></td>-->
<!--          </tr>-->
<!--        </table>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div *ngIf="expanded&&(!isLoading)" id="options" (click)="stopPropagation($event)" #optionsList>-->
<!--          <option *ngFor="let opt of dataList | filter: inputValue: 'label'; let i = index"  [className]="getClass(opt)" [id]="'options'+i" (click)="selectValue($event,opt)" [value]="opt">{{opt.label}}</option>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
