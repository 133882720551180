import { Component, OnInit } from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
  selector: 'app-check-vcell-renderer',
  templateUrl: './check-vcell-renderer.component.html',
  styleUrls: ['./check-vcell-renderer.component.scss']
})
export class CheckVCellRendererComponent implements ICellRendererAngularComp {

  value: string;
  constructor() { }

  agInit(params: ICellRendererParams): void {
    this.value = params.value
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  getStatusIcon() {
    if(this.value) return 'active-icon';
    return 'inactive-icon';
  }

}
