import {Component, Inject, OnInit, ViewChild} from "@angular/core";
import {AgGridAngular} from "ag-grid-angular";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'image-showcase-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.css'],
})
export class ImageShowcaseDialogComponent implements OnInit {

    public imageSrc: string;
    constructor(@Inject(MAT_DIALOG_DATA) public data: {imageSrc: any},public dialogRef: MatDialogRef<ImageShowcaseDialogComponent>) {
        this.imageSrc = data.imageSrc
    }

    ngOnInit(): void {

    }
    close() {
        this.dialogRef.close();
    }
}
