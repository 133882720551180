import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../loading-frame/loading-frame.component";

export enum LoginAction {
  Open,
  Close

}

@Injectable()
export class LoginService {
  private subject = new Subject<any>();
  constructor(private http: HttpClient, private apiKey:ApiUrl, private loadingMaskServiceService: LoadingMaskServiceService) {}

  sendClickEvent(action: LoginAction = LoginAction.Open){
    this.subject.next(action);
  }

  getClickEvent():Observable<any>{
    return this.subject.asObservable();
  }
}
