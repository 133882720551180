<div class="switchBtn">
  <div class="row switchBtnContainer">
    <div class="col switchBtn" (click)="switchFunc('licenceCode')" [class]="isLicenceCode?'selectedBtn':''" id="licenceCode">{{'sale4u.label.licenceCode' | translate}}</div>
    <div class="col switchBtn" (click)="switchFunc('manual')" [class]="!isLicenceCode?'selectedBtn':''" id="manual">{{'sale4u.label.manual' | translate}}</div>
  </div>
</div>
<div *ngIf="isLicenceCode" id="licenceCodeContainer" class="licenceCodeContainer">
  <form [formGroup]="licenceCodeForm" (ngSubmit)="onSubmit($event)" style="direction: ltr; text-align: center">
    <fieldset  class="form-group licenceCarBox" >
      <div class="row">
        <div class="col licenceCodeBtnBox"><input  type="text" #licenceCode class="licenceDigit input-field-focus" appPhoneMask  formControlName="licenceCode"></div>
        <div class="col searchBtnBox">
          <button [disabled]="licenceCode.value.length === 0" type="submit" class="btn btn-lg btn-secondary btn-block searchBtn" >{{"sale4you.label.car.search" | translate}}</button>
        </div>
      </div>
    </fieldset>
    <div>
      <div style="direction: rtl; text-align:right;" *ngIf="licenseCodeDes">
        <h1>{{"sale4you.label.licenseCodeDes.header.1" | translate}}</h1>
        <h2 class="cart_description">
          {{"sale4you.label.licenseCodeDes.header.2" | translate:licenseCodeDes}}
        </h2>
      </div>
      <h1 *ngIf="!licenseCodeDes">{{error}}</h1>

<!--      <h2>{{subtitle}}</h2>-->
    </div>

  </form>
  <div>
    {{carInfo}}
  </div>
</div>
<div *ngIf="!isLicenceCode" id="questionContainer">
  <div *ngFor="let question of questions" style="text-align: center">
    <app-question [question]="question" [form]="form"></app-question>
  </div>
</div>
