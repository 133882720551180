
  <div #filterBaseContainer [ngSwitch]="filterBase.controlType">
    <div class="field-box col">
      <div  *ngSwitchCase="'SelectField'">
        <app-select-field [filterBase]="filterBase" *ngIf="!(filterBase.type == 'bindSelect' && !filterBase.bindSelectHide)" ></app-select-field>
      </div>
      <div *ngSwitchCase="'RangeText'">
        <app-range-text [filterBase]="filterBase"></app-range-text>
      </div>
      <div *ngSwitchCase="'RangeSelect'">
        <app-range-select [filterBase]="filterBase" ></app-range-select>
      </div>
      <div *ngSwitchCase="'Checkbox'">
        <app-checkbox [filterBase]="filterBase"></app-checkbox>
      </div>
      <div *ngSwitchCase="'DateField'">
        <app-date-field [filterBase]="filterBase"></app-date-field>
      </div>
      <div *ngSwitchCase="'TextBox'">
        <app-text-box></app-text-box>
      </div>
    </div>
    <div *ngSwitchCase="'SelectCheckBox'" class="col-12">
      <app-select-check-boxs [filterBase]="filterBase"></app-select-check-boxs>
    </div>
  </div>
