import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-field-message',
  templateUrl: './field-message.component.html',
  styleUrls: ['./field-message.component.css']
})
export class FieldMessageComponent implements OnInit {
  @Input()
  message!: string;

  @Input()  errorFlg!: boolean;
  @Output() errorFlgChange = new EventEmitter<boolean>();

  constructor() { }

  resize(delta: boolean) {
    this.errorFlg = delta;
  }

  ngOnInit(): void {
  }

}
