import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterBase} from "../../../../../models/filter-base";
import {FilterbaseService} from "../../filter-base/services/filterbase.service";

@Component({
  selector: 'app-range-text',
  templateUrl: './range-text.component.html',
  styleUrls: ['./range-text.component.css']
})
export class RangeTextComponent implements OnInit {

  @Input() filterBase!: FilterBase;
  @Output() outValue = new EventEmitter<string>();

  constructor(private filterBaseService: FilterbaseService) { }

  ngOnInit(): void {
  }

  setValue(dir: string, $event: any):void{
    const value = $event.target.value;
    const key = this.filterBase.key;
    const questionIsExist = this.filterBaseService.getQuestion(key)
    if(questionIsExist == undefined){
      const object = {[dir]:value,range:true};
      this.filterBaseService.addQuestion(key, object);
    }else{
      questionIsExist[dir] = value
      this.filterBaseService.addQuestion(key, questionIsExist);
    }
  };

}
