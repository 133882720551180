import {User} from "./admin/users/user.model";
import {ColDef} from "ag-grid-community";

export class MyColDef implements ColDef {}

export enum PaymentProcessStatus{
    TODO="TODO",
    IN_PROGRESS="IN_PROGRESS",
    DONE="DONE"
}
export  enum  HttpActions{
    POST = "POST",
    DELETE = "DELETE",
    UPDATE = "UPDATE",
    GET = "GET"
}
export enum ProductType {
    ALL= "ALL",
    CAR = 'CAR',
    NADLAN = 'NADLAN',
    SALE2 = 'SALE2'
}



export class ZCreditWebCheckoutCreateSessionResponse {
    HasError: boolean;
    Errors: any[]; // You can replace 'any' with a specific type if needed
    ObjectName: string;
    Data: {
        HasError: boolean;
        ReturnCode: number;
        ReturnMessage: string | null;
        SessionId: string;
        SessionUrl: string;
        RequestData: {
            Key: string;
            Local: string;
            UniqueId: string;
            SuccessUrl: string;
            CancelUrl: string;
            CallbackUrl: string;
            PaymentType: string;
            CreateInvoice: boolean;
            AdditionalText: string;
            ShowCart: boolean;
            ThemeColor: string;
            IssuerWhiteList: number[];
            BrandWhiteList: number[];
            UseLightMode: boolean;
            Installments: {
                Type: string;
                MinQuantity: number;
                MaxQuantity: number;
            };
            Customer: {
                Email: string;
                Name: string;
                PhoneNumber: string;
                Attributes: {
                    HolderId: string;
                    Name: string;
                    PhoneNumber: string;
                    Email: string;
                };
            };
            CartItems: {
                Amount: number;
                Currency: string;
                Name: string;
                Description: string;
                AdjustAmount: boolean;
                Quantity: number;
                Image: string;
                IsTaxFree: boolean;
            }[];
            CardsIcons: {
                ShowVisaIcon: boolean;
                ShowMastercardIcon: boolean;
                ShowDinersIcon: boolean;
                ShowAmericanExpressIcon: boolean;
                ShowIsracardIcon: boolean;
            };
            UseCustomCSS: boolean;
            GetCurrencyCode: string;
            BitButtonEnabled: boolean;
            MaxPayButtonEnabled: boolean;
            ApplePayButtonEnabled: boolean;
            GooglePayButtonEnabled: boolean;
            CreditCardPaymentEnabled: boolean;
            FocusType: string;
            ShowTotalSumInPayButton: boolean;
            ForceCaptcha: boolean;
            BackgroundColor: string;
        };
    };
    ResponseType: number;
}
export class Category {
  constructor(public id: number, 
              public name:string, 
              public hasSubCategory: boolean,
              public parentId: Category){ }
}

export class ProductResponse{
    object_list: Product[];
    page_size: number;
}
export class Product {
  constructor(public id: number,
              public name: string,
              public images: Array<any>,
              public supplierPrice: number,
              public clientPrice: number,
              public discount: number,
              public ledType: string,
              public barCode: string,
              public  workHours: string,
              public ledPower :string,
              public quantity :number,
              public ratingsCount: number,
              public ratingsValue: number,
              public description: string,
              public availibilityCount: number,
              public cartCount: number,
              public color: Color[],
              public size: Array<string>,
              public weight: number,
              public mainCategory:Category,
              public categoryId: number){ }
              public lumen: number;
              public lighting: string;
              public bottomIlluminationAngle : number;
              public upperIlluminationAngle : number;
              public lightBulbHouse : string;
              public lightBulb : string;
              public height : number;
              public width : number;
              public length : number;
              public lightHue : string;
              public ip : number;
              public voltage : string;
              public building : string;
              public cover : string;
              public illuminationAngles : number;
              public depth : number;
              public diameter : number;
              public bore : number;
              public cri : number;
              public lm : number;
              public deviceStatus : string;
              public createTime : string;
              public updateTime : string;
              public selectedColor?: Color[];

}
export class Banner{
    constructor(
        public id: number,
        public title: string,
        public subtitle: string,
        public link: string,
        public image: string,
    ) {}
}
export class VideoBackground{
    constructor(
        public id: number,
        public description: string,
        public title: string,
        public videoSrc: string,
        public mobileSrc: string
    )
    {}
}
export class Slider{
    constructor(
        public id: number,
        public title: string,
        public subtitle: string,
        public link: string,
        public image: string,
        public sortNumber: number
    ) {}
}
export class Brands {
    constructor(
        public name: string,
        public image: string,
    ){ }
}
export class Size {
    constructor(
        public size: number,
    ){ }
}
export class Color {
    public count!: number
    constructor(
        public id: string,
        public name: string,
        public code: string,
        public selected: boolean,
        public image: string
    ){ }
}

export class PaymentMethod{
    constructor(
        public user: User,
        public cardHolderName: string,
        public cardNumber: string,
        public expiredMonth: number,
        public expiredYear: number,
        public cvv: string,
    ) {}

}
export class OrderItem{
    constructor(
        public product: Product,
        public quantity: number,
        public freeText: string,
    ) {}
}

export class Order{
    constructor(
        public number: number | null,
        public submittedDate: string  | null,
        public user: User  | null,
        public paymentMethod: PaymentMethod  | null,
        public totalPrice: number  | null,
        public status: string | null,
        public paymentStatus: string | null,
        public orderItem: OrderItem[]  | null,
        public firstName: string  | null,
        public lastName: string  | null,
        public email: string  | null,
        public phone: string  | null,
        public deliveryOption1: string | null,
        public deliveryOption2: string | null,
        public deliveryOption3: string | null
    ) {}
}


export interface CheckoutRequestData {
    Key: string;
    Local: string;
    UniqueId: string;
    SuccessUrl: string;
    CancelUrl: string;
    CallbackUrl: string;
    PaymentType: string;
    CreateInvoice: boolean;
    AdditionalText: string;
    ShowCart: boolean;
    ThemeColor: string;
    BitButtonEnabled: boolean;
    ApplePayButtonEnabled: boolean;
    GooglePayButtonEnabled: boolean;
    Installments: Installments;
    Customer: Customer;
    CartItems: CartItem[];
    FocusType: string;
    CardsIcons: CardsIcons;
    IssuerWhiteList: number[];
    BrandWhiteList: number[];
    UseLightMode: boolean;
    UseCustomCSS: boolean;
    BackgroundColor: string;
    ShowTotalSumInPayButton: boolean;
    ForceCaptcha: boolean;
}

export interface Installments {
    Type: string;
    MinQuantity: number;
    MaxQuantity: number;
}

export interface Customer {
    Email: string;
    Name: string;
    PhoneNumber: string;
    Attributes: Attributes;
}

export interface Attributes {
    HolderId: string;
    Name: string;
    PhoneNumber: string;
    Email: string;
}

export interface CartItem {
    Amount: number;
    Currency: string;
    Name: string;
    Description: string;
    Quantity: number;
    Image: string;
    IsTaxFree: boolean;
    AdjustAmount: boolean;
}

export interface CardsIcons {
    ShowVisaIcon: boolean;
    ShowMastercardIcon: boolean;
    ShowDinersIcon: boolean;
    ShowAmericanExpressIcon: boolean;
    ShowIsracardIcon: boolean;
}

export interface adsRoute{
    id: string,
    title: string,
    description: string,
    price: number,
    lifeSpan: number,
    image: string,
    level: string,
    block: boolean
}