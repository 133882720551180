import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'BranchToList', pure: false })
export class BranchToList implements PipeTransform {
  transform(value, args?): string {
    if(value){
      let branchString = ""
      value.forEach(branch=>{
        branchString += branch.address +",  "
      })
      return branchString.slice(0, -3);
    }
    return "";
  }
}

@Pipe({ name: 'UserSearchPipe', pure: false })
export class UserSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    let searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(store => {
        if (store.profile.firstName) {
          return store.profile.firstName.search(searchText) !== -1;
        }
        else{
          if(store.username){
            return store.username.search(searchText) !== -1;
          }else {
            return store;
          }
        }
      });
    }
  }
}
