import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {percentage} from "ngx-image-cropper/lib/utils/percentage.utils";

@Component({
  selector: 'app-loading-bar-cell-renderer',
  templateUrl: './loading-bar-cell-renderer.component.html',
  styleUrls: ['./loading-bar-cell-renderer.component.scss']
})
export class LoadingBarCellRendererComponent implements ICellRendererAngularComp {

  maxLifeSpan:number = 30;
  value: string;
  get getSuitColor(){
    let color = ''
    if(parseInt(this.value)<50){
      color = 'red'
    }
    if(parseInt(this.value)>50){
      color = 'orange'
    }
    if(parseInt(this.value)== 100){
      color = '#4caf50'
    }
    return {background: color};
  };
  constructor() { }

  agInit(params: ICellRendererParams): void {
    const endDateObject = new Date(Date.parse(params.data.expireDate));
    const startDateObject = new Date(Date.parse(params.data.startDate));
    const diffDays = endDateObject.getTime() - startDateObject.getTime();
    const daysRemaining = Math.floor(diffDays / (1000 * 60 * 60 * 24));
    this.maxLifeSpan = daysRemaining;
    this.value = this.calculatePercentage(params.data.expireDate).toFixed(0);
  }

  refresh(params: ICellRendererParams<any>): boolean {
    return false;
  }

  calculatePercentage(expireDate:any):number{
    const calculateRemainingDays = this.calculateRemainingDays(expireDate)
    if(calculateRemainingDays<=0){ return 100; }
    if(calculateRemainingDays > this.maxLifeSpan){ return 0}
    const percentage = (calculateRemainingDays/this.maxLifeSpan)*100;
    return 100 - percentage
  }
  calculateRemainingDays(expireDate:any): any {
    try {
      const endDateObject = new Date(Date.parse(expireDate));
      if (isNaN(endDateObject.getTime())) {
        throw new Error('Invalid date format');
      }
      const currentDate = new Date();
      const differenceInMs = endDateObject.getTime() - currentDate.getTime();
      const daysRemaining = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
      if(daysRemaining<0){
        return 0;
      }
      return daysRemaining;
    } catch (error) {
      console.error(`Error calculating remaining days: ${error.message}`);
      return NaN;
    }
  }

}
