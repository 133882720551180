import { Component, OnInit } from '@angular/core';
import {FilterbaseService} from "../top-filter/filter-base/services/filterbase.service";
import {ApiUrl} from "../../../shared/models/apiurl.models";
import {LoadingMaskServiceService} from "../../../components/loading-frame/loading-frame.component";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TranslateService} from '@ngx-translate/core';
import {MainCategory} from "../../../models/header.model";
import {SystemApp} from "../../../constans/SystemConfigurations";
import {LoginAction, LoginService} from "../../../components/session/login/login.service";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {AppService} from "../../../app.service";

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})

export class MainContentComponent implements OnInit {
  itemsList!:any[];
  mainCategories!:MainCategory[];
  baseurl: string = SystemApp.base_url;
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.apiKey.getJWTToken()
    })
  }
  constructor(private filterbaseService: FilterbaseService,private http: HttpClient, private apiKey: ApiUrl, private loginService: LoginService, private loadingMaskServiceService: LoadingMaskServiceService,public translate:TranslateService, public appService: AppService) {
    this.loadData()
  }

  isLogin(){ return !!localStorage.getItem('token') }
  ngOnInit(): void {
  }

  searchApply(): any {
    this.loadingMaskServiceService.setOnLoadingMask(true, true);
    this.filterbaseService.offset = 0;
    this.filterbaseService.loadFilterData(this.filterbaseService.limit, this.filterbaseService.offset).subscribe(data => {
      this.loadingMaskServiceService.setOnLoadingMask(false, false);
      this.itemsList = data.object_list;
    })
  }
  private loadData() {
    this.mainCategories = []
    this.getPages().subscribe((res) => {
      this.mainCategories = res.map((cate:any) => {
        return{
          id: cate.id,
          name: cate.name,
          nameEng: cate.category.toLowerCase(),
          important: cate.important,
          icon: cate.icon,
          subcategories: cate.subcategories

        }})
    })

  }
  private handleObserverError(error: any): Observable<any>{
    return throwError(error.error || error)
  }
  getPages(): Observable<any>{
    return this.http.get(this.apiKey.getPages(), this.options)
      .pipe(map((res) => res ? res : null ),catchError(this.handleObserverError)
      );
  }
  logout() {
    localStorage.removeItem('token')
    this.loginService.sendClickEvent(LoginAction.Close);
  }

  openLogin() {
    this.loginService.sendClickEvent(LoginAction.Open);
  }
}
